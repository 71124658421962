import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

const NewLanguageModal = ({ open, onClose, onSave }) => {
  const { t } = useTranslation("translations");

  const [language, setLanguage] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({});

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validate = useCallback(() => {
    const temp = {};
    temp.language =
      validateEmpty(language) === true ? "" : t("languageCodeCantBeEmpty");
    temp.buttonText =
      validateEmpty(buttonText) === true
        ? ""
        : t("languageEnglishNameCantBeEmpty");

    if (!dirty.language && language !== "") {
      setDirty({ ...dirty, language: true });
    }
    if (!dirty.buttonText && buttonText !== "") {
      setDirty({ ...dirty, buttonText: true });
    }
    setErrors({ ...temp });
  }, [language, buttonText, t, dirty]);

  useEffect(() => {
    validate();
  }, [language, buttonText, t, dirty, validate]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {t("newLanguage")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          label={t("languageCode") + "*"}
          sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
          inputProps={{ maxLength: 2 }}
          value={language}
          error={errors.language && dirty.language}
          helperText={errors.language && dirty.language ? errors.language : ""}
          onChange={(e) => {
            setLanguage(e.target.value.toUpperCase());
          }}
        />
        <TextField
          variant="standard"
          label={t("englishName") + "*"}
          sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
          value={buttonText}
          error={errors.buttonText && dirty.buttonText}
          helperText={
            errors.buttonText && dirty.buttonText ? errors.buttonText : ""
          }
          onChange={(e) => {
            setButtonText(e.target.value.toLowerCase());
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={Object.keys(errors).some((x) => errors[x])}
          onClick={() => {
            onSave({
              language,
              buttonText,
            });
            setLanguage("");
            setButtonText("");
            setErrors({});
            setDirty({});
          }}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewLanguageModal;
