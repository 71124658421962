import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    name: "",
    display_name: "",
    _id: "",
    renderChat: uuidv4(),
    updateCombo: false,
  },
  reducers: {
    setClient: (state, action) => {
      state.name = action.payload.name;
      state.display_name = action.payload.display_name;
      state._id = action.payload._id;
    },

    setRenderChat: (state) => {
      state.renderChat = uuidv4();
    },

    updateClientList: (state, action) => {
      state.updateCombo = action.payload;
    },
  },
});

export const { setClient, updateClientList, setRenderChat } =
  clientSlice.actions;

export default clientSlice.reducer;
