import React, { Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

import InfoIcon from "@mui/icons-material/Info";
import InputLabel from "@mui/material/InputLabel";

import { Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { isEmpty } from "lodash";

export default function EdgeIntents({ ...props }) {
  const [list, setList] = React.useState([]);
  const [valid, setValid] = React.useState(true);
  const [intents, setIntents] = React.useState([]);
  const { t } = useTranslation("transitionIntent");

  const prevIntentsRef = useRef();

  useEffect(() => {
    setIntents(props.intents);
    orderIntents(props.list);
    validate(props.intents);
  }, []);

  useEffect(() => {
    if (props.intents.length !== 0) {
      setIntents(props.intents);
      orderIntents(props.list);
      validate(props.intents);
      props.intentsUpdate(props.intents, valid);
    }
  }, [props.intents, valid]);

  useEffect(() => {
    prevIntentsRef.current = props.intents;
  }, [intents]);

  async function handleChangeIntent(intent) {
    setIntents(intent);
    validate(intent);
    props.intentsUpdate(intent, valid);
  }

  function validate(intent) {
    if (intent?.length > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  async function orderIntents(unsortedIntents) {
    let sortedIntents = unsortedIntents.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    let options = await clearIntentsOptions(sortedIntents);
    setList(options);
  }

  async function clearIntentsOptions(sortedIntents) {
    let intentsOptions = [];
    sortedIntents
      .filter((intent) => isEmpty(intent.destinationPage))
      .forEach((intent) => intentsOptions.push(intent.name));
    return intentsOptions;
  }

  return (
    <Fragment>
      <div className="intent-select">
        <div className="intent-label">
          <InputLabel id="intent">{t("intents")}</InputLabel>
          <Tooltip title={t("intentsHelper")} sx={{ marginLeft: "2px" }}>
            <InfoIcon />
          </Tooltip>
        </div>
        <Tooltip
          title={!props.userPermissions ? t("noPermissionsTooltip") : ""}>
          <Autocomplete
            sx={{ marginTop: "10px" }}
            autoHighlight
            loading
            disabled={!props.userPermissions}
            multiple
            disableCloseOnSelect
            value={intents ? intents : []}
            disabledItemsFocusable
            onChange={(e, intent) => {
              handleChangeIntent(intent);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {option}
              </li>
            )}
            options={list}
            renderInput={(intent) => (
              <Tooltip
                title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
                <TextField
                  {...intent}
                  disabled={!props.userPermissions}
                  label={t("intents")}
                  error={!valid}
                  helperText={valid ? "" : t("errorIntents")}
                />
              </Tooltip>
            )}></Autocomplete>
        </Tooltip>
      </div>
    </Fragment>
  );
}
