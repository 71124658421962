import React from "react";
import { HashLoader } from "react-spinners";
import { useSelector } from "react-redux";

const Spinner = () => {
  const isLoading = useSelector((state) => state.loading);
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "100",
      }}>
      <HashLoader
        size={150}
        color={"#FFFFFF"}
        loading={isLoading ? isLoading : false}
      />
    </div>
  );
};

export default Spinner;
