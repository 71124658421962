import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ChipInput from "../../shared/components/ChipInput";

import { Box, Button, TextField } from "@mui/material";

const NewStepOne = ({
  intent,
  validateNoDuplicateName,
  validateNoDuplicatePhrases,
  onClose,
  setStep,
  setNewIntent,
  agentSelected,
}) => {
  const [name, setName] = useState("");
  const [phrases, setPhrases] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({});
  const [answerError, setAnswerError] = useState("");

  const { t } = useTranslation("intents");

  useEffect(() => {
    if (intent && Object.keys(intent).length !== 0) {
      setName(intent.name);
      setPhrases(intent.phrases);
      setAnswer(intent.response.text);
    }
  }, [intent]);

  useEffect(() => {
    const temp = {};

    if (!dirty.name && name !== "") {
      setDirty({ ...dirty, name: true });
    }
    if (!dirty.phrases && phrases.length > 0) {
      setDirty({ ...dirty, phrases: true });
    }
    let duplicatePhrases = phrases
      ? validateNoDuplicatePhrases(phrases)
      : false;
    temp.name = name ? "" : t("errorNameRequired");
    temp.name = validateNoDuplicateName(name)
      ? t("errorNameDuplicate")
      : temp.name;
    temp.phrases = phrases.length > 0 ? "" : t("errorphrasesRequired");
    temp.phrases =
      phrases.length > 0 && duplicatePhrases
        ? t("errorphrasesDuplicate", duplicatePhrases)
        : temp.phrases;
    setErrors({ ...temp });
  }, [name, phrases, answer, t]);

  const nextStep = useCallback(() => {
    let newIntent = intent;
    newIntent.name = name;
    newIntent.phrases = phrases;
    newIntent.response = {
      text: answer,
    };
    setNewIntent(newIntent);
    setStep(1);
  }, [setStep, setNewIntent, name, phrases, answer]);

  const hasErrors = useMemo(
    () =>
      Object.keys(errors).some((x) => errors[x] !== "") || answerError !== "",
    [errors, answerError],
  );

  return (
    <div>
      <Box p={2} sx={{ width: 600 }}>
        <TextField
          id="modal-name"
          label={t("name")}
          autoFocus
          value={name}
          variant="standard"
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          onChange={(e) => setName(e.target.value)}
          error={errors.name && dirty.name}
          helperText={errors.name && dirty.name ? errors.name : ""}
        />
        <div id="new-intent-name-phrases">
          <ChipInput
            name="phrases"
            source="phrases"
            sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
            label={t("intents:phrases")}
            value={phrases}
            getValidation={{ get: true, agentSelected }}
            updateProps={async (e) => {
              setPhrases(e);
            }}
            error={
              errors.phrases !== "" &&
              errors.phrases !== undefined &&
              dirty.phrases
                ? errors.phrases
                : ""
            }
          />{" "}
        </div>
        <div id="new-intent-name-answer">
          <ChipInput
            name="answer"
            source="answer"
            sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
            label={t("intents:answer")}
            value={answer}
            updateProps={(e) => {
              setAnswer(e);
            }}
            error={answerError}
            answerLengthError={true}
            lengthError={(e) => {
              if (e) {
                setAnswerError(t("intents:lengthValidation"));
              } else {
                setAnswerError("");
              }
            }}
          />
        </div>
      </Box>
      <Box
        p={2}
        sx={{ minWidth: 400, display: "flex", justifyContent: "flex-end" }}>
        <Button id="modal1-cancel-button" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          id="modal1-next-button"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={() => nextStep()}>
          {t("next")}
        </Button>
      </Box>
    </div>
  );
};

export default NewStepOne;
