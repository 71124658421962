import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Transition from "../shared/helper/transitionDialog";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import {
  validateUrlHost,
  validateNameCharacters,
  validateEmpty,
} from "../shared/helper/validations";
import { Button } from "@mui/material";

const EditModal = ({ typeModal, data, open, onClose, onSave }) => {
  const [name, setName] = useState("");
  const [host, setHost] = useState("");
  const [_id, setId] = useState("");
  const [errors, setErrors] = useState({});
  const { t } = useTranslation("endpoints");
  const dataArray = Array.isArray(data) ? data : data ? [data] : [];

  useEffect(() => {
    if (typeModal !== "new") {
      setName(data.name);
      setHost(data.host);
      setId(data._id);
    } else {
      setName("");
      setHost("");
      setId("");
    }
  }, [data]);

  const validateName = (name) => {
    const allDataArray = dataArray[0].allData || [];
    const filteredData = allDataArray.filter((item) => item._id !== _id);
    if (filteredData.some((item) => item.name === name)) {
      return t("endpoints:errorNameExist");
    } else if (!validateNameCharacters(name)) {
      return t("endpoints:validationName");
    } else {
      return null;
    }
  };

  const validateHost = (host) => {
    if (!validateUrlHost(host)) {
      return t("endpoints:validationHost");
    } else {
      return null;
    }
  };

  const validate = useCallback(() => {
    const temp = {};
    temp.name = name ? validateName(name) : null;
    temp.host = host ? validateHost(host) : null;
    setErrors({ ...temp });
  }, [name, host]);

  useEffect(() => {
    validate();
  }, [name, host, validate]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new"
          ? t("endpoints:addEndpoint")
          : t("endpoints:editEndpoint")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          label={t("nameModal")}
          required
          sx={{ width: "75%", marginBottom: "0.5rem", marginTop: "0.5rem" }}
          value={name}
          error={errors.name}
          helperText={errors.name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          variant="standard"
          label={t("host")}
          required
          sx={{ width: "75%", marginBottom: "0.5rem", marginTop: "0.5rem" }}
          value={host}
          error={errors.host}
          helperText={errors.host}
          onChange={(e) => {
            setHost(e.target.value);
          }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("endpoints:modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            Object.keys(errors).some((x) => errors[x]) ||
            !validateEmpty(host) ||
            !validateEmpty(name)
              ? true
              : false
          }
          onClick={() => onSave({ name, host })}>
          {t("endpoints:modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
