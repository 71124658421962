import React from "react";

export const CopyIconIntents = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="atom/icon-copy">
      <path
        id="Vector"
        d="M6 12C5.63333 12 5.31944 11.8695 5.05833 11.6084C4.79722 11.3473 4.66667 11.0334 4.66667 10.6667V2.66671C4.66667 2.30004 4.79722 1.98615 5.05833 1.72504C5.31944 1.46393 5.63333 1.33337 6 1.33337H12C12.3667 1.33337 12.6806 1.46393 12.9417 1.72504C13.2028 1.98615 13.3333 2.30004 13.3333 2.66671V10.6667C13.3333 11.0334 13.2028 11.3473 12.9417 11.6084C12.6806 11.8695 12.3667 12 12 12H6ZM6 10.6667H12V2.66671H6V10.6667ZM3.33333 14.6667C2.96667 14.6667 2.65278 14.5362 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3334V4.00004H3.33333V13.3334H10.6667V14.6667H3.33333Z"
        fill="#F1F1F1"
      />
    </g>
  </svg>
);
