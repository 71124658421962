import React, { Fragment, useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTranslation } from "react-i18next";

export function DayPicker({ ...props }) {
  const [days, setDays] = React.useState(new Array(7).fill(""));
  const { t } = useTranslation("days");

  let dayList = [
    {
      name: t("monday"),
      nameShort: t("mondayShort"),
      value: "M",
    },
    {
      name: t("tuesday"),
      nameShort: t("tuesdayShort"),
      value: "T",
    },
    {
      name: t("wednesday"),
      nameShort: t("wednesdayShort"),
      value: "W",
    },
    {
      name: t("thursday"),
      nameShort: t("thursdayShort"),
      value: "Th",
    },
    {
      name: t("friday"),
      nameShort: t("fridayShort"),
      value: "F",
    },
    {
      name: t("saturday"),
      nameShort: t("saturdayShort"),
      value: "S",
    },
    {
      name: t("sunday"),
      nameShort: t("sundayShort"),
      value: "Sn",
    },
  ];

  function setDay(day) {
    let daysClone = [].concat(days);
    dayList.forEach((dayObj, i) => {
      if (dayObj.value === day) {
        if (daysClone[i] === dayObj.value) {
          daysClone[i] = "";
        } else {
          daysClone[i] = dayObj.value;
        }
      }
    });
    props.daySelected(daysClone);
  }

  useEffect(() => {
    if (props.days.length > 0) {
      setDays(props.days);
    }
  }, [props.days]);

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{
          marginBottom: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          display: "block",
          width: "fit-content",
          border: "1px solid #333333 !important",
        }}>
        {dayList.map((day) => (
          <Button key={day}
            variant="contained"
            disabled={props.disabled}
            onClick={() => setDay(day.value)}
            sx={{
              width: "50px",
              border: "0",
              "&&": {
                backgroundColor: days.includes(day.value)
                  ? "#2C2F88 !important"
                  : "#D5D5E7 !important",
                color: days.includes(day.value)
                  ? "#F1F1F1 !important"
                  : "#7E7E7E !important",
              },
              "&&:hover": {
                backgroundColor: days.includes(day.value)
                  ? "var(--primary-azul-brillante, #3A3EB5) !important"
                  : "#ddddeb !important",
                color: days.includes(day.value)
                  ? "#F1F1F1 !important"
                  : "#979797 !important",
              },
              "&&:disabled": {
                backgroundColor: "#D5D5E7 !important",
                color: "#7E7E7E !important",
              },
            }}>
            {props.fullName ? day.name : day.nameShort}
          </Button>
        ))}
      </ButtonGroup>
    </Fragment>
  );
}
