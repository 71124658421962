import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import Transition from "../../shared/helper/transitionDialog";
import ChipInput from "../../shared/components/ChipInput";

const EditModal = ({
  intent,
  open,
  onClose,
  onSave,
  validateNoDuplicateName,
  validateNoDuplicatePhrases,
  deleteValidate,
}) => {
  const [editIntent, setEditIntent] = useState();
  const [errors, setErrors] = useState({});
  const [itsDefault, setItsDefault] = useState(false);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    //It checks if it is the default intent welcome intent
    //TODO: Ver posibilidad de agregar un booleano en intent "defaultwelcome" para solucionar el condicional
    setItsDefault(deleteValidate(intent?.name));
    //set the modal with the intent to edit
    if (intent) {
      setEditIntent(JSON.parse(JSON.stringify(intent)));
      setDisplayName(intent.name.replace(/-/g, " "));

    }
  }, [intent]);

  const { t } = useTranslation("intents");

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const spacesToDashes = useCallback((str) => {
    if (!str) return "";
  
    const result = str.replace(/\s+/g, "-");
    setDisplayName(str);
  
    return result;
  }, [setDisplayName]);

  useEffect(() => {
    const temp = {};

    let duplicatePhrases = editIntent?.phrases
      ? validateNoDuplicatePhrases(editIntent?.phrases, editIntent?._id)
      : false;
    temp.name = editIntent?.name ? "" : t("errorNameRequired");
    temp.name =
      editIntent?.name &&
      validateNoDuplicateName(editIntent?.name, editIntent?._id)
        ? t("errorNameDuplicate")
        : temp.name;
    temp.phrases =
      editIntent?.phrases?.length > 0 ? "" : t("errorphrasesRequired");
    temp.phrases =
      editIntent?.phrases?.length > 0 && duplicatePhrases
        ? t("errorphrasesDuplicate", duplicatePhrases)
        : temp.phrases;
    setErrors({ ...temp });
  }, [editIntent, t]);

  const handleNameChange = (e) => {
    const name = e.target.value;
    const newName = spacesToDashes(name);
    setDisplayName(name);
    setEditIntent({ ...editIntent, name: newName });
  };

  const handleClose = () => {
    setEditIntent(JSON.parse(JSON.stringify(intent)));
    setDisplayName(intent.name);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}>
      <Box p={2} sx={{ width: 600 }}>
        <Typography variant="h6" gutterBottom>
          {t("editIntent")}
        </Typography>
        <TextField
          id="modal-name"
          name="name"
          source="name"
          variant="standard"
          label={t("intents:name")}
          disabled={itsDefault}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={displayName}
          onChange={handleNameChange}
          error={errors.name !== "" && errors.name !== undefined}
          helperText={errors.name}
          fullWidth
        />
        <ChipInput
          id="edit-intent-name-phrases"
          name="phrases"
          source="phrases"
          label={t("intents:phrases")}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={editIntent?.phrases}
          updateProps={(e) => {
            setEditIntent({ ...editIntent, phrases: e });
          }}
          error={
            errors.phrases !== "" && errors.phrases !== undefined
              ? errors.phrases
              : ""
          }
        />
        <ChipInput
          id="edit-intent-name-answer"
          name="answer"
          source="answer"
          disabled={itsDefault}
          label={t("intents:answer")}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={editIntent?.response?.text}
          updateProps={(e) => {
            let tempResponse = editIntent?.response;
            tempResponse.text = e;
            setEditIntent({ ...editIntent, response: tempResponse });
          }}
        />
      </Box>
      <DialogActions>
        <Button id="modal-cancel-button" onClick={handleClose}>{t("cancel")}</Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={hasErrors}
          onClick={() => onSave(editIntent)}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
