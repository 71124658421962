
import React, {Fragment, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import styles from "../Login.module.css";

import { validateEmail } from "../../shared/helper/validations";

const ForgotPassword = ({dialogForgotPassword, setEmail, recoverPassword, errorModal, email, blockUser, setBlockUser, setDialogForgotPassword, setErrors, setErrorModal }) => {
  const { t } = useTranslation();
  const [timer, setTimer] = useState("");
  const [restartTime, setRestartTime] = useState(true);

  useEffect(() => {
    if (blockUser.value) {
      setTimer(blockUser.time);
      setRestartTime(false);
    }
  }, [blockUser.value]);

  useEffect(() => {
    if (blockUser.value) {
      const intervalId = setInterval (() => {
        setTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            setBlockUser({});
            setRestartTime(true);
            return 0;
          } 
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [blockUser.value]);

  const formatTime = (time) => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const validateModal = () => {
    let errorModal = {};

    if (email === "") {
      errorModal.email = t("login:errorEmailEmpty");
    }else if (!validateEmail(email)) {
      errorModal.email = t("login:errorWrongEmail");
    }

    return errorModal;
  };

  const handleSendPassword = () => {
    const errorsTemp2Auth = validateModal();
    if (errorsTemp2Auth.email) {
      setErrorModal(errorsTemp2Auth);
      return;
    }
    recoverPassword();
  };

  return (
    <Fragment>
      {dialogForgotPassword == 1 && (
        <div className={styles.content1}>
          <div className={styles.content2}>
            <div className={styles.ttuloDescripcion}>
              <div className={styles.emoji}>🤔</div>
              <div className={styles.tituloDescripcion}>
                <div className={styles.titulo1}>
                  <p className={styles.titulo2}>¿Olvidaste tu</p>
                  <p className={styles.titulo2}>contraseña?</p>
                </div>
                <div className={styles.elUniversoDe}>
                  Te enviaremos una contraseña nueva por correo electrónico.
                  ¿A dónde quieres recibirla?
                </div>
              </div>
            </div>
            <div className={styles.formRecordarme}>
              <div className={styles.form}>
                <TextField
                  id="recovery-pass-email"
                  label="Correo electrónico"
                  fullWidth
                  variant="filled"
                  className={styles.textField}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={errorModal.email ? true:false}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validateModal();
                    }
                  }}
                />
              </div>
              {
                (errorModal.email) ?
                  <div id="recovery-email-error" className={styles.helper2FaError}>*{errorModal.email}</div> : null
              }
            </div>
            <div className={styles.twoFAbotForm}>
              <Button
                variant="contained"
                type="email"
                id="login_send_button"
                disabled={!restartTime}
                onClick={() => {
                  handleSendPassword();
                }}
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  minWidth: "248px",
                  width: "100%",
                }}>
                { !restartTime ? `${t("login:remainingTime")}: ${formatTime(timer)}` : t("login:sendPassword")}
              </Button>
              <a 
                id="login_cancel_button"
                onClick={() => {
                  setEmail("");
                  setBlockUser({});
                  setDialogForgotPassword(0);
                  setErrors({});
                }}
                className={styles.buttonTypeLink}>
                {t("login:returnLogin")}
              </a>
            </div>
            <div id="password-request-limit-alert-box" className={!restartTime ? styles.alertBoxError : styles.alertBox}>
              <div className={styles.infoParent}>
                {!restartTime ? <WarningAmberOutlinedIcon className={styles.infoIconError} /> : <InfoOutlinedIcon className={styles.infoIcon} /> }
                <div className={styles.titleParent}>
                  <div id="password-request-limit-alert-title" className={!restartTime ? styles.titleAlertError : styles.titleAlert }>
                    {!restartTime ? t("login:tittleAlertError") : t("login:tittleAlert")}
                  </div>
                  <div id="password-request-limit-alert-description" className={styles.descriptionAlert}>
                    {!restartTime ? t("login:descriptionAlertError") : t("login:descriptionAlert")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {dialogForgotPassword == 2 && (
        <div className={styles.content1}>
          <div className={styles.content2}>
            <div className={styles.ttuloDescripcion}>
              <div className={styles.emoji}>😌</div>
              <div className={styles.tituloDescripcion}>
                <div className={styles.titulo1}>
                  <p id="title-all-ready" className={styles.titulo2}>¡Todo listo!</p>
                </div>
                <div id="text-all-ready" className={styles.elUniversoDe}>
                  Puedes iniciar sesión con la nueva contraseña que enviamos a
                  tu correo electrónico.
                </div>
              </div>
            </div>
            <div className={styles.twoFAbotForm}>
              <Button
                variant="contained"
                id="login_return_button"
                onClick={() => {
                  setEmail("");
                  setDialogForgotPassword(0);
                }}
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  minWidth: "248px",
                  width: "100%",
                }}>
                {t("login:returnLogin")}
              </Button>
            </div>
            <div className={styles.necesitasAyudaParaIniciarSParent}>
              <div >
                <span id="question-all-ready">¿No recibiste la contraseña?</span>
              </div>
              <a
                id="login_resend_link"
                style={{
                  marginLeft: "1%",
                }}
                className={styles.buttonTypeLink}
                onClick={() => {
                  if (validateModal()) recoverPassword();
                }}>
                {t("login:clickToResend")}
              </a>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ForgotPassword;