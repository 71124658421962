import React, { useState } from "react";
import { DeleteRowModal } from "../../shared/helper/tableHelper";
import { Box, Button, Typography, Drawer } from "@mui/material";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import CardTrace from "./CardTrace.component";
import { useTranslation } from "react-i18next";

const TracesHistory = ({
  traces,
  agentPages,
  traces_intents_toggle,
  handlePublish,
  handleDelete,
}) => {
  const [open, setOpen] = useState(false);
  const [deleteChange, setDeleteChange] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {traces.length > 0 ? (
        <Button
          id="traces-history-button"
          onClick={() => setOpen(true)}
          variant="contained"
          sx={{
            position: "fixed",
            right: -40,
            top: "50%",
            paddingBottom: 1.5,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            transform: "translate(0%, -50%) rotate(-90deg)",
          }}>
          <KeyboardDoubleArrowUpIcon sx={{ marginRight: "10%" }} />
          {t("tracesHistory:buttonOpen")}
        </Button>
      ) : null}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ width: 450 }}>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", marginTop: 2, marginBottom: 1 }}>
          {t("tracesHistory:title")}
        </Typography>
        <Box sx={{ width: 450, maxHeight: "80vh", overflowY: "auto" }}>
          {traces.map((trace) => (
            <CardTrace key={trace} trace={trace} agentPages={agentPages} />
          ))}
        </Box>
        {traces_intents_toggle ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}>
            <Button
              id="traces-publish-button"
              variant="contained"
              sx={{ width: "85%", marginLeft: "7.5%", marginTop: 2 }}
              onClick={() => {
                handlePublish();
                setOpen(false);
              }}>
              {t("tracesHistory:buttonPublish")}
            </Button>
            <Button
              id="traces-delete-button"
              variant="contained"
              color="error"
              sx={{
                width: "85%",
                marginLeft: "7.5%",
                marginTop: 1,
              }}
              onClick={() => {
                setDeleteChange(true);
              }}>
              {t("tracesHistory:buttonDelete")}
            </Button>
          </div>
        ) : null}
      </Drawer>
      <DeleteRowModal
        title={t("tracesHistory:sureDelete")}
        open={deleteChange}
        onClose={() => {
          setDeleteChange(false);
        }}
        onDelete={() => {
          setOpen(false);
          setDeleteChange(false);
          handleDelete();
        }}
      />
    </div>
  );
};

export default TracesHistory;
