import React, { useState, useEffect, useMemo } from "react";
import { get, post, put, remove } from "../../shared/http/httpService";
import { useTranslation } from "react-i18next";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./EditModal.component";
import { useSnackbar } from "notistack";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { DeleteRowModal } from "../../shared/helper/tableHelper";
import { Box, IconButton, Tooltip, Typography, Button } from "@mui/material";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import { ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";

//Clonar el tema y eliminar la propiedad shadows
const modifiedLightTheme = { ...tableLightTheme, shadows: [] };
const modifiedDarkTheme = { ...tableDarkTheme, shadows: [] };

const TransferTable = ({ providersData, userPermissions }) => {
  const { t, i18n } = useTranslation("transfersTable");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [data, setData] = useState([]);
  const theme = useSelector((state) => state.theme);

  const columns = useMemo(
    () => [
      {
        header: t("inboundNameTransfer"),
        accessorKey: "inboundName",
      },
      {
        header: t("transferName"),
        accessorKey: "transferName",
        filterVariant: "multi-select",
        filterSelectOptions: clientList.map((x) => x.name),
        filterFn: (rows, id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(rows.original.transferName);
        },
        //change render value to displayName
        Cell: ({ cell }) => {
          const client = clientList.find((x) => x.name === cell.getValue());
          return client ? client.display_name : cell.getValue();
        },
      },
      {
        header: t("providerTransfer"),
        accessorKey: "provider",
      },
      {
        header: t("descriptionTransfer"),
        accessorKey: "description",
      },
    ],
    [clientList, i18n.language],
  );

  useEffect(() => {
    getTransferTable();
    getClientList();
  }, []);

  const getTransferTable = () => {
    get("/transferTable")
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRoute"), {
          variant: "error",
        });
      });
  };

  const getClientList = () => {
    get("/clients/names")
      .then((data) => {
        data.sort((a, b) => a.display_name.localeCompare(b.display_name));
        setClientList(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingRouteTransfer"), {
          variant: "error",
        });
      });
  };

  const newTransferTable = (row) => {
    const body = {
      inboundName: row.inboundName,
      transferName: row.transferName,
      provider: row.provider,
      description: row.description,
    };
    post("/transferTable", body)
      .then(() => {
        enqueueSnackbar(t("newSavedTransfer"), {
          variant: "success",
        });
        getTransferTable();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorTransfer"), {
          variant: "error",
        });
      });
  };

  const updateTransferTable = (row, values) => {
    const body = {
      _id: row.original._id,
      inboundName: values.inboundName,
      transferName: values.transferName,
      provider: values.provider,
      description: values.description,
    };
    put("/transferTable", body)
      .then(() => {
        enqueueSnackbar(t("updatedTransfer"), {
          variant: "success",
        });
        getTransferTable();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorTransfer"), {
          variant: "error",
        });
      });
  };

  const deleteTransferTable = (row) => {
    const body = {
      _id: row.original._id,
    };
    remove("/transferTable", body)
      .then(() => {
        enqueueSnackbar(t("deletedTransfer"), {
          variant: "success",
        });
        getTransferTable();
        setDeleteModalOpen(false);
        seteditRow(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorTransfer"), {
          variant: "error",
        });
      });
  };

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 50,
      },
    },
    positionActionsColumn: "last",
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    enableDelete: true,
    editDisplayMode: "modal",
    enableEditing: true,
    initialState: {
      density: "compact",
    },
    mrtTheme: () => ({
      baseBackgroundColor: theme.darkTheme
        ? modifiedLightTheme.palette.background.default
        : modifiedDarkTheme.palette.background.default,
    }),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip
          arrow
          placement="left"
          title={userPermissions ? t("editButton") : t("noPermissionTooltip")}>
          <span>
            <IconButton
              id="edit-icon-transfer"
              onClick={() => {
                setEditModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          arrow
          placement="right"
          title={
            userPermissions ? t("deleteButton") : t("noPermissionTooltip")
          }>
          <span>
            <IconButton
              id="delete-icon-transfer"
              sx={{ color: "deleteIcon.color" }}
              onClick={() => {
                setDeleteModalOpen(true);
                seteditRow(row);
              }}
              disabled={!userPermissions}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ marginLeft: "1rem" }}>
          <Typography variant="h4">{t("tittleTransfers")}</Typography>
          <Typography variant="subtitle3">{t("subTittleTransfers")}</Typography>
        </div>
        <Tooltip
          arrow
          placement="right"
          title={userPermissions ? "" : t("noPermissionTooltip")}>
          <span>
            <Button
              id="new-button-transfer"
              className="mrt-create-new-account-button"
              onClick={() => {
                setEditModalOpen(true);
                setTypeModal("new");
              }}
              disabled={!userPermissions}
              variant="contained">
              {t("addButtonTransfer")}
            </Button>
          </span>
        </Tooltip>
      </div>
    ),
  });

  return (
    <div>
      <ThemeProvider
        theme={theme.darkTheme ? modifiedLightTheme : modifiedDarkTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteTransferTable(editRow);
        }}
        title={t("tittleDeleteModalTransfer", {
          inboundName: editRow?.original?.inboundName,
          transferName: clientList.find(
            (x) => x.name === editRow?.original?.transferName,
          )?.display_name,
        })}
      />
      <EditModal
        providersData={providersData}
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        data={{
          inboundName: editRow?.original?.inboundName,
          transferName: editRow?.original?.transferName,
          provider: editRow?.original?.provider,
          description: editRow?.original?.description,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newTransferTable(values);
            setEditModalOpen(false);
          } else {
            updateTransferTable(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
        clientList={clientList}
      />
    </div>
  );
};

export default TransferTable;
