import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { withSnackbar } from "notistack";
import { useSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { get, put, post, remove } from "../shared/http/httpService";
import { defaultAgent } from "../shared/helper/agentsHelper";
import Delete from "@mui/icons-material/Delete";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import EditModal from "./EditModal.component";
import { Button } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import ChipInput from "../shared/components/ChipInput";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import RefreshIcon from "@mui/icons-material/Refresh";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const host_api_gw = process.env.REACT_APP_API_GW;
const FormData = require("form-data");

const Files = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("files");
  const [data, setData] = useState([]);
  const [accountNames, setAccountNames] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [accountSelected, setAccountSelected] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const theme = useSelector((state) => state.theme);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let auth = useSelector((state) => state.auth);
  const inputFile = useRef(null);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "file" && permission.fullAccess !== true,
    );
    setUserPermissions(hasPermissions);
  };

  useEffect(() => {
    getDataFiles();
    getUserPermisions();
  }, [accountSelected]);
  useEffect(() => {
    getAccountNames();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: t("files:name"),
        accessorKey: "name",
        size: 150,
        enableEditing: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        Cell: ({ cell }) => {
          const fileName = cell.getValue();
          let downloadUrl = "";
          if (fileName.startsWith("report-data-")) {
            if (fileName.startsWith("report-data-conversationsPerDay")) {
              downloadUrl = `${host_api_gw}/file-download/${encodeURIComponent(clientName)}/${encodeURIComponent(accountSelected)}/${encodeURIComponent(fileName)}`;
            } else {
              downloadUrl = `${host_api_gw}/file-download/${encodeURIComponent(clientName)}/${encodeURIComponent(clientName)}/${encodeURIComponent(fileName)}`;
            }
          } else {
            downloadUrl = `${host_api_gw}/file-download/${encodeURIComponent(clientName)}/${encodeURIComponent(accountSelected)}/${encodeURIComponent(fileName)}`;
          }
          return (
            <div>
              <a
                href={downloadUrl}
                download={cell.getValue()}
                target="_blank"
                rel="noopener noreferrer">
                <InsertDriveFileIcon />
                {fileName}
              </a>
            </div>
          );
        },
      },
      {
        header: t("files:description"),
        accessorKey: "description",
        size: 150,
      },
      {
        header: "Tags",
        accessorKey: "tags",
        size: 50,
        Cell: ({ cell }) => {
          return (
            <ChipInput
              disabled
              name="tags"
              source="tags"
              label="Tags"
              value={cell.getValue()}
            />
          );
        },
        muiTableHeadCellProps: {
          align: "left",
        },
      },
    ],
    [data, i18n.language],
  );

  const getAccountNames = () => {
    get("/agents")
      .then((data) => {
        orderAlphabeticallyWithAttribute(data.agents, "display_name");
        let default_agent = defaultAgent(data.agents);
        setClientName(data.clientName);
        setAccountNames(data.agents);
        setAccountSelected(default_agent.name);
      })
      .catch(() => {
        enqueueSnackbar(t("pages:errorGettingAccountNames"), {
          variant: "error",
        });
      });
  };

  const fileUpload = async (e) => {
    try {
      e.preventDefault();
      let formData = new FormData();
      const file = e.target.files[0];
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("folder", accountSelected);
      formData.append("clientName", accountSelected);
      let headers = {};
      headers["Content-type"] = "multipart/form-data";
      post("/file", formData, headers)
        .then(() => {
          enqueueSnackbar(t("files:fileUploaded"), {
            variant: "success",
          });
          getDataFiles();
        })
        .catch(() => {
          enqueueSnackbar(t("files:errorCreatingFile"), {
            variant: "error",
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getDataFiles = () => {
    if (accountSelected !== "" && accountSelected !== " ") {
      get("/file/" + accountSelected)
        .then((data) => {
          console.log(data);
          setData(data);
        })
        .catch(() => {
          enqueueSnackbar(t("files:errorGettingFiles"), {
            variant: "error",
          });
        });
    }
  };

  const uploadFile = () => {
    inputFile.current.click();
  };

  const updateFile = (row, values) => {
    const tags = values.tags.map((v) => v.toLowerCase());
    const body = {
      _id: row.original._id,
      description: values.description,
      tags: tags,
    };
    put("/file", body)
      .then(async () => {
        enqueueSnackbar(t("files:fileUpdated"), {
          variant: "success",
        });
        getDataFiles();
      })
      .catch(() => {
        enqueueSnackbar(t("files:errorUpdatingFile"), {
          variant: "error",
        });
      });
  };
  const deleteFile = (row) => {
    remove("/file/" + row.original.name, {
      folder: accountSelected,
    })
      .then(async () => {
        enqueueSnackbar(t("files:fileDeleted"), {
          variant: "success",
        });
        setDeleteModalOpen(false);
        getDataFiles();
      })
      .catch(() => {
        enqueueSnackbar(t("files:errorDeletingFile"), {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <Typography variant="h4" sx={{ mb: "1rem" }}>
        {t("files:files")}
      </Typography>
      <ThemeProvider theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={(e) => fileUpload(e)}
          style={{ display: "none" }}
        />
        <MaterialReactTable
          localization={
            i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
          }
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
              size: 50,
            },
          }}
          positionActionsColumn="last"
          columns={columns}
          data={data}
          enableRowActions
          enableColumnOrdering
          enableGlobalFilter={false}
          enableEditing
          onEditingRowSave={updateFile}
          renderRowActions={({ row }) => (
            <Box
              sx={{ justifyContent: "center", gap: "1rem", display: "flex" }}>
              <Tooltip
                arrow
                placement="left"
                title={
                  !userPermissions
                    ? t("files:editFile")
                    : t("files:noPermissionTooltip")
                }>
                <span>
                  <IconButton
                    onClick={() => {
                      setEditModalOpen(true);
                      seteditRow(row);
                    }}
                    disabled={userPermissions}>
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                arrow
                placement="right"
                title={
                  !userPermissions
                    ? t("files:deleteButton")
                    : t("files:noPermissionTooltip")
                }>
                <span>
                  <IconButton
                    sx={{ color: "deleteIcon.color" }}
                    onClick={() => {
                      setDeleteModalOpen(true);
                      seteditRow(row);
                    }}
                    disabled={userPermissions}>
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          )}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <Tooltip arrow title={t("files:refreshData")}>
                <IconButton
                  onClick={() => {
                    getDataFiles();
                  }}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <Tooltip
                arrow
                placement="right"
                title={!userPermissions ? "" : t("files:noPermissionTooltip")}>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      uploadFile();
                    }}
                    disabled={userPermissions}
                    variant="contained">
                    {t("files:addFile")}
                  </Button>
                </span>
              </Tooltip>
              <InputLabel
                id="agent-label"
                sx={{
                  display: "inline",
                  height: "fit-content",
                  marginRight: "15px",
                  marginLeft: "10px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}>
                {t("pages:agent")}
              </InputLabel>
              <FormControl variant="standard">
                <Select
                  id='combo-agent'
                  value={accountSelected}
                  onChange={(e) => {
                    setAccountSelected(e.target.value);
                    getDataFiles();
                  }}
                  sx={{
                    height: "fit-content",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}>
                  {accountNames.map((item, index) => (
                    <MenuItem
                      name={item.display_name}
                      value={item.name}
                      key={index}
                      id={`menu-item-${index}`}>
                      {item.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        />
      </ThemeProvider>
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
        data={{
          _id: editRow?.original?._id,
          name: editRow?.original?.name,
          description: editRow?.original?.description,
          tags: editRow?.original?.tags,
        }}
        onSave={(values) => {
          updateFile(editRow, values);
          setEditModalOpen(false);
        }}
      />
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteFile(editRow);
        }}
        title={t("files:tittleDeleteModal")}
      />
    </React.Fragment>
  );
};

export default withTranslation()(withSnackbar(Files));
