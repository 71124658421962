import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Paper } from "@mui/material";
// Analytics Components
import ConversationPerDay from "./Components/ConversationPerDay.component";
/*The conversation about the report is commented 
  on because it was for a specific client and case that no
  longer makes sense; it should be consulted directly with chattigo*/
//import ConversationsChattigo from "./Components/ConversationsChattigo";
import AverageTalkTime from "./Components/TalkTime";
import LoggedPerDay from "./Components/LoggedPerDay";
import ConversationalInteractions from "./Components/ConversationalInteractions";
import MonthInteractions from "./Components/MonthInteractions";
import RankingIntents from "./Components/RankingIntents";
import Feedback from "./Components/Feedback.component";
import RankingTags from "./Components/RankingTags";
import InteractionsPerDay from "./Components/InteractionsIaPerDay";
import { useTranslation } from "react-i18next";

export default function Analytics () {
  const [option, setOption] = useState("MonthInteractions");
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setOption(value);
  };

  const renderGraphic = () => {
    switch (option) {
      case "AverageTalkTime":
        return <AverageTalkTime />;
      case "ConversationPerDay":
        return <ConversationPerDay />;
      case "LoggedPerDay":
        return <LoggedPerDay />;
      case "ConversationalInteractions":
        return <ConversationalInteractions />;
      case "MonthInteractions":
        return <MonthInteractions />;
      case "RankingIntents":
        return <RankingIntents />;
      case "Feedback":
        return <Feedback />;
      case "RankingTags":
        return <RankingTags />;
        /*The conversation about the report is commented 
         on because it was for a specific client and case that no
         longer makes sense; it should be consulted directly with chattigo*/
      // case "ConversationsChattigo":
      //   return <ConversationsChattigo />;
      case "InteractionsPerDay":
        return <InteractionsPerDay />;
      default:
        return <ConversationPerDay />;
    }
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper>
          <div
            style={{
              padding: "15px",
              maxWidth: "20%",
              borderRadius: "2%",
              marginBottom: "15px",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            <FormControl component="fieldset">
              <InputLabel id="graph-label">{t("analytics:selectGraph")}</InputLabel>
              <Select
                labelId="graph-label"
                id="graph-select"
                value={option}
                label="Age"
                onChange={handleChange}
                style={{
                  minWidth: "150px",
                }}>
                <MenuItem value={"MonthInteractions"}>
                  {t("analytics:interactionsPerPeriod")}
                </MenuItem>
                <MenuItem value={"ConversationPerDay"}>
                  {t("analytics:conversationPerDay")}
                </MenuItem>
                <MenuItem value={"AverageTalkTime"}> {t("analytics:talkTime")}</MenuItem>
                <MenuItem value={"LoggedPerDay"}>{t("analytics:usersPerDay")}</MenuItem>
                <MenuItem value={"ConversationalInteractions"}>
                  {t("analytics:interactionsPerConversation")}
                </MenuItem>
                <MenuItem value={"RankingIntents"}>{t("analytics:intentsRanking")}</MenuItem>
                <MenuItem value={"Feedback"}>{t("analytics:sat")}</MenuItem>
                <MenuItem value={"RankingTags"}>{t("analytics:tagsRanking")}</MenuItem>
                {/* The conversation about the report is commented on because it was 
                for a specific client and 
                case that no longer makes sense; it should be consulted directly with chattigo
                <MenuItem value={"ConversationsChattigo"}>{t("analytics:conversationsChattigo")}
                </MenuItem>
                */}
                <MenuItem value={"InteractionsPerDay"}>{t("analytics:interactionsIa")}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Paper>
      </Container>
      {renderGraphic()}
    </React.Fragment>
  );
}
