import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  TextField,
  List,
  ListItem,
  IconButton,
  FormControlLabel,
  Switch,
  Card,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Container, Draggable } from "@smooth-dnd/react";
import { useState } from "react";
import "./Quick.replies.css";

export default function QuickReplies(props) {
  const [repliesFromArray, setRepliesFromArray] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorIndex, setErrorIndex] = useState({});
  const [valid, setValid] = useState(true);
  const [quickReplies, setQuickReplies] = useState({});
  const [filteredIntents, setFilteredIntents] = useState([]);
  const [dirty, setDirty] = useState({});
  const intents = props.intents;
  const { t } = useTranslation();

  useEffect(() => {
    if (props.editNode?.data) {
      props.updateErrors(errors, errorIndex, valid);
    }
    update();
  }, [errors, errorIndex, valid]);

  useEffect(() => {
    if (props.editNode?.data) {
      editQuickReplies(props.editNode?.data);
    } else if (props.intents && props.quickReplies) {
      editQuickReplies(props.quickReplies);
    }
    validate();
  }, [props.editNode?.id]);

  useEffect(() => {
    validate();
  }, [quickReplies]);

  const update = () => {
    if (props.editNode?.data && Object.keys(quickReplies).length > 0) {
      let editNode = props.editNode;
      editNode.data.quickReplies = quickReplies.static;
      editNode.data.quickRepliesName = quickReplies.quickRepliesName;
      editNode.data.quickRepliesDynamic = quickReplies.quickRepliesDynamic;
      props.updateQuickReplies(props.editNode, errors, errorIndex, valid);
    } else if (props.intents && props.quickReplies) {
      let quickRepliesIntent = props.quickReplies;
      quickRepliesIntent.static = quickReplies.static;
      quickRepliesIntent.quickRepliesDynamic = quickReplies.quickRepliesDynamic;
      quickRepliesIntent.quickRepliesName = quickReplies.quickRepliesName;
      quickRepliesIntent.quickRepliesSwitch = repliesFromArray;
      props.updateQuickReplies(quickRepliesIntent, errors, errorIndex, !valid);
    }
  };

  const editQuickReplies = (quickReplies) => {
    let tempQuickReplies = {};

    tempQuickReplies.static = quickReplies.quickReplies;
    tempQuickReplies.quickRepliesName = quickReplies.quickRepliesName;
    tempQuickReplies.quickRepliesDynamic = quickReplies.quickRepliesDynamic;

    if (
      quickReplies.quickRepliesDynamic?.length > 0 &&
      quickReplies.quickRepliesDynamic[0]?.arrayName !== undefined
    ) {
      setRepliesFromArray(true);
      let tempDirty = {
        arrayName: false,
        value: false,
        label: false,
        description: false,
      };
      if (quickReplies.quickRepliesDynamic[0].arrayName) {
        tempDirty.arrayName = true;
      }
      if (quickReplies.quickRepliesDynamic[0].value) {
        tempDirty.value = true;
      }
      if (quickReplies.quickRepliesDynamic[0].label) {
        tempDirty.label = true;
      }
      if (quickReplies.quickRepliesDynamic[0].description) {
        tempDirty.description = true;
      }
      setDirty(tempDirty);
    } else {
      setRepliesFromArray(false);
    }

    setQuickReplies(tempQuickReplies);
  };

  const filterIntents = (text) => {
    const filtered = intents.filter((intent) =>
      intent.name.toLowerCase().includes(text.toLowerCase()),
    );
    setFilteredIntents(filtered);
  };

  const updateQuickRepliesName = (e) => {
    let edit = quickReplies;
    edit.quickRepliesName = e.target.value;
    setQuickReplies({ ...edit });
  };

  const enableQuickRepliesFromArray = () => {
    setRepliesFromArray(!repliesFromArray);
    validate();
    let edit = quickReplies;
    setDirty({});
    edit.quickRepliesDynamic = [{}];
    edit.static = [];
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesArray = (e) => {
    let edit = quickReplies;
    if (!edit.quickRepliesDynamic || edit.quickRepliesDynamic.length === 0) {
      edit.quickRepliesDynamic = [{ arrayName: "", label: "", value: "" }];
    }
    edit.quickRepliesDynamic[0].arrayName = e.target.value;

    setDirty({ ...dirty, arrayName: true });
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesLabel = async (e, index) => {
    let edit = quickReplies;

    if (edit.static.length) {
      edit.static[index].label = e.target.value;
    }
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesDescription = async (e, index) => {
    let edit = quickReplies;

    if (edit.static.length) {
      edit.static[index].description = e.target.value;
    }
    setQuickReplies({ ...edit });
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      filterIntents("");
    }
  };

  const updateQuickRepliesValue = async (e, index) => {
    let edit = { ...quickReplies };
    filterIntents(e.target.value);

    if (edit.static.length > index) {
      const selectedIntent = filteredIntents.find(
        (intent) => intent.name === e.target.value,
      );

      if (selectedIntent) {
        edit.static[index].value = selectedIntent.phrases[0];
      } else {
        edit.static[index].value = e.target.value;
      }
    }

    setQuickReplies(edit);
  };

  const deleteQuickReply = async (e, index) => {
    let edit = quickReplies;

    edit.static.splice(index, 1);
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesDynamicValue = (e) => {
    let edit = quickReplies;
    if (!edit.quickRepliesDynamic || edit.quickRepliesDynamic.length === 0) {
      edit.quickRepliesDynamic = [{ arrayName: "", label: "", value: "" }];
    }
    edit.quickRepliesDynamic[0].value = e.target.value;
    setDirty({ ...dirty, value: true });
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesDynamicLabel = (e) => {
    let edit = quickReplies;
    if (!edit.quickRepliesDynamic || edit.quickRepliesDynamic.length === 0) {
      edit.quickRepliesDynamic = [{ arrayName: "", label: "", value: "" }];
    }
    edit.quickRepliesDynamic[0].label = e.target.value;
    setDirty({ ...dirty, label: true });
    setQuickReplies({ ...edit });
  };

  const updateQuickRepliesDynamicDescription = (e) => {
    let edit = quickReplies;
    if (!edit.quickRepliesDynamic || edit.quickRepliesDynamic.length === 0) {
      edit.quickRepliesDynamic = [{ arrayName: "", label: "", value: "" }];
    }
    edit.quickRepliesDynamic[0].description = e.target.value;
    setDirty({ ...dirty, description: true });
    setQuickReplies({ ...edit });
  };

  const handleAddQuickReply = (e, index) => {
    let edit = quickReplies;

    if (!edit.static) {
      edit.static = [];
    }

    if (edit.static.length < 10) {
      if (index !== undefined) {
        edit.static.splice(index + 1, 0, { value: null, label: null });
      } else {
        edit.static.push({ value: null, label: null });
      }
    }
    setQuickReplies({ ...edit });
  };

  const handleRemoveQuickReply = async () => {
    let edit = quickReplies;

    if (edit.static.length) {
      edit.static.pop({});
    }
    setQuickReplies({ ...edit });
  };

  const validate = () => {
    let errors = {};
    let errorIndex = {};
    let validate = false;
    let vQR = true;
    if (
      Object.keys(dirty).length > 0 ||
      (repliesFromArray && quickReplies.quickRepliesDynamic)
    ) {
      if (
        repliesFromArray &&
        quickReplies.quickRepliesDynamic &&
        quickReplies.quickRepliesDynamic.length > 0
      ) {
        if (
          dirty.arrayName &&
          quickReplies.quickRepliesDynamic[0].arrayName === ""
        ) {
          errors.quickRepliesArray = t("flowgraph:errorQRDynamicArray");
          validate = false;
        }
        if (dirty.label && quickReplies.quickRepliesDynamic[0].label === "") {
          errors.quickRepliesLabel = t("flowgraph:errorQRDynamicLabel");
          validate = false;
        }
        if (dirty.value && quickReplies.quickRepliesDynamic[0].value === "") {
          errors.quickRepliesValue = t("flowgraph:errorQRDynamicValue");
          validate = false;
        }
        if (
          dirty.description &&
          quickReplies.quickRepliesDynamic[0].description &&
          quickReplies.quickRepliesDynamic[0].description.length > 72
        ) {
          errors.quickRepliesDescription = t(
            "flowgraph:errorQRDynamicDescriptionLong",
          );
          validate = false;
        }
        if (
          dirty.arrayName === true &&
          dirty.label === true &&
          dirty.value === true &&
          Object.keys(errors).length === 0
        ) {
          validate = true;
          errorIndex = 0;
        }
        if (Object.keys(errors).length === 0 && dirty.length !== undefined) {
          validate = true;
        }
      }
    } else {
      if (quickReplies.static && quickReplies.static.length > 0) {
        errorIndex.quickRepliesValue = [];
        errorIndex.quickRepliesLabel = [];
        errorIndex.quickRepliesLabelLong = [];
        errorIndex.quickRepliesDescriptionLong = [];
        for (let i = 0; i < quickReplies.static.length; i++) {
          if (quickReplies.static[i].label === null || quickReplies.static[i].value === null) {
            vQR= false;
          }
          if (quickReplies.static[i].label === "") {
            errors.quickRepliesLabel = t("flowgraph:errorTitleIsRequired");
            errorIndex.quickRepliesLabel.push(i);
            validate = false;
          }
          if (quickReplies.static[i].value === "") {
            errors.quickRepliesValue = t("flowgraph:errorPlayloadIsRequired");
            errorIndex.quickRepliesValue.push(i);
            validate = false;
          }
          if (
            quickReplies.static[i].label &&
            quickReplies.static[i].label.length > 20
          ) {
            errors.quickRepliesLabelLong = t("flowgraph:errorTitleTooLarge");
            errorIndex.quickRepliesLabelLong.push(i);
            validate = false;
          }
          if (
            quickReplies.static[i].description &&
            quickReplies.static[i].description.length > 72
          ) {
            errors.quickRepliesDescriptionLong = t(
              "flowgraph:errorDescriptionTooLarge",
            );
            errorIndex.quickRepliesDescriptionLong.push(i);
            validate = false;
          }
          // find duplicate quick replies
          for (let j = 0; j < quickReplies.static.length; j++) {
            if (
              i !== j &&
              quickReplies.static[i].value === quickReplies.static[j].value
            ) {
              errors.quickRepliesValue = t("flowgraph:errorPlayloadDuplicated");
              errorIndex.quickRepliesValue.push(i);
              validate = false;
            }
            if (
              i !== j &&
              quickReplies.static[i].label === quickReplies.static[j].label
            ) {
              errors.quickRepliesLabel = t("flowgraph:errorLabelDuplicated");
              errorIndex.quickRepliesLabel.push(i);
              validate = false;
            }
          }
        }
      }
      if (Object.keys(errors).length === 0 && vQR) {
        validate = true;
      }
    }
    setErrorIndex(errorIndex);
    setErrors(errors);
    setValid(validate);
  };

  const arrayMove = (array, removedIndex, addedIndex) => {
    // Move an array item to a different position
    if (addedIndex >= array.length) {
      let k = addedIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(addedIndex, 0, array.splice(removedIndex, 1)[0]);
    return array;
  };

  const onDrop = (removedIndex, addedIndex) => {
    // Modifies dragged and dropped item index
    let edit = quickReplies;
    edit.static = arrayMove(edit.static, removedIndex, addedIndex);
    setQuickReplies({ ...edit });
  };

  const getQuickRepliesName = (quickReply) => {
    if (
      quickReplies.quickRepliesDynamic &&
      quickReplies.quickRepliesDynamic[0]
    ) {
      return quickReplies.quickRepliesDynamic[0][quickReply];
    }
    return "";
  };

  const truncatedPhrases = (intent) => {
    let truncatedPhrases = "";
    let isTrucated = false;
    for (let i = 0; i < intent.phrases.length; i++) {
      const newLength = (truncatedPhrases + intent.phrases[i]).length;
      if (newLength <= 50) {
        truncatedPhrases += intent.phrases[i] + ", ";
      } else {
        truncatedPhrases = truncatedPhrases.slice(0, -2) + " ...";
        isTrucated = true;
        break;
      }
    }
    if (!isTrucated) {
      truncatedPhrases = truncatedPhrases.slice(0, -2) + ".";
    }
    return truncatedPhrases.length > 0 ? truncatedPhrases : "";
  };

  return (
    <div>
      <Tooltip
        title={
          !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
        }>
        <TextField
          id="quickreply-payload"
          label={t("flowgraph:quickRepliesName")}
          variant="standard"
          className="transition-message"
          disabled={!props.userPermissions}
          InputLabelProps={{ shrink: true }}
          value={
            quickReplies.quickRepliesName === undefined
              ? ""
              : quickReplies.quickRepliesName
          }
          onChange={(e) => {
            updateQuickRepliesName(e);
          }}
        />
      </Tooltip>
      <div>
        <FormControlLabel
          control={
            <Tooltip
              title={
                !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
              }>
              <span>
                <Switch
                  disabled={!props.userPermissions}
                  checked={repliesFromArray}
                  onChange={enableQuickRepliesFromArray}
                />
              </span>
            </Tooltip>
          }
          label={
            !repliesFromArray
              ? t("flowgraph:staticMode")
              : t("flowgraph:dynamicMode")
          }
        />

        <div
          className="quickreply-buttons"
          style={{
            float: "right",
            display: !repliesFromArray ? "inline-block" : "none",
            marginTop: "7px",
          }}>
          <Tooltip
            title={
              !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                sx={{ "&:hover": { color: "#4e8cff" } }}
                fontSize="inherit"
                onClick={(e, index) => handleAddQuickReply(e, index)}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                sx={{ "&:hover": { color: "deleteIcon.color" } }}
                fontSize="inherit"
                onClick={(e, index) => handleRemoveQuickReply(e, index)}>
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <Tooltip
        title={
          !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
        }>
        <TextField
          id="quickreply-payload"
          label={t("flowgraph:quickRepliesArray")}
          variant="standard"
          disabled={!props.userPermissions}
          className="transition-message"
          error={errors.quickRepliesArray}
          helperText={errors.quickRepliesArray}
          style={{
            display: repliesFromArray ? "block" : "none",
          }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("arrayName") === undefined
              ? ""
              : getQuickRepliesName("arrayName")
          }
          onChange={(e) => {
            updateQuickRepliesArray(e);
          }}
        />{" "}
      </Tooltip>
      <Tooltip
        title={
          !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
        }>
        <TextField
          id="quickreply-label"
          label={t("flowgraph:quickRepliesLabel")}
          disabled={!props.userPermissions}
          variant="standard"
          error={errors.quickRepliesLabel}
          helperText={errors.quickRepliesLabel}
          className="transition-message"
          style={{
            display: repliesFromArray ? "block" : "none",
            marginTop: "15px",
          }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("label") === undefined
              ? ""
              : getQuickRepliesName("label")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicLabel(e);
          }}
        />
      </Tooltip>
      <Tooltip
        title={
          !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
        }>
        <TextField
          id="quickreply-value"
          label={t("flowgraph:quickRepliesValue")}
          variant="standard"
          disabled={!props.userPermissions}
          error={errors.quickRepliesValue}
          helperText={errors.quickRepliesValue}
          className="transition-message"
          style={{
            display: repliesFromArray ? "block" : "none",
          }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("value") === undefined
              ? ""
              : getQuickRepliesName("value")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicValue(e);
          }}
        />{" "}
      </Tooltip>
      <Tooltip
        title={
          !props.userPermissions ? t("flowgraph:noPermissionTooltip") : ""
        }>
        <TextField
          id="quickreply-description"
          label={t("flowgraph:quickRepliesDescription")}
          variant="standard"
          disabled={!props.userPermissions}
          className="transition-message"
          error={errors.quickRepliesDescription}
          helperText={errors.quickRepliesDescription}
          style={{
            display: repliesFromArray ? "block" : "none",
          }}
          InputLabelProps={{ shrink: true }}
          value={
            getQuickRepliesName("description") === undefined
              ? ""
              : getQuickRepliesName("description")
          }
          onChange={(e) => {
            updateQuickRepliesDynamicDescription(e);
          }}
        />{" "}
      </Tooltip>
      <FormControl
        fullWidth
        style={{
          display: !repliesFromArray ? "block" : "none",
        }}>
        <div style={{ marginTop: "5px" }}>
          <List sx={{ width: "100%" }}>
            <Container
              sx={{ display: "flex", justifyContent: "center" }}
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              dropPlaceholder={{
                animationDuration: 150,
                showOnTop: true,
                className: "cards-drop-preview",
              }}
              dragClass="card-ghost"
              dropClass="card-ghost-drop"
              onDrop={(event) => onDrop(event.removedIndex, event.addedIndex)}>
              {quickReplies.static &&
                quickReplies.static.map((quickReply, index) => (
                  <Draggable key={index}>
                    <Card
                      sx={{
                        width: "95%",
                        marginTop: "5px",
                        marginBottom: "5px",
                        marginLeft: "2.5%",
                        borderRadius: "15px",
                        boxShadow: "6px 6px 6px 0px rgba(0,0,0,0.4)",
                        backgroundColor: "cardQuickReply.background",
                        border:
                          errorIndex.quickRepliesValue &&
                          (errorIndex.quickRepliesValue.includes(index) ||
                            errorIndex.quickRepliesLabel.includes(index) ||
                            errorIndex.quickRepliesLabelLong.includes(index) ||
                            errorIndex.quickRepliesDescriptionLong.includes(
                              index,
                            ))
                            ? "2px solid #D62828"
                            : "",
                      }}>
                      <Typography sx={{ marginLeft: "5%", marginTop: "1%" }}>
                        {t("flowgraph:quickRepliesCardTitle")} {index + 1}
                      </Typography>
                      <ListItem
                        key={index}
                        sx={{ width: "100%" }}
                        secondaryAction={
                          <div>
                            <Tooltip
                              title={
                                !props.userPermissions
                                  ? t("flowgraph:noPermissionTooltip")
                                  : ""
                              }>
                              <span>
                                <IconButton
                                  disabled={!props.userPermissions}
                                  edge="end"
                                  aria-label={t("flowgraph:add")}
                                  sx={{ "&:hover": { color: "#4e8cff" } }}
                                  onClick={(e) =>
                                    handleAddQuickReply(e, index)
                                  }>
                                  <AddIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip
                              title={
                                !props.userPermissions
                                  ? t("flowgraph:noPermissionTooltip")
                                  : ""
                              }>
                              <span>
                                <IconButton
                                  disabled={!props.userPermissions}
                                  edge="end"
                                  aria-label={t("flowgraph:delete")}
                                  sx={{
                                    "&:hover": { color: "deleteIcon.color" },
                                  }}
                                  onClick={(e) => deleteQuickReply(e, index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        }>
                        <IconButton
                          className="drag-handle"
                          size="large"
                          sx={{ "&:hover": { color: "#4e8cff" } }}>
                          <DragHandleIcon />
                        </IconButton>
                        <Tooltip
                          title={
                            !props.userPermissions
                              ? t("flowgraph:noPermissionTooltip")
                              : ""
                          }>
                          <TextField
                            id="quickreply-title"
                            label={t("flowgraph:title")}
                            variant="standard"
                            disabled={!props.userPermissions}
                            className="transition-message"
                            InputLabelProps={{
                              shrink: true,
                              position: "relative",
                            }}
                            value={quickReply.label}
                            fullWidth
                            sx={{
                              marginRight: "5%",
                              marginLeft: "2%",
                              width: "45%",
                            }}
                            style={{
                              marginRight: "5%",
                              marginLeft: "2%",
                              width: "45%",
                            }}
                            onChange={(e) => updateQuickRepliesLabel(e, index)}
                            error={
                              errors.quickRepliesLabel &&
                              errorIndex.quickRepliesLabel &&
                              errorIndex.quickRepliesLabel.includes(index)
                                ? true
                                : errors.quickRepliesLabelLong &&
                                  errorIndex.quickRepliesLabelLong &&
                                  errorIndex.quickRepliesLabelLong.includes(
                                    index,
                                  )
                                  ? true
                                  : false
                            }
                            helperText={
                              errors.quickRepliesLabel &&
                              errorIndex.quickRepliesLabel &&
                              errorIndex.quickRepliesLabel.includes(index)
                                ? errors.quickRepliesLabel
                                : errors.quickRepliesLabelLong &&
                                  errorIndex.quickRepliesLabelLong &&
                                  errorIndex.quickRepliesLabelLong.includes(
                                    index,
                                  )
                                  ? errors.quickRepliesLabelLong
                                  : ""
                            }
                          />{" "}
                        </Tooltip>
                        <Tooltip
                          title={
                            !props.userPermissions
                              ? t("flowgraph:noPermissionTooltip")
                              : ""
                          }>
                          <TextField
                            id="quickreply-payload"
                            label={t("flowgraph:payload")}
                            variant="standard"
                            className="transition-message"
                            disabled={!props.userPermissions}
                            InputLabelProps={{
                              shrink: true,
                              position: "relative",
                            }}
                            onClick={(e) => handleFilter(e)}
                            value={quickReply.value}
                            style={{ width: "45%", marginRight: "25px" }}
                            sx={{ width: "45%", marginRight: "25px" }}
                            onChange={(e) => updateQuickRepliesValue(e, index)}
                            error={
                              errors.quickRepliesValue &&
                              errorIndex.quickRepliesValue &&
                              errorIndex.quickRepliesValue.includes(index)
                            }
                            helperText={
                              errors.quickRepliesValue &&
                              errorIndex.quickRepliesValue &&
                              errorIndex.quickRepliesValue.includes(index)
                                ? errors.quickRepliesValue
                                : ""
                            }
                            InputProps={{
                              inputProps: {
                                autoComplete: "off",
                                list: "suggestions",
                              },
                            }}
                          />
                        </Tooltip>
                        {filteredIntents.length >= 0 && (
                          <datalist id="suggestions">
                            {filteredIntents.map((intent) => (
                              <option key={intent._id} value={intent.name}>
                                {intent.phrases && intent.phrases.length > 1
                                  ? truncatedPhrases(intent)
                                  : intent.phrases[0] + "."}
                              </option>
                            ))}
                          </datalist>
                        )}
                      </ListItem>
                      <ListItem
                        sx={{ display: "flex", justifyContent: "center" }}>
                        <Tooltip
                          title={
                            !props.userPermissions
                              ? t("flowgraph:noPermissionTooltip")
                              : ""
                          }>
                          <TextField
                            id="quickreply-description"
                            label={t("flowgraph:description")}
                            variant="standard"
                            className="transition-message"
                            disabled={!props.userPermissions}
                            InputLabelProps={{
                              shrink: true,
                              position: "relative",
                            }}
                            value={quickReply.description || ""}
                            sx={{ width: "75%" }}
                            onChange={(e) =>
                              updateQuickRepliesDescription(e, index)
                            }
                            error={
                              errors.quickRepliesDescriptionLong &&
                              errorIndex.quickRepliesDescriptionLong &&
                              errorIndex.quickRepliesDescriptionLong.includes(
                                index,
                              )
                                ? true
                                : false
                            }
                            helperText={
                              errors.quickRepliesDescriptionLong &&
                              errorIndex.quickRepliesDescriptionLong &&
                              errorIndex.quickRepliesDescriptionLong.includes(
                                index,
                              )
                                ? errors.quickRepliesDescriptionLong
                                : ""
                            }
                          />
                        </Tooltip>
                      </ListItem>
                    </Card>
                  </Draggable>
                ))}
            </Container>
          </List>
        </div>
      </FormControl>
    </div>
  );
}
