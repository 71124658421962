import React from "react";

export const Path290Icon = () => (
  <svg
    width="5"
    height="23"
    viewBox="0 0 5 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      id="path290"
      d="M0.12207 6.16945H4.56908V22.3606H0.12207V6.16945ZM4.56908 3.66401H0.12207V-5.72205e-05H4.56908V3.66401Z"
      fill="#2D2D2D"
    />
  </svg>
);

export const DotIcon = () => (
  <svg
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="dot">
      <g id="g298">
        <g id="g304">
          <g id="g306">
            <path
              id="path314"
              d="M0.758301 5.3606H5.42297V0.785022H0.758301V5.3606Z"
              fill="url(#paint0_linear_2430_754)"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2430_754"
        x1="2.76039"
        y1="0.896622"
        x2="5.31137"
        y2="3.0935"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#2C2F88" />
        <stop offset="1" stopColor="#7F1D98" />
      </linearGradient>
    </defs>
  </svg>
);

export const G274Icon = () => (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="g274">
      <path
        id="path276"
        d="M4.91678 18.2268H9.30116C12.1511 18.2268 14.8758 16.473 14.8758 11.5247C14.8758 7.01504 13.3099 4.13378 8.42425 4.13378H4.91678V18.2268ZM0 -5.53131e-05H9.64566C15.4395 -5.53131e-05 19.7926 3.63269 19.7926 11.0549C19.7926 17.5378 16.473 22.3606 9.64566 22.3606H0V-5.53131e-05Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export const G278Icon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="g278">
      <path
        id="path280"
        d="M11.5658 8.99882C10.8141 9.6565 9.24821 9.68782 7.87021 9.93836C6.49237 10.2202 5.23965 10.69 5.23965 12.3185C5.23965 13.9784 6.52369 14.3854 7.96417 14.3854C11.4405 14.3854 11.5658 11.6295 11.5658 10.6588V8.99882ZM1.29341 5.71058C1.54412 1.54527 5.27097 0.292547 8.90371 0.292547C12.1295 0.292547 16.0128 1.01286 16.0128 4.89631V13.3207C16.0128 14.7927 16.1694 16.2645 16.5765 16.9223H12.0668C11.9102 16.4211 11.7848 15.8888 11.7537 15.3564C10.3443 16.8283 8.27735 17.3606 6.30431 17.3606C3.23529 17.3606 0.79248 15.8262 0.79248 12.5064C0.79248 8.84223 3.54847 7.96532 6.30431 7.58966C9.02898 7.18237 11.5658 7.27648 11.5658 5.45988C11.5658 3.54963 10.2504 3.26777 8.68464 3.26777C6.99346 3.26777 5.89733 3.95677 5.74074 5.71058H1.29341Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export const G282Icon = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="g282">
      <path
        id="path284"
        d="M0.57666 1.16945H4.8046V4.17599H4.86708C5.68151 2.14032 7.87378 0.730999 10.0346 0.730999C10.3477 0.730999 10.7236 0.793636 11.0056 0.88759V5.02158C10.5983 4.92762 9.94077 4.86499 9.40821 4.86499C6.15128 4.86499 5.02367 7.21385 5.02367 10.0636V17.3606H0.57666V1.16945Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export const G286Icon = () => (
  <svg
    width="26"
    height="17"
    viewBox="0 0 26 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="g286">
      <path
        id="path288"
        d="M20.0172 16.3606H15.4449L12.5636 5.49337H12.501L9.74499 16.3606H5.14138L0.00537109 0.169453H4.70293L7.67815 11.1619H7.74078L10.4341 0.169453H14.7559L17.5119 11.1306H17.5744L20.5496 0.169453H25.122L20.0172 16.3606Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export const G292Icon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="g292">
      <path
        id="path294"
        d="M0.569336 1.16945H4.79728V3.4242H4.89123C6.01868 1.60791 7.96025 0.730999 9.83934 0.730999C14.5682 0.730999 15.7585 3.39304 15.7585 7.40175V17.3606H11.3113V8.21587C11.3113 5.55399 10.5283 4.23863 8.46134 4.23863C6.04985 4.23863 5.01635 5.58515 5.01635 8.87355V17.3606H0.569336V1.16945Z"
        fill="#2D2D2D"
      />
    </g>
  </svg>
);

export const EmailIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="mail">
      <path
        id="Vector"
        d="M29.3332 8.00004C29.3332 6.53337 28.1332 5.33337 26.6665 5.33337H5.33317C3.8665 5.33337 2.6665 6.53337 2.6665 8.00004V24C2.6665 25.4667 3.8665 26.6667 5.33317 26.6667H26.6665C28.1332 26.6667 29.3332 25.4667 29.3332 24V8.00004ZM26.6665 8.00004L15.9998 14.6534L5.33317 8.00004H26.6665ZM26.6665 24H5.33317V10.6667L15.9998 17.3334L26.6665 10.6667V24Z"
        fill="#282938"
      />
    </g>
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="atom/icon-copy">
      <path
        id="Vector"
        d="M6 12C5.63333 12 5.31944 11.8695 5.05833 11.6084C4.79722 11.3473 4.66667 11.0334 4.66667 10.6667V2.66671C4.66667 2.30004 4.79722 1.98615 5.05833 1.72504C5.31944 1.46393 5.63333 1.33337 6 1.33337H12C12.3667 1.33337 12.6806 1.46393 12.9417 1.72504C13.2028 1.98615 13.3333 2.30004 13.3333 2.66671V10.6667C13.3333 11.0334 13.2028 11.3473 12.9417 11.6084C12.6806 11.8695 12.3667 12 12 12H6ZM6 10.6667H12V2.66671H6V10.6667ZM3.33333 14.6667C2.96667 14.6667 2.65278 14.5362 2.39167 14.275C2.13056 14.0139 2 13.7 2 13.3334V4.00004H3.33333V13.3334H10.6667V14.6667H3.33333Z"
        fill="#F1F1F1"
      />
    </g>
  </svg>
);
