import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import StepOne from "./NewStepOne.component";
import StepTwo from "./NewStepTwo.component";
import { Dialog, DialogActions, IconButton, Box } from "@mui/material";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip, Typography } from "@mui/material";
import Transition from "../../shared/helper/transitionDialog";
import {
  permissionsDefaultRoot,
  permissionsDefaultUser,
  permissionsDefaultApi,
} from "../../shared/constants/permissionsDefault";

const NewModal = ({ users, clientList, open, onClose, onSave }) => {
  const { t } = useTranslation("users");
  const [activeStep, setActiveStep] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [clients, setClients] = useState([]);
  const [type, setType] = useState("");
  const [valid, setValid] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const onchangeStepOne = useCallback(
    (values) => {
      if (values) {
        //permissions are a object const, so we need to clone it
        const permissionsRoot = JSON.parse(
          JSON.stringify(permissionsDefaultRoot),
        );
        const permissionsUser = JSON.parse(
          JSON.stringify(permissionsDefaultUser),
        );

        const permissionsApi = JSON.parse(
          JSON.stringify(permissionsDefaultApi),
        );

        setUsername(values.username);
        setPassword(values.password);
        setEmail(values.email);
        setType(values.type);
        setClients(values.clients);
        values.type === "Root"
          ? setPermissions(permissionsRoot)
          : values.type === "User"
            ? setPermissions(permissionsUser)
            : setPermissions(permissionsApi);
      }
    },
    [setUsername, setPassword, setEmail, setType, setClients],
  );

  const onChangeStepTwo = useCallback((values) => {
    if (values) {
      setPermissions(values);
    }
  }, []);

  const handleClose = useCallback(() => {
    setUsername("");
    setPassword("");
    setEmail("");
    setType("");
    setClients([]);
    setPermissions([]);
    setActiveStep(0);
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave({
      username,
      password,
      email,
      clients,
      type,
      permissions,
    });
    handleClose();
  }, [
    username,
    password,
    email,
    clients,
    type,
    permissions,
    onSave,
    handleClose,
  ]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm">
        <div>
          {activeStep === 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 2,
              }}>
              <Typography variant="h6" component="div">
                {t("StepOneTitle")}
              </Typography>
            </Box>
          )}
          {activeStep === 1 && (
            <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
              <Tooltip title="Back">
                <IconButton
                  onClick={() => setActiveStep(0)}
                  sx={{
                    marginRight: "30%",
                  }}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" component="div">
                {t("StepTwoTitle")}
              </Typography>
            </Box>
          )}
        </div>
        {activeStep === 0 && (
          <StepOne
            users={users}
            clientList={clientList}
            onChange={onchangeStepOne}
            setValid={setValid}
            data={{
              username,
              password,
              email,
              clients,
              type,
            }}
          />
        )}
        {activeStep === 1 && (
          <StepTwo
            type={type}
            onChange={onChangeStepTwo}
            setPermissions={setPermissions}
            permissions={permissions}
          />
        )}
        <DialogActions>
          <Button id="modal-cancel-button" onClick={handleClose}>
            {t("modalCancel")}
          </Button>
          {activeStep === 0 && (
            <Button
              id="modal-next-button"
              variant="contained"
              color="primary"
              disabled={!valid}
              onClick={() =>
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
              }>
              {t("nextButton")}
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              id="modal-save-button"
              variant="contained"
              color="primary"
              onClick={handleSave}>
              {t("modalSave")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NewModal;
