import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  Autocomplete,
  Grid,
} from "@mui/material";
import ChipInput from "../../shared/components/ChipInput";

const NewStepTwo = ({
  intent,
  onClose,
  allTags,
  setNewIntent,
  setStep,
  dirtyExcludeAlternatives,
  agentPages,
}) => {
  const [oneWord, setOneWord] = useState(false);
  const [excludeAlternatives, setExcludeAlternatives] = useState(false);
  const [excludeRankings, setExcludeRankings] = useState(false);
  const [destinationPage, setDestinationPage] = useState(undefined);
  const [suggestion_message, setSuggestion_message] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [responseAlt, setResponseAlt] = useState([]);

  const { t } = useTranslation("intents");

  useEffect(() => {
    // Checks if exclude alternatives should be true or false only the first time
    if (
      (dirtyExcludeAlternatives !== undefined
        ? dirtyExcludeAlternatives
        : false) ||
      intent?.response?.text?.length === 0
    ) {
      setExcludeAlternatives(
        dirtyExcludeAlternatives !== undefined
          ? dirtyExcludeAlternatives
          : true,
      );
    }
  }, []);

  useEffect(() => {
    if (intent && Object.keys(intent).length > 3) {
      if (intent.oneWord) {
        setOneWord(intent.oneWord);
      }
      if (intent.excludeAlternatives) {
        setExcludeAlternatives(intent.excludeAlternatives);
      }
      if (intent.excludeRankings) {
        setExcludeRankings(intent.excludeRankings);
      }
      if (intent.destinationPage) {
        setDestinationPage(intent.destinationPage);
      }
      if (intent.suggestion_message) {
        setSuggestion_message(intent.suggestion_message);
      }
      if (intent.tags) {
        setTags(intent.tags);
      }
      if (intent.response.responseAlt) {
        setResponseAlt(intent.response.responseAlt);
      }
    }
  }, [intent]);

  const handleDestinationPage = (e) => {
    const selectedpage = agentPages.find((page) => page.data.label === e);
    setDestinationPage(selectedpage ? selectedpage.id : "");
  };

  const nextStep = useCallback(() => {
    let newIntent = intent;
    newIntent.oneWord = oneWord;
    newIntent.excludeAlternatives = excludeAlternatives;
    newIntent.excludeRankings = excludeRankings;
    newIntent.destinationPage = destinationPage;
    newIntent.suggestion_message = suggestion_message;
    if (responseAlt.length > 0) {
      newIntent.response.responseAlt = responseAlt;
    }
    if (tags.length > 0) {
      newIntent.tags = tags;
    }
    if (newIntent.tags && tags.length === 0) {
      delete newIntent.tags;
    }
    setNewIntent(newIntent);
    setStep(2);
  }, [
    oneWord,
    excludeAlternatives,
    excludeRankings,
    destinationPage,
    responseAlt,
    suggestion_message,
    tags,
  ]);

  return (
    <div>
      <Box p={2} sx={{ width: 600 }}>
        <Autocomplete
          label={t("destinationPage")}
          id="new_step_two_destination_page_textfield"
          variant="standard"
          fullWidth
          value={
            destinationPage
              ? agentPages.find((page) => page.id === destinationPage)?.data
                ?.label
              : ""
          }
          options={agentPages.map((page) => page.data.label)}
          onChange={(e, value) => handleDestinationPage(value)}
          sx={{
            width: "80%",
            marginBottom: 2,
            marginLeft: "10%",
          }}
          renderInput={(params) => (
            <Box display="flex" flexDirection="row">
              <TextField
                {...params}
                label={t("destinationPage")}
                variant="standard"
                className="transition-message"
                fullWidth></TextField>
            </Box>
          )}></Autocomplete>
        <ChipInput
          name="msgs-alt"
          source="alt"
          label={t("alternativeMessage")}
          fullWidth
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={responseAlt}
          updateProps={(e) => {
            setResponseAlt(e);
          }}
        />
        <TextField
          label={t("suggestionMessage")}
          variant="standard"
          fullWidth
          value={suggestion_message}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          onChange={(e) => setSuggestion_message(e.target.value)}
          inputProps={{ maxLength: 20 }}
        />
        <Grid container columns={3} sx={{ marginLeft: "10%", width: "90%" }}>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("oneWord")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              checked={oneWord}
              onClick={() => {
                setOneWord(!oneWord);
              }}></Checkbox>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">
              {t("excludeAlternatives")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              checked={excludeAlternatives}
              id="new_step_two_exclude_alternatives_checkbox"
              onClick={() => {
                setExcludeAlternatives(!excludeAlternatives);
              }}></Checkbox>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle1">{t("excludeIntents")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Checkbox
              checked={excludeRankings}
              onClick={() => {
                setExcludeRankings(!excludeRankings);
              }}></Checkbox>
          </Grid>
        </Grid>
        <Autocomplete
          multiple
          options={allTags}
          getOptionLabel={(option) => option.name}
          value={allTags ? allTags.filter((tag) => tags.includes(tag._id)) : []}
          onChange={(e, value) => {
            setTags(value.map((tag) => tag._id));
          }}
          renderInput={(params) => (
            <Box display="flex" flexDirection="row">
              <TextField
                {...params}
                label="Tags"
                variant="standard"
                className="transition-message"
                sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
                fullWidth></TextField>
            </Box>
          )}
        />
      </Box>
      <Box
        p={2}
        sx={{ minWidth: 400, display: "flex", justifyContent: "flex-end" }}>
        <Button id="modal2-cancel-button" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          id="modal2-next-button"
          variant="contained"
          color="primary"
          onClick={() => nextStep()}>
          {t("next")}
        </Button>
      </Box>
    </div>
  );
};

export default NewStepTwo;
