import React, { Suspense } from "react";
import "./App.css";
import Router from "./shared/components/Router";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as ThemeProviderLegacy } from "@mui/material/styles";

import { useSelector } from "react-redux";

import { darkTheme, lightTheme } from "./shared/theming/theme";
import { I18nextProvider } from "react-i18next";
import i18next from "./Translations/i18n";

const App = () => {
  const theme = useSelector((state) => state.theme);

  return (
    <Suspense fallback={<div></div>}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme.darkTheme ? lightTheme : darkTheme}>
          <ThemeProviderLegacy theme={theme.darkTheme ? lightTheme : darkTheme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={4}>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProviderLegacy>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default App;
