import React, { Fragment, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import {
  validateCharacters,
  validateEmpty,
} from "../../../shared/helper/validations";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

export default function EdgeEntity({ ...props }) {
  const [entities, setEntities] = React.useState([]);
  const [nameDirty, setNameDirty] = React.useState(false);
  const { t } = useTranslation("edge");

  useEffect(() => {
    // Por si a futuro son múltiples entities
    if (props.entity) {
      setEntities([props.entity]);
      setNameDirty(true);
    } else {
      setEntities([{}]);
    }
  }, []);

  useEffect(() => {
    validate();
  }, [entities]);

  async function updateName(e, index) {
    let current = [...entities];
    current[index].name = e.target.value;
    setEntities(current);
    setNameDirty(true);
    validate();
  }

  async function updateValue(e, index) {
    let current = [...entities];
    current[index].value = e.target.value;
    setEntities(current);
    validate();
  }

  function updateNotFoundMessage(e, index) {
    let current = [...entities];
    current[index].notFoundMessage = e.target.value;
    setEntities(current);
    validate();
  }

  async function updateNotFoundTransition(e, index) {
    let current = [...entities];
    current[index].notFoundTransition =
      e.target.value !== "" ? e.target.value : null;
    setEntities(current);
    validate();
  }

  function validate() {
    let valid = true;
    if (entities && entities.length > 0) {
      for (let i = 0, len = entities.length; i < len; i++) {
        if (!validateCharacters(entities[i].name)) {
          valid = false;
        }

        if (
          !validateEmpty(entities[i].name) ||
          !validateEmpty(entities[i].value)
        ) {
          valid = false;
        }
      }
    } else valid = false;
    props.entityUpdate(entities[0], valid);
  }

  return (
    <Fragment>
      <div>
        <h3 className="entity-title">
          {t("entities")}
        </h3>

        {entities.map((item, index) => (
          <div key={index} className="entity">
            <Tooltip
              title={props.userPermissions ? "" : t("noPermissionTooltip")}
              placement="left">
              <TextField
                className="entity-name"
                label={t("entitieName")}
                variant="standard"
                multiline
                required
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.name}
                onChange={(e) => updateName(e, index)}
                error={
                  item.name !== ""
                    ? item.name
                      ? nameDirty && !validateCharacters(item.name)
                      : false
                    : true
                }
                helperText={
                  item.name !== ""
                    ? item.name
                      ? nameDirty && !validateCharacters(item.name)
                        ? t("validateNameInvalid")
                        : ""
                      : ""
                    : t("validateNameEmpty")
                }
              />
            </Tooltip>
            <Tooltip
              title={props.userPermissions ? "" : t("noPermissionTooltip")}
              placement="left">
              <TextField
                className="entity-regex"
                label={t("entitieRegex")}
                variant="standard"
                required
                disabled={!props.userPermissions}
                multiline
                fullWidth
                defaultValue={item.value}
                onChange={(e) => updateValue(e, index)}
                error={item.value !== "" ? false : true}
                helperText={item.value !== "" ? "" : t("validateRegex")}
              />
            </Tooltip>
            <Tooltip
              title={props.userPermissions ? "" : t("noPermissionTooltip")}
              placement="left">
              <TextField
                className="transition-message"
                label={t("entitieNotFoundMessage")}
                variant="standard"
                multiline
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.notFoundMessage}
                onChange={(e) => updateNotFoundMessage(e, index)}
              />
            </Tooltip>
            <FormControl fullWidth>
              <InputLabel>{t("entitieNotFoundPage")}</InputLabel>
              <Tooltip
                title={props.userPermissions ? "" : t("noPermissionTooltip")}
                placement="left">
                <Select
                  variant="standard"
                  disabled={!props.userPermissions}
                  defaultValue={
                    item.notFoundTransition
                      ? item.notFoundTransition._id
                        ? item.notFoundTransition._id
                        : item.notFoundTransition
                      : ""
                  }
                  onChange={(e) => updateNotFoundTransition(e, index)}>
                  <MenuItem value="">
                    <i>None</i>
                  </MenuItem>
                  {props.pages.map((page) => (
                    <MenuItem key={page} value={page.data.oid}>{page.data.label}</MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
