import React, { useState, useEffect, useCallback } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import AssignmentLateRoundedIcon from "@mui/icons-material/AssignmentLateRounded";

import "../Flowgraph.css";
import {
  validateCharacters,
  validateEmpty,
} from "../../shared/helper/validations";
import InputLabel from "@mui/material/InputLabel";
import ChipInput from "../../shared/components/ChipInput";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormHelperText, Typography } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";

import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";

import QuickreplyIcon from "@mui/icons-material/Quickreply";
import MessageIcon from "@mui/icons-material/Message";
import RuleIcon from "@mui/icons-material/Rule";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";

import TransformIcon from "@mui/icons-material/Transform";

import Switch from "@mui/material/Switch";

import { orderAlphabeticallyWithAttribute } from "../../shared/helper/orderAlphabetically";
import QuickReplies from "./NodeComponents/Quick.replies";
import Transfers from "./NodeComponents/node.transfers.component";
import { Ai } from "./NodeComponents/node.AI";
import AltMessage from "./NodeComponents/node.AltMessage";

import { useTranslation } from "react-i18next";

const NodeModal = ({
  onClose,
  open,
  onDelete,
  onSave,
  pagesLean,
  editNodeProps,
  endpoints,
  agent,
  intents,
  previous,
  featureProps,
  nodes,
  userPermissions,
}) => {
  const [feature, setFeature] = useState({});
  const [hasEndpoint, setHasEndpoint] = useState(false);
  const [repliesFromArray, setRepliesFromArray] = useState(false);
  const [endpointSelected, setEndpointSelected] = useState();
  const [endpoint, setEndpoint] = useState();
  const [errors, setErrors] = useState({});
  const [editNode, setEditNode] = useState({});
  const [errorIndex, setErrorIndex] = useState({});
  const [editableNode, setEditableNode] = useState(true);
  const [valid, setValid] = useState(true);
  const { t } = useTranslation("intents");

  useEffect(() => {
    validate();
  }, [editNode, hasEndpoint, endpoint, endpointSelected]);

  useEffect(() => {
    //
    if (open) {
      setEditNode({ ...editNodeProps });
      editNode.data = editNodeProps.data;
      if (editNodeProps?.data?.quickRepliesArray?.length > 0) {
        setRepliesFromArray(true);
      } else {
        setRepliesFromArray(false);
      }
      if (editNode?.data?.endpoint) {
        const tempEndpoint = endpoints.find(
          (e) => e._id === editNode.data.endpoint.host,
        );
        setEndpointSelected(tempEndpoint);
        setEndpoint(editNode?.data?.endpoint);
      } else {
        setEndpoint(undefined);
        setEndpointSelected(undefined);
      }
      setFeature(featureProps);
      setEditableNode(true);
    }
    if (
      editNodeProps?.node_type === "input" ||
      editNodeProps?.data?.label === "output" ||
      editNodeProps?.data.label === "End Session" ||
      editNodeProps?.data.label === "Start"
    ) {
      setEditableNode(false);
    }
  }, [open]);

  const validate = useCallback(() => {
    let newErrors = {};
    let newErrorIndex = {};

    if (editNode) {
      if (validateEmpty(editNode.data?.label)) {
        if (!validateCharacters(editNode.data?.label)) {
          newErrors.name = t("flowgraph:validateNameCharacters");
        }
      } else {
        newErrors.name = t("flowgraph:validateNameEmpty");
      }

      if (validateDuplicateName(editNode.data?.label)) {
        newErrors.name = t("flowgraph:validateNameAlreadyExist");
      }

      if (endpoint?.host) {
        const related = endpointSelected?.routes?.some(
          (e) => e._id === editNode.data?.endpoint?.route,
        );
        if (!editNode.data?.endpoint?.route) {
          newErrors.endpointRoute = t("flowgraph:validateRoute");
        } else if (!related) {
          newErrors.endpointRoute = t("flowgraph:validateRelated");
        }
      }

      if (editNode.data?.responseImageAdapter) {
        if (!editNode.data?.responseImageType) {
          newErrors.responseImageType = t("flowgraph:validateImageType");
        }
      }

      if (editNode.data?.quickReplies) {
        newErrorIndex.quickRepliesValue = [];
        newErrorIndex.quickRepliesLabel = [];
        newErrorIndex.quickRepliesLabelLong = [];
        if (editNode.data?.quickReplies.length > 0) {
          for (let i = 0; i < editNode.data.quickReplies.length; i++) {
            if (!editNode.data.quickReplies[i].label) {
              newErrors.quickRepliesLabel = t("flowgraph:errorTitleIsRequired");
              newErrorIndex.quickRepliesLabel.push(i);
            }
            if (!editNode.data.quickReplies[i].value) {
              newErrors.quickRepliesValue = t(
                "flowgraph:errorPlayloadIsRequired",
              );
              newErrorIndex.quickRepliesValue.push(i);
            }
            if (editNode.data.quickReplies[i].label.length > 20) {
              newErrors.quickRepliesLabelLong = t(
                "flowgraph:errorTitleTooLarge",
              );
              newErrorIndex.quickRepliesLabelLong.push(i);
            }
            // find duplicate quick replies
            for (let j = 0; j < editNode.data.quickReplies.length; j++) {
              if (
                i !== j &&
                editNode.data.quickReplies[i].value ===
                  editNode.data.quickReplies[j].value
              ) {
                t("flowgraph:errorPlayloadDuplicated");
                newErrorIndex.quickRepliesValue.push(i);
              }
              if (
                i !== j &&
                editNode.data.quickReplies[i].label ===
                  editNode.data.quickReplies[j].label
              ) {
                newErrors.quickRepliesLabel = t(
                  "flowgraph:errorTitleDuplicated",
                );
                newErrorIndex.quickRepliesLabel.push(i);
              }
            }
          }
        }
      }

      //validate response message is too large if quick replies are present
      if (
        editNode.data?.responseMessage &&
        editNode.data.responseMessage.length > 0 &&
        editNode.data.quickReplies &&
        editNode.data.quickReplies.length > 0
      ) {
        editNode.data.responseMessage.forEach((message) => {
          if (message.length > 1024) {
            newErrors.responseMessage = t("flowgraph:errorButtonAndAnswer");
          }
        });
      }

      setErrors(newErrors);
      setErrorIndex(newErrorIndex);
    }
  }, [editNode, t]);

  const updateNode = async (e) => {
    let edit = { ...editNode };

    if (edit.data) {
      edit.data.label = e.target.value;
      setEditNode(edit);
    }
  };

  const updateMessage = async (e) => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: { ...editNode.data, responseMessage: e },
      };
      setEditNode(updatedNode);
    }
  };

  const updateNoMatchMessage = async (e) => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: { ...editNode.data, default: e.target.value },
      };
      setEditNode(updatedNode);
    }
  };

  const updateResponseImageAdapter = async (e) => {
    if (editNode.data) {
      let updatedNode = { ...editNode };
      updatedNode.data.responseImageAdapter = e.target.value;
      setEditNode(updatedNode);
    }
  };

  const updateResponseImageType = async (e) => {
    if (editNode.data) {
      let updatedNode = { ...editNode };
      updatedNode.data.responseImageType = e.target.value;
      setEditNode(updatedNode);
    }
  };

  const updateSqaOff = async () => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: { ...editNode.data, sqaOff: !(editNode.data?.sqaOff === true) },
      };
      setEditNode(updatedNode);
    }
  };

  const updateIgnoreNoMatch = async () => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: {
          ...editNode.data,
          ignoreNoMatch: !(editNode.data?.ignoreNoMatch === true),
        },
      };
      setEditNode(updatedNode);
    }
  };

  const updateResolutionNode = async () => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: {
          ...editNode.data,
          resolutionNode: !(editNode.data?.resolutionNode === true),
        },
      };
      setEditNode(updatedNode);
    }
  };

  const updateSensitiveData = async (e) => {
    if (editNode.data) {
      const updatedNode = {
        ...editNode,
        data: { ...editNode.data, sensitiveData: e },
      };
      setEditNode(updatedNode);
    }
  };

  const updateEndpointHost = (event) => {
    if (editNode.data) {
      const hostSelected = endpoints?.find((e) => e._id === event.target.value);
      const tempEndpoint = {};
      const updateNode = {
        ...editNode,
        data: { ...editNode.data, endpoint: tempEndpoint },
      };
      if (hostSelected) {
        setEndpointSelected(hostSelected);
        tempEndpoint.host = hostSelected._id;
        updateNode.data.endpoint.host = hostSelected._id;
      } else {
        setEndpointSelected(null);
        delete updateNode.data.endpoint;
      }
      setEndpoint(tempEndpoint);
      setEditNode(updateNode);
    }
  };

  const updateEndpointRoute = async (event) => {
    if (editNode.data) {
      const routeSelected = endpointSelected?.routes?.find(
        (e) => e._id === event.target.value,
      );
      const tempEndpoint = { ...endpoint };
      if (routeSelected) {
        tempEndpoint.route = routeSelected._id;
      } else {
        delete tempEndpoint.route;
      }
      const updateNode = {
        ...editNode,
        data: { ...editNode.data, endpoint: tempEndpoint },
      };
      setEndpoint(tempEndpoint);
      setEditNode(updateNode);
    }
  };

  const handleSave = () => {
    if (editNode && editNode.data) {
      editNode.is_modified = true;
      if (hasEndpoint) {
        let endpointCopy = { ...endpoint };
        endpointCopy.name = editNode.data?.label;
        onSave(editNode, endpointCopy);
      } else {
        onSave(editNode);
      }
    }
  };

  const handleClose = () => {
    let editNode = { ...editNodeProps };
    previous.nodes?.forEach((element) => {
      if (element.id === editNode?.id) {
        editNode.data = element.data;
      }
    });

    // Chequea en los nodos nuevos, si existe un quick reply vacío y lo elimina
    editNode.is_new &&
    editNode.data.quickReplies?.length === 1 &&
    editNode.data.quickReplies[0].value === null &&
    editNode.data.quickReplies[0].label === null
      ? (editNode.data.quickReplies = [])
      : null;

    setHasEndpoint(false);
    setEditNode(editNode);
    onClose(false);
  };

  const handleDelete = () => {
    onDelete(editNode.id);

    setEditNode({});
    setHasEndpoint(false);
  };

  const updateTransferAgent = async (value) => {
    let edit = { ...editNode };

    if (edit.data) {
      if (value === "" || value === undefined) {
        edit.data.transferToAgent = {};
      } else {
        edit.data.transferToAgent = {
          agent: value,
        };
      }

      // Construct transfer agent page select
      let tempAgentsPagesArray = JSON.parse(JSON.stringify(pagesLean));
      tempAgentsPagesArray = tempAgentsPagesArray.filter(
        (page) => page.agent === value,
      );
      tempAgentsPagesArray.unshift({ name: "", display_name: "None" });

      tempAgentsPagesArray = orderAlphabeticallyWithAttribute(
        tempAgentsPagesArray,
        "name",
      );
      edit.data.transfer_agent_pages = [].concat(tempAgentsPagesArray);

      setEditNode(edit);
    }
  };

  const updateTransferAgentPage = async (value) => {
    let edit = { ...editNode };

    if (edit.data) {
      edit.data.transferToAgent.agent_page_id = value;
      setEditNode(edit);
    }
  };

  const validateDuplicateName = (name) => {
    for (let i = 0; i < nodes?.length; i++) {
      if (nodes[i].id !== editNode.id) {
        if (
          name &&
          nodes[i].data.label
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
            name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const updateResponseImagePosition = (e) => {
    if (editNode.data) {
      let edit = { ...editNode };
      edit.data.responseImagePosition = e.target.checked;
      setEditNode(edit);
    }
  };

  const updateEditNode = (editNode, newErrors, errorIndex, valid) => {
    setEditNode(editNode);
    if (newErrors.length === undefined && valid) {
      setErrors({ ...newErrors });
    } else {
      setErrors({ ...errors, ...newErrors });
    }
    setErrorIndex(errorIndex);
  };

  const updateEditResponse = (responseAlt) => {
    if (editNode.data) {
      let edit = { ...editNode };
      edit.data.responseAlt = responseAlt;
      setEditNode(edit);
    }
  };

  const updateErrors = async (newErrors, errorIndex, valid) => {
    //validate response message is too large if quick replies are present
    if (
      editNode.data?.responseMessage &&
      editNode.data.responseMessage.length > 0 &&
      editNode.data.quickReplies &&
      editNode.data.quickReplies.length > 0
    ) {
      editNode.data.responseMessage.forEach((message) => {
        if (message.length > 1024) {
          newErrors.responseMessage = t(
            "flowgraph:errorResponseMessageTooLarge",
          );
        }
      });
    }
    if (newErrors.length === undefined && valid) {
      setErrors({ ...newErrors });
    } else {
      setErrors({ ...errors, ...newErrors });
    }
    setErrorIndex(errorIndex);
    setValid(valid);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t("flowgraph:pageEdit")}</DialogTitle>
        <DialogContent>
          <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <TextField
              label={t("flowgraph:name")}
              variant="standard"
              fullWidth
              disabled={!userPermissions || !editableNode}
              sx={{ marginTop: "15px", marginBottom: "25px" }}
              defaultValue={editNode?.data?.label}
              value={editNode?.data?.label}
              onChange={updateNode}
              error={errors.name}
              helperText={errors.name}
            />
          </Tooltip>
          <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <ChipInput
              variant="standard"
              label={t("flowgraph:message")}
              multiline
              disabled={!userPermissions}
              fullWidth
              sx={{ marginTop: "15px", marginBottom: "25px" }}
              value={editNode?.data?.responseMessage}
              updateProps={updateMessage}
              error={errors.responseMessage}
            />{" "}
          </Tooltip>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t("flowgraph:alternativeMessages")}
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <AltMessage
                    disabled={!userPermissions}
                    data={editNode?.data ? editNode.data : {}}
                    updateAMessage={updateEditResponse}></AltMessage>
                </span>
              </Tooltip>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: "3%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              id="endpoint-header">
              <Typography
                sx={{
                  display: "flex",
                  width: "100%",
                }}>
                <Tooltip title="" sx={{ verticalAlign: "middle" }}>
                  <MessageIcon
                    style={{
                      display: "inline-block",
                      marginBottom: "1%",
                      marginRight: "3%",
                    }}
                  />
                </Tooltip>
                {t("flowgraph:otherResponseTypes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <TextField
                    id="no-match-message"
                    label={t("flowgraph:noMatchMessage")}
                    variant="standard"
                    className="transition-message"
                    disabled={!userPermissions}
                    multiline
                    fullWidth
                    value={
                      editNode?.data?.default ? editNode?.data?.default : ""
                    }
                    onChange={updateNoMatchMessage}
                  />
                </span>
              </Tooltip>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <FormControlLabel
                  label={t("flowgraph:repeatQROnNoMatch")}
                  style={{ paddingLeft: "1%" }}
                  control={
                    <Tooltip
                      title={!userPermissions ? t("noPermissionTooltip") : ""}>
                      <span>
                        <Switch
                          disabled={!userPermissions}
                          onChange={(e) =>
                            setEditNode({
                              ...editNode,
                              data: {
                                ...editNode.data,
                                repeatQROnNoMatch: e.target.checked,
                              },
                            })
                          }
                          id="repeat-quick-replies-on-no-match"
                          checked={editNode?.data?.repeatQROnNoMatch}
                        />
                      </span>
                    </Tooltip>
                  }
                />
              </Tooltip>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <TextField
                    id="response-image-adapter"
                    label={t("flowgraph:imageAdapter")}
                    variant="standard"
                    className="response-image-adapter"
                    disabled={!userPermissions}
                    fullWidth
                    value={
                      editNode?.data?.responseImageAdapter
                        ? editNode?.data?.responseImageAdapter
                        : ""
                    }
                    onChange={(e) => updateResponseImageAdapter(e)}
                    style={{ marginBottom: "10px" }}
                  />
                </span>
              </Tooltip>
              <br></br>
              <Tooltip
                title={t("flowgraph:tooltipGIFS")}
                style={{ verticalAlign: "middle", marginTop: "4%" }}>
                <InfoIcon />
              </Tooltip>
              <FormControl
                error={errors.responseImageType}
                helperText={
                  errors.responseImageType ? errors.responseImageType : ""
                }
                style={{ width: "93%", marginTop: "2%", marginLeft: "2%" }}>
                <InputLabel id="response-image-type-label">
                  {t("flowgraph:imageType")}
                </InputLabel>
                <Tooltip
                  title={!userPermissions ? t("noPermissionTooltip") : ""}>
                  <Select
                    label={t("flowgraph:imageType")}
                    variant="standard"
                    disabled={!userPermissions}
                    className="response-image-type"
                    value={
                      editNode?.data?.responseImageType
                        ? editNode?.data?.responseImageType
                        : ""
                    }
                    onChange={updateResponseImageType}
                    fullWidth>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="image/png">Image PNG</MenuItem>
                    <MenuItem value="image/jpeg">Image JPEG</MenuItem>
                    <MenuItem value="video/mp4">Video MP4</MenuItem>
                    <MenuItem value="application/pdf">Document PDF</MenuItem>
                  </Select>
                </Tooltip>
                {errors.responseImageType && (
                  <FormHelperText error>
                    {errors.responseImageType}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControlLabel
                label={t("flowgraph:beforeMessage")}
                style={{ paddingLeft: "1%", paddingTop: "10px" }}
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <span>
                      <Switch
                        disabled={!userPermissions}
                        onChange={updateResponseImagePosition}
                        className="response-image-position"
                        id="response-image-position"
                        checked={editNode?.data?.responseImagePosition}
                      />
                    </span>
                  </Tooltip>
                }
              />
            </AccordionDetails>
          </Accordion>
          {editableNode ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="endpoint-header">
                <Typography
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}>
                  <Tooltip title="" sx={{ verticalAlign: "middle" }}>
                    <TransformIcon
                      style={{
                        display: "inline-block",
                        marginBottom: "1%",
                        marginRight: "3%",
                      }}
                    />
                  </Tooltip>
                  {t("flowgraph:transfers")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip
                  title={!userPermissions ? t("noPermissionTooltip") : ""}>
                  <span>
                    <Transfers
                      editNode={editNode}
                      userPermissions={userPermissions}
                      pagesLean={pagesLean}
                      updateTransferAgent={updateTransferAgent}
                      updateTransferAgentPage={
                        updateTransferAgentPage
                      }></Transfers>
                  </span>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              id="endpoint-header">
              <Tooltip title="" sx={{ verticalAlign: "middle" }}>
                <RuleIcon
                  style={{
                    display: "inline-block",
                    marginBottom: "1%",
                    marginRight: "3%",
                  }}
                />
              </Tooltip>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                Tooltip
                {t("flowgraph:properties")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip
                title={t("flowgraph:disableSimpleQuestions")}
                sx={{ verticalAlign: "middle" }}>
                <CommentsDisabledIcon />
              </Tooltip>
              <FormControlLabel
                label={t("flowgraph:disableSQA")}
                labelPlacement="start"
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <span>
                      <Checkbox
                        disabled={!userPermissions}
                        label={t("flowgraph:disableSQA")}
                        checked={editNode?.data?.sqaOff ? true : false}
                        onChange={updateSqaOff}
                        color="primary"
                      />
                    </span>
                  </Tooltip>
                }
              />
              <br></br>
              <Tooltip
                title={t("flowgraph:ignoreNoMatch")}
                sx={{ verticalAlign: "middle" }}>
                <NotInterestedIcon />
              </Tooltip>
              <FormControlLabel
                label={t("flowgraph:ignoreNoMatch")}
                labelPlacement="start"
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <span>
                      <Checkbox
                        label={t("flowgraph:ignoreNoMatch")}
                        checked={editNode?.data?.ignoreNoMatch ? true : false}
                        onChange={updateIgnoreNoMatch}
                        disabled={!userPermissions}
                        color="primary"
                      />
                    </span>
                  </Tooltip>
                }
              />
              <br></br>
              <Tooltip
                title={t("flowgraph:resolutionNode")}
                sx={{ verticalAlign: "middle" }}>
                <AssignmentLateRoundedIcon />
              </Tooltip>
              <FormControlLabel
                label={t("flowgraph:resolutionNode")}
                labelPlacement="start"
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <span>
                      <Checkbox
                        label={t("flowgraph:nodeResolution")}
                        checked={editNode?.data?.resolutionNode ? true : false}
                        onChange={updateResolutionNode}
                        disabled={!userPermissions}
                        color="primary"
                      />
                    </span>
                  </Tooltip>
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <LockIcon
                style={{
                  display: "inline-block",
                  marginBottom: "1%",
                  marginRight: "3%",
                }}
              />
              {t("flowgraph:sensitiveData")}
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <ChipInput
                    name="sensitive-params"
                    source="params"
                    disabled={!userPermissions}
                    label={t("flowgraph:params")}
                    fullWidth
                    value={editNode?.data?.sensitiveData}
                    updateProps={(e) => updateSensitiveData(e)}
                  />
                </span>
              </Tooltip>
            </AccordionDetails>
          </Accordion>
          {editableNode ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="endpoint-header">
                <Typography
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}>
                  <Tooltip title="" sx={{ verticalAlign: "middle" }}>
                    <FilterDramaIcon
                      style={{
                        display: "inline-block",
                        marginBottom: "1%",
                        marginRight: "3%",
                      }}
                    />
                  </Tooltip>
                  {t("flowgraph:endpoint")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl style={{ width: "93%", marginLeft: "2%" }}>
                  <InputLabel id="select-label-host">
                    {t("flowgraph:host")}
                  </InputLabel>
                  <Tooltip
                    title={!userPermissions ? t("noPermissionMessage") : ""}
                    sx={{
                      display: "flex",
                      width: "100%",
                      "&:hover": { color: "#4e8cff" },
                    }}>
                    <Select
                      variant="standard"
                      fullWidth
                      value={endpoint?.host ?? ""}
                      onChange={updateEndpointHost}
                      sx={{ marginBottom: "2%" }}
                      disabled={!userPermissions}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {endpoints?.map((e) => (
                        <MenuItem key={e._id} value={e._id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
                <FormControl style={{ width: "93%", marginLeft: "2%" }}>
                  <InputLabel id="select-label-route">
                    {t("flowgraph:route")}
                  </InputLabel>
                  <Tooltip
                    title={!userPermissions ? t("noPermissionMessage") : ""}
                    sx={{
                      display: "flex",
                      width: "100%",
                      "&:hover": { color: "#4e8cff" },
                    }}>
                    <Select
                      variant="standard"
                      fullWidth
                      value={endpoint?.route ?? ""}
                      onChange={updateEndpointRoute}
                      sx={{
                        minWidth: "20%",
                        marginTop: "2%",
                        marginBottom: "2%",
                      }}
                      disabled={!userPermissions}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {endpointSelected?.routes?.map((e) => (
                        <MenuItem key={e._id} value={e._id}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          ) : (
            ""
          )}
          {editableNode && feature && feature.value ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                id="endpoint-header">
                <Typography
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}>
                  <Tooltip title="" sx={{ verticalAlign: "middle" }}>
                    <HubOutlinedIcon
                      style={{
                        display: "inline-block",
                        marginBottom: "1%",
                        marginRight: "3%",
                      }}
                    />
                  </Tooltip>
                  {t("flowgraph:ai")}
                </Typography>
              </AccordionSummary>
              <Ai
                agent={agent}
                userPermissions={userPermissions}
                pagesLean={pagesLean}
                data={editNode.data ? editNode.data : {}}></Ai>
            </Accordion>
          ) : null}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand">
              <Tooltip
                title={t("flowgraph:tooltipTitlePlayload")}
                sx={{ verticalAlign: "middle" }}>
                <QuickreplyIcon
                  style={{
                    display: "inline-block",
                    marginBottom: "1%",
                    marginRight: "3%",
                  }}
                />
              </Tooltip>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {t("flowgraph:quickReplies")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <QuickReplies
                userPermissions={userPermissions}
                editNode={editNode}
                repliesFromArray={repliesFromArray}
                errors={errors}
                errorIndex={errorIndex}
                updateErrors={updateErrors}
                updateQuickReplies={updateEditNode}
                //
                intents={intents}></QuickReplies>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand">
              <BookmarkBorderOutlinedIcon
                style={{
                  display: "inline-block",
                  marginBottom: "1%",
                  marginRight: "3%",
                }}
              />
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {t("flowgraph:expressReportTitle")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <TextField
                  label={t("flowgraph:expressReportLabel")}
                  variant="standard"
                  fullWidth
                  disabled={!userPermissions || !editableNode}
                  sx={{ marginTop: "15px", marginBottom: "25px" }}
                  defaultValue={editNode?.data?.expressReport}
                  value={editNode?.data?.expressReport}
                  onChange={(e) =>
                    setEditNode({
                      ...editNode,
                      data: { ...editNode.data, expressReport: e.target.value },
                    })
                  }
                />
              </Tooltip>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                color="error"
                variant="contained"
                onClick={handleDelete}
                disabled={!editableNode || !userPermissions}>
                {t("flowgraph:delete")}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                color="success"
                variant="contained"
                onClick={handleSave}
                disabled={
                  Object.keys(errors).length > 0 || !valid || !userPermissions
                }>
                {t("flowgraph:save")}
              </Button>
            </span>
          </Tooltip>
          <Button variant="contained" onClick={handleClose}>
            {t("flowgraph:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NodeModal;
