import React, { useState, useEffect } from "react";
import styles from "../Login.module.css";
//images
import carrouselImage1 from "../../assets/images/image.jfif";
import carrouselImage2 from "../../assets/images/image2.jfif";
import carrouselImage3 from "../../assets/images/image3.jfif";

const Carousel = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const images = [carrouselImage1, carrouselImage2, carrouselImage3];
  
  const firstText = [
    "Mantente en contacto con tus clientes",
    "Personaliza la atención de tus clientes y",
    "Mejora la experiencia de tus clientes y",
  ];
  
  const secondText = [
    "las 24 horas los 7 días de la semana.",
    "ofrece respuestas inmediatas.",
    "garantiza mejores resultados.",
  ];
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setIsTransitioning(false);
      }, 500);
    }, 3500);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [images.length]);
  
  return (
    <div className={styles.carrusel}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${styles.carruselImg} ${
            currentImage === index ? styles.active : ""
          } ${isTransitioning ? styles.transitioning : ""}`}
        >
          <img src={image} alt={`carrousel-${index + 1}`} />
          <div className={styles.carruselFilter}></div>
        </div>
      ))}
      <div className={styles.body}>
        <div className={styles.textLogin}>
          <div className={styles.textContent}>
            <div className={styles.loremIpsumDolorContainer}>
              <span className={styles.carrouselFirstText}>
                {firstText[currentImage]}
              </span>
              <span className={styles.carrouselSecondText}>
                {" "}
                {secondText[currentImage]}
              </span>
            </div>
            <div className={styles.carrouselThirdText}>
                Darwin by Evoltis.
            </div>
          </div>
        </div>
        <div className={styles.slider}>
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              className={
                currentImage === index ? styles.sliderChild : styles.sliderItem
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
  
export default Carousel;
