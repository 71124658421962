import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { get, post, put, remove } from "../shared/http/httpService";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./EditModal.component";
import { ImportJsonModal } from "./ImportJsonModal.component";
import NewLanguageModal from "./NewLanguageModal.component";
import { Button } from "@mui/material";
import { DeleteRowModal } from "../shared/helper/tableHelper";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import JSZip from "jszip";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import DeleteLanguageModal from "./DeleteLanguageModal.component";

const Translations = () => {
  const { t, i18n } = useTranslation("translations");

  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [importJsonModalOpen, setimportJsonModalOpen] = useState(false);
  const [newLanguageModalOpen, setNewLanguageModalOpen] = useState(false);
  const [deleteLanguageModalOpen, setDeleteLanguageModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const theme = useSelector((state) => state.theme);
  const [languages, setLanguages] = useState([]);
  let [userPermissions, setUserPermissions] = React.useState(false);
  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "translations" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const dynamicColumns = languages.map((language) => {
    return {
      header: t(language.buttonText),
      accessorFn: (original) => {
        const translation = original.translations?.find(
          (item) => item.lang === language.language.toLowerCase(),
        );
        return translation ? translation.value : "";
      },
      id: language.language.toLowerCase(),
      size: 150,
    };
  });

  const staticColumns = [
    {
      header: t("componentName"),
      accessorFn: (original) => original.componentName,
      id: "componentName",
      size: 100,
    },
    {
      header: "Label",
      accessorFn: (original) => original.label,
      id: "label",
      size: 100,
    },
  ];

  const columns = [...staticColumns, ...dynamicColumns];

  const [translations, setTranslations] = useState([{}]);

  useEffect(() => {
    getLanguages();
    getTranslations();
    getUserPermisions();
  }, []);

  const exportToJsonAndZip = (
    originalDataLanguages,
    originalDataTranslations,
  ) => {
    const jsonLanguagesData = JSON.stringify(originalDataLanguages, null, 2);
    const jsonTranslationsData = JSON.stringify(
      originalDataTranslations,
      null,
      2,
    );

    const zip = new JSZip();

    // Add the JSON files to the ZIP archive
    zip.file("languages.json", jsonLanguagesData);
    zip.file("translations.json", jsonTranslationsData);

    // Generate the ZIP file
    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipUrl = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = zipUrl;
      a.download = "translations.zip";
      document.body.appendChild(a);

      a.click();

      URL.revokeObjectURL(zipUrl);
      document.body.removeChild(a);
    });
  };

  const importFromJson = (collection, replace, importData) => {
    if (!replace) {
      post("/" + collection + "/import", importData)
        .then(() => {
          if (collection === "translations") {
            enqueueSnackbar(t("importedTranslationJson"), {
              variant: "success",
            });
            getTranslations();
          } else {
            enqueueSnackbar(t("importedLanguageJson"), {
              variant: "success",
            });
            getLanguages();
          }
        })
        .catch(() => {
          if (collection === "translations") {
            enqueueSnackbar(t("errorImportingTranslations" + collection), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(t("errorImportingLanguage"), {
              variant: "success",
            });
          }
        });
    } else {
      post("/" + collection + "/import/replace", importData)
        .then(() => {
          if (collection === "translations") {
            enqueueSnackbar(t("replacedTranslation" + collection), {
              variant: "success",
            });
            getTranslations();
          } else {
            enqueueSnackbar(t("replacedLanguage"), {
              variant: "success",
            });
            getLanguages();
          }
        })
        .catch(() => {
          if (collection === "translations") {
            enqueueSnackbar(t("errorReplacingTranslation" + collection), {
              variant: "error",
            });
          } else {
            enqueueSnackbar(t("errorReplacingLanguage" + collection), {
              variant: "error",
            });
          }
        });
    }
  };

  const getLanguages = () => {
    get("/languages")
      .then(async (data) => {
        setLanguages(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingLanguages"), {
          variant: "error",
        });
      });
  };

  const getTranslations = () => {
    get("/translations")
      .then(async (data) => {
        setTranslations(data);
      })
      .catch(() => {
        enqueueSnackbar(t("errorGettingTranslations"), {
          variant: "error",
        });
      });
  };

  const newLanguage = (values) => {
    const body = {
      language: values.language,
      buttonText: values.buttonText,
    };
    post("/languages", body)
      .then(() => {
        enqueueSnackbar(t("newLanguage"), {
          variant: "success",
        });
        getLanguages();
        getTranslations();
      })
      .catch(() => {
        enqueueSnackbar(t("errorCreatingLanguage"), {
          variant: "error",
        });
      });
  };

  const newTranslation = (row) => {
    const body = {
      componentName: row.componentName,
      label: row.label,
      translations: row.translations,
    };
    post("/translations", body)
      .then(() => {
        enqueueSnackbar(t("newTranslation"), {
          variant: "success",
        });
        getTranslations();
      })
      .catch(() => {
        enqueueSnackbar(t("errorCreatingTranslation"), {
          variant: "error",
        });
      });
  };

  const updateTranslation = (row, values) => {
    const body = {
      componentName: row.original.componentName,
      label: row.original.label,
      translations: values.translations,
    };
    put("/translations", body)
      .then(async () => {
        enqueueSnackbar(t("translationUpdated"), {
          variant: "success",
        });
        getTranslations();
      })
      .catch(() => {
        enqueueSnackbar(t("errorUpdatingTranslation"), {
          variant: "error",
        });
      });
  };

  const deleteTranslation = (row) => {
    remove("/translations", {
      componentName: row.original.componentName,
      label: row.original.label,
    })
      .then(async () => {
        enqueueSnackbar(t("translationDeleted"), {
          variant: "success",
        });
        getTranslations();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("errorDeletingTranslation"), {
          variant: "error",
        });
      });
  };


  const deleteLanguage = (language, deleteTranslations) => {
    remove(`/languages/${language}?includeTranslations=${deleteTranslations}`)
      .then(async () => {
        enqueueSnackbar(t("languageDeleted"), {
          variant: "success",
        });
        getLanguages();
        getTranslations();
      })
      .catch(() => {
        enqueueSnackbar(t("errorDeletingTranslation"), {
          variant: "error",
        });
      });
  };


  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("translations")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            columns={columns}
            positionActionsColumn="first"
            data={translations}
            enableColumnOrdering
            enableGlobalFilter={false}
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateTranslation}
            enableDelete
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: "flex",
                }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions ? t("editButton") : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      onClick={() => {
                        setEditModalOpen(true);
                        setTypeModal("edit");
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("deleteButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Box>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Button
                      className="mrt-create-new-account-button"
                      id="mrt-create-new-account-button"
                      onClick={() => {
                        setEditModalOpen(true);
                        setTypeModal("new");
                      }}
                      variant="contained"
                      disabled={!userPermissions}>
                      {t("addButton")}
                    </Button>
                  </span>
                </Tooltip>
                <Button
                  className="mrt-create-new-account-button"
                  sx={{ ml: "1rem" }}
                  onClick={() => {
                    exportToJsonAndZip(languages, translations);
                  }}
                  variant="contained">
                  {t("exportButton")}
                </Button>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Button
                      className="mrt-create-new-account-button"
                      sx={{ ml: "1rem" }}
                      onClick={() => {
                        setimportJsonModalOpen(true);
                      }}
                      variant="contained"
                      disabled={!userPermissions}>
                      {t("importButton")}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Button
                      className="mrt-create-new-account-button"
                      sx={{ ml: "1rem" }}
                      onClick={() => {
                        setNewLanguageModalOpen(true);
                      }}
                      variant="contained"
                      disabled={!userPermissions}>
                      {t("newLanguageButton")}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Button
                      className="mrt-create-new-account-button"
                      sx={{ ml: "1rem" }}
                      color="error"
                      onClick={() => {
                        setDeleteLanguageModalOpen(true);
                      }}
                      variant="contained"
                      disabled={!userPermissions}>
                      {t("deleteLanguageButton")}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteTranslation(editRow);
          setDeleteModalOpen(false);
        }}
        title={t("tittleDeleteModal")}
      />
      <ImportJsonModal
        open={importJsonModalOpen}
        onClose={() => setimportJsonModalOpen(false)}
        title={t("titleImportModal")}
        onImport={(replace, importTranslationsData, importLanguagesData) => {
          if (importTranslationsData?.length > 0) {
            importFromJson("translations", replace, importTranslationsData);
          }
          if (importLanguagesData?.length > 0) {
            importFromJson("languages", replace, importLanguagesData);
          }
          setimportJsonModalOpen(false);
        }}
      />
      <NewLanguageModal
        open={newLanguageModalOpen}
        onClose={() => {
          setNewLanguageModalOpen(false);
        }}
        title={t("titleImportModal")}
        onSave={(values) => {
          newLanguage(values);
          setNewLanguageModalOpen(false);
        }}
      />
      <DeleteLanguageModal
        open={deleteLanguageModalOpen}
        onClose={() => setDeleteLanguageModalOpen(false)}
        title={t("titleDeleteLanguageModal")}
        onSave={(values) => {
          deleteLanguage(values.language,values.deleteTranslations);
          setDeleteLanguageModalOpen(false);
        }}
        languages={languages}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        languages={languages}
        rowdata={{
          componentName: editRow?.original?.componentName,
          label: editRow?.original?.label,
          translations: editRow?.original?.translations,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newTranslation(values);
            setEditModalOpen(false);
          } else {
            updateTranslation(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
      />
    </React.Fragment>
  );
};

export default Translations;
