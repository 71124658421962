import React from "react";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Transition from "./transitionDialog";

//delete dialog confirmation
export const DeleteRowModal = ({ open, onClose, onDelete, title }) => {
  const { t } = useTranslation("deleteRowModal");

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.5rem",
        },
      }}>
      <DialogTitle textAlign="center">
        {title ?? "Are you sure you want to delete this row?"}
      </DialogTitle>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button id="delete-row-cancel-button" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          id="delete-row-accept-button"
          color="primary"
          onClick={onDelete}
          variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
