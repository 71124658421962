import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Paper, Box, Container, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import launcherIcon from "../assets/logo-no-bg.svg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { get, put } from "../../shared/http/httpService";
import { setRenderChat } from "../../shared/redux/clientSlice";

const ButtonTab = () => {
  const [imageUrl, setImageUrl] = useState("");
  const { t } = useTranslation("clients");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState("Small");
  const [chatTitle, setChatTitle] = useState(undefined);
  const imageSizes = {
    Small: "60px",
    Medium: "75px",
    Large: "90px",
  };
  const client = useSelector((state) => state.client);

  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "file" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  useEffect(() => {
    getChatCConfig();
    getUserPermisions();
  }, []);

  const getChatCConfig = () => {
    let body = { clientName: client.name };

    get("/file/ChatCConfig/", body)
      .then(async (res) => {
        setImageUrl(res.url);
        setSelectedSize(res.size);
        setChatTitle(res.chatTitle);
      })
      .catch(() => {});
  };

  const updateFile = () => {
    const body = {
      clientName: client.name,
      url: imageUrl,
      size: selectedSize,
      chatTitle: chatTitle,
    };

    put("/file/ChatCConfig/", body)
      .then(async () => {
        enqueueSnackbar(t("personalization:fileUpdated"), {
          variant: "success",
        });
        getChatCConfig();
        dispatch(setRenderChat());
      })
      .catch(() => {
        enqueueSnackbar(t("personalization:errorUploadingFile"), {
          variant: "error",
        });
      });
  };

  const handleImageUrlChange = (event) => {
    setImageUrl(event.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Paper style={{ marginBottom: "10px" }}>
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <h1 style={{ marginTop: "20px" }}>
              {t("personalization:avatarCustomization")}
            </h1>
            <FormControl style={{ width: "70%", marginBottom: "1rem" }}>
              <Box>
                <InputLabel id="image-url-label">
                  {t("personalization:image")}
                </InputLabel>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <span>
                    <Input
                      labelId="image-url-label"
                      value={imageUrl}
                      onChange={handleImageUrlChange}
                      style={{ width: "100%" }}
                      disabled={!userPermissions}
                    />
                  </span>
                </Tooltip>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}>
                <span
                  style={{
                    color: "gray",
                    width: "100%",
                    fontSize: "0.8rem",
                    textAlign: "left",
                  }}>
                  {t("personalization:recomendation")}
                </span>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}>
                <Tooltip
                  title={userPermissions ? "" : t("noPermissionTooltip")}>
                  <Select
                    labelId="size-select-label"
                    id="size-select"
                    value={selectedSize}
                    onChange={(event) => setSelectedSize(event.target.value)}
                    style={{ width: "30%" }}
                    disabled={!userPermissions}>
                    <MenuItem value={"Small"}>
                      {t("personalization:SizeSmall")}
                    </MenuItem>
                    <MenuItem value={"Medium"}>
                      {t("personalization:SizeMedium")}
                    </MenuItem>
                    <MenuItem value={"Large"}>
                      {t("personalization:SizeLarge")}
                    </MenuItem>
                  </Select>
                </Tooltip>
              </Box>
            </FormControl>
            <Box style={{ minwidth: "10vw" }}>
              <div
                style={{
                  margin: "15px",
                  display: "inline-block",
                  width: imageSizes[selectedSize],
                  height: imageSizes[selectedSize],
                  borderRadius: "50%",
                  backgroundColor: "#4E8CFF",
                }}>
                <img
                  src={imageUrl ? imageUrl : launcherIcon}
                  alt="Client Image"
                  style={{
                    width: "inherit",
                    height: "inherit",
                  }}
                />
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "1rem",
                marginBottom: "1rem",
              }}>
              <Tooltip title={userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "1rem" }}
                    onClick={() => updateFile()}
                    disabled={!userPermissions}>
                    {t("save")}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default ButtonTab;
