import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

const EditModal = ({
  typeModal,
  languages,
  rowdata,
  open,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation("translations");

  const [componentName, setComponentName] = useState("");
  const [label, setLabel] = useState("");
  const [translations, setTranslations] = useState([]);
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if (typeModal !== "new") {
      setComponentName(rowdata.componentName);
      setLabel(rowdata.label);
      setTranslations(rowdata.translations);
      setDirty({
        componentName: true,
        label: true,
      });
    } else {
      setComponentName("");
      setLabel("");
      setTranslations([]);
      setDirty({});
    }
  }, [rowdata, typeModal]);

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validate = useCallback(() => {
    const temp = {};
    temp.componentName =
      validateEmpty(componentName) === true
        ? ""
        : t("componentNameCantBeEmpty");
    temp.label = validateEmpty(label) === true ? "" : t("labelCantBeEmpty");
    typeModal !== "new" ? translations?.forEach((translation) => {
      temp[translation.lang] =
            validateEmpty(translation.value) === true
              ? ""
              : t(
                "translation" +
                    translation.lang.toUpperCase() +
                    "CantBeEmpty",
              );
    }) : 
      languages?.forEach((language) => {
        const existingTranslation = translations.find(
          (t) => t.lang === language.language.toLowerCase(),
        );
        if (existingTranslation) {
          temp[language.language] =
              validateEmpty(existingTranslation.value) === true
                ? ""
                : t(
                  "translation" +
                      existingTranslation.lang.toUpperCase() +
                      "CantBeEmpty",
                );
        }
      });

    if (!dirty.componentName && componentName !== "") {
      setDirty({ ...dirty, componentName: true });
    }
    if (!dirty.label && label !== "") {
      setDirty({ ...dirty, label: true });
    }
    setErrors({ ...temp });
  }, [componentName, label, t, translations, dirty]);

  useEffect(() => {
    validate();
  }, [componentName, label, translations, dirty, validate]);

  const handleTranslationChange = (lang, newValue) => {
    const existingTranslation = translations.find(
      (t) => t.lang === lang.toLowerCase(),
    );

    if (!existingTranslation) {
      // Crear una nueva traducción si no existe una con el mismo idioma
      const newTranslation = {
        lang: lang.toLowerCase(),
        value: newValue,
      };

      setTranslations([...translations, newTranslation]);
    } else{
      const updatedTranslations = translations.map((translation) => {
        if (translation.lang === lang.toLowerCase()) {
          return {
            ...translation,
            value: newValue,
          };
        }
        return translation;
      });

      setTranslations(updatedTranslations);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new" ? t("modalTitleNew") : t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          disabled={typeModal !== "new" ? true : false}
          label={t("componentName")}
          required
          sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
          value={componentName}
          error={errors.componentName && dirty.componentName}
          helperText={
            errors.componentName && dirty.componentName
              ? errors.componentName
              : ""
          }
          onChange={(e) => {
            setComponentName(e.target.value);
          }}
        />
        <TextField
          variant="standard"
          disabled={typeModal !== "new" ? true : false}
          label={t("label")}
          required
          sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
          value={label}
          error={errors.label && dirty.label}
          helperText={errors.label && dirty.label ? errors.label : ""}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
        />
        {typeModal !== "new"
          ? languages?.map((language) => (
            <TextField
              key={language.language}
              variant="standard"
              label={t(language.buttonText)}
              required
              sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
              value={translations?.find((translation) => translation.lang === language.language.toLowerCase())?.value}
              error={errors[language.language.toLowerCase()]}
              helperText={
                errors[language.language] ? errors[language.language] : ""
              }
              onChange={(e) => {
                handleTranslationChange(language.language, e.target.value);
              }}
            />
          ))
          : languages?.map((language) => (
            <TextField
              key={language.language}
              variant="standard"
              label={t(language.buttonText)}
              required
              error={errors[language.language]}
              helperText={
                errors[language.language] ? errors[language.language] : ""
              }
              sx={{ width: "75%", marginBottom: "1rem", marginTop: "1rem" }}
              onChange={(e) => {
                handleTranslationChange(language.language, e.target.value);
              }}
            />
          ))}
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={Object.keys(errors).some((x) => errors[x])}
          onClick={() =>
            onSave({
              componentName,
              label,
              translations,
            })
          }>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
