import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

export default function Transfers({ ...props }) {
  const [editNode, setEditNode] = useState({ data: {} });
  const [transferAgent, setTransferAgent] = useState("");
  const [transferAgentPage, setTransferAgentPage] = useState("");
  const { t } = useTranslation("transferAccordion");

  useEffect(() => {
    if (props.editNode.data) {
      setEditNode(props.editNode);
      setTransferAgent(props.editNode.data?.transferToAgent?.agent);
      setTransferAgentPage(props.editNode.data.transferToAgent?.agent_page_id);
    }
  }, [props]);

  return (
    <div>
      <FormControl style={{ width: "93%", marginLeft: "2%" }}>
        <InputLabel id="destination-agent-label">
          {t("destinationAgent")}
        </InputLabel>
        <Select
          disabled={!props.userPermissions}
          variant="standard"
          className="destination-agent"
          value={transferAgent ? transferAgent : ""}
          onChange={(e) => props.updateTransferAgent(e.target.value)}
          fullWidth
          style={{ marginBottom: "15px" }}>
          {editNode.data.transfer_agent
            ? editNode.data.transfer_agent.map((item, index) => (
              <MenuItem
                name={item.display_name}
                value={item.name}
                key={index}>
                <ListItemText primary={item.display_name} />
              </MenuItem>
            ))
            : ""}
        </Select>
      </FormControl>
      <FormControl style={{ width: "93%", marginLeft: "2%" }}>
        <InputLabel id="destination-agent-page-label">
          {t("destinationAgentPage")}
        </InputLabel>
        <Select
          disabled={!props.userPermissions}
          label={t("destinationAgentPage")}
          variant="standard"
          className="destination-agent-page"
          value={transferAgentPage ? transferAgentPage : ""}
          onChange={(e) => props.updateTransferAgentPage(e.target.value)}
          fullWidth
          style={{ marginBottom: "15px" }}>
          {editNode.data.transfer_agent_pages
            ? editNode.data.transfer_agent_pages.map((item, index) => (
              <MenuItem
                name={item.display_name ? item.display_name : item.name}
                value={item._id}
                key={index}>
                <ListItemText
                  primary={item.display_name ? item.display_name : item.name}
                />
              </MenuItem>
            ))
            : ""}
        </Select>
      </FormControl>
    </div>
  );
}
