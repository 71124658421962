import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from "victory";
import DateRange from "../../shared/components/DateRange";
import { get } from "../../shared/http/httpService";
import ChartTheme from "../theme/theme";
import dayjs from "dayjs";
import { mkConfig, generateCsv, download } from "export-to-csv";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: "center",
  },
  button: {
    backgroundColor: "#D9D9D9",
    color: "black",
    height: "40px",
    width: "100px",
    borderRadius: "5px",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));
const InteractionsPerDay = () => {
  const classes = useStyles();
  let chartTheme = ChartTheme();

  const { t } = useTranslation();

  const [valid, setValid] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [dataGraph, setDataGraph] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [total, setTotal] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [services, setServices] = useState([]);
  const [fileName, setFileName] = useState(
    t("analytics:interactionsIAPerDay") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((row) => {
      return {
        [t("analytics:date")]: row.x,
        [t("analytics:count")]: row.y,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchDataInteraction = async () => {
    // eslint-disable-next-line no-useless-escape
    const timezone = startDate.format("Z");
    const interactionPerDayTmp = await get("/report/ia_interactions", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
      timezone,
    });
    //interactionPerDayTmp array with date and count
    const newData = interactionPerDayTmp.map((interaction) => {
      return {
        x: dayjs(interaction.date).format("DD/MM/YYYY"),
        y: interaction.count,
        label: `${interaction.count} interaction/s on the day: ${interaction.date}`,
        totalTokens: interaction.totalTokens,
        totalCost: interaction.totalCost,
        services: interaction.services,
      };
    });
    setDataGraph(newData);

    setTotal(
      newData.reduce((accumulator, object) => {
        return accumulator + object.y;
      }, 0),
    );
    setTotalTokens(
      newData.reduce((accumulator, object) => {
        return accumulator + object.totalTokens;
      }, 0),
    );
    setTotalCost(
      newData.reduce((accumulator, object) => {
        return accumulator + object.totalCost;
      }, 0),
    );
    setServices(
      newData.reduce((accumulator, object) => {
        (object.services || []).forEach((service) => {
          const existingService = accumulator.find(
            (accService) => accService.service === service.service,
          );
          if (existingService) {
            existingService.tokens += service.tokens;
            existingService.cost += service.cost;
            existingService.count += service.count;
          } else {
            accumulator.push({ ...service });
            console.log(services);
          }
        });
        return accumulator;
      }, []),
    );
    setData(newData);
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);

      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:interactionsIAPerDay") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  return (
    <Container maxwidth="lg">
      <Paper
        style={{
          marginBottom: "15px",
          paddingTop: "10px",
          paddingBottom: "15px",
          paddingLeft: "5px",
        }}>
        <Typography variant="h4">{t("analytics:interactionsIa")}</Typography>
        <br></br>
        <Grid container justify="space-around" alignItems="center">
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDate}
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginLeft: "10px",
            }}>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              size="medium"
              disabled={!valid}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}
              onClick={fetchDataInteraction}>
              {t("interactionsPerDay:fetch")}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              disabled={data.length === 0}
              onClick={() => {
                prepareDataForExport(data);
              }}
              size="medium"
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}>
              {t("interactionsPerDay:download")}
            </Button>
          </div>
        </Grid>
        <VictoryChart
          fixLabelOverlap={true}
          responsive={false}
          height={300}
          width={400}
          animate={{
            duration: 500,
            onLoad: { duration: 200 },
          }}
          theme={chartTheme}
          domainPadding={{ x: 30 }}
          padding={80}>
          <VictoryBar
            barRatio={1}
            cornerRadius={{ top: 3 }}
            style={{
              data: {
                fill: "#B474DC",
              },
              labels: {
                fontSize: 5,
                fill: "black",
              },
            }}
            alignment="middle"
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                pointerLength={0}
                flyoutStyle={{
                  stroke: "grey",
                  backgroundColor: "red",
                }}
              />
            }
            data={dataGraph}
          />
          <VictoryAxis
            label={t("interactionsPerDay:date")}
            style={{
              axisLabel: { padding: 30 },
              tickLabels: { angle: -65, fontSize: 5 },
            }}
          />
          <VictoryAxis
            dependentAxis
            label={t("interactionsPerDay:amountOfInteractions")}
            style={{
              axisLabel: { padding: 50 },
            }}
          />
        </VictoryChart>
        <Typography variant="h6">
          {t("interactionsPerDay:totalInteractions")}: {total}
        </Typography>
        <Typography variant="h6">
          {t("interactionsPerDay:totalTokens")}: {totalTokens}
        </Typography>
        <Typography variant="h6">
          {t("interactionsPerDay:totalCost")}: {totalCost}
        </Typography>
        {services.map((service, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{service?.service}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="h6">
                {t("interactionsPerDay:totalTokens")}: {service.tokens}
              </Typography>
              <Typography variant="h6">
                {t("interactionsPerDay:totalCost")}: {service.cost}
              </Typography>
              <Typography variant="h6">
                {t("interactionsPerDay:model")}: {service?.model}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

export default InteractionsPerDay;
