// React imports
import React, { useState, useEffect } from "react";
// Material UI imports
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Collapse,
  Autocomplete,
  Typography,
} from "@mui/material";
//Icons
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SearchIcon from "@mui/icons-material/Search";
// Styles
import "./Bar.component.css";

import TextField from "@mui/material/TextField";
function Bar({
  agentSelected,
  setAgentSelected,
  agentNames,
  zoomIn,
  zoomOut,
  onSave,
  nodes,
  onSearch,
}) {
  //new node drag
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    setSearchValue("");
  }, [agentSelected]);

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        bottom: 0,
        right: 0,
        m: 2,
        width: "40vw",
        height: "8vh",
        marginRight: "32.5vw",
        marginBottom: "5vh",
        bgcolor: "#323232",
        border: "1px solid #1e1e1e",
        borderRadius: "15px",
        zIndex: 100,
        boxShadow: 15,
      }}>
      {/* dragable button */}
      <div
        className={"dndnode input "}
        onDragStart={(event) => {
          onDragStart(event, "default");
          const dragElem = document.createElement("div");
          dragElem.className = "dragging-node";
          dragElem.textContent = "New Node";
          document.body.appendChild(dragElem);
          event.dataTransfer.setDragImage(dragElem, 0, 0);
        }}
        onDragEnd={() => {
          document.querySelector(".dragging-node").remove();
        }}
        style={{
          width: "20%",
          cursor: "grab",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        draggable>
        <AddIcon />
        New Node
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}>
        <UndoIcon />
        Undo
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div
        onClick={onSave}
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}>
        <SaveIcon sx={{ color: "#fff" }} />
        Save
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div
        style={{
          width: "30%",
        }}>
        <FormControl sx={{ mt: 1, minWidth: "90%" }}>
          <InputLabel id="demo-simple-select-label">Agent</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agentSelected}
            label="Agent"
            onChange={(e) => setAgentSelected(e.target.value)}>
            {agentNames.map((agent) => (
              <MenuItem key={agent.name} value={agent.name}>
                {agent.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div
        style={{
          width: "20%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}>
        <ZoomInIcon
          onClick={zoomIn}
          sx={{ cursor: "pointer", height: "100%" }}
        />
        Zoom
        <ZoomOutIcon
          onClick={zoomOut}
          sx={{ cursor: "pointer", height: "100%" }}
        />
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsSearchOpen(!isSearchOpen)}>
        <SearchIcon sx={{ cursor: "pointer" }} />
        Search
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}>
        <Collapse orientation="horizontal" in={isSearchOpen}>
          <Autocomplete
            id="search"
            value={searchValue}
            options={nodes.sort((a, b) =>
              a.data.label.toUpperCase() > b.data.label.toUpperCase() ? 1 : -1,
            )}
            getOptionLabel={(option) =>
              option && option.data ? `${option.data.label} ${option.data.responseMessage}` : ""
            }
            sx={{ width: 200, mr: 1 }}
            onChange={(e, value) => {
              setSearchValue(value);
              onSearch(value);
            }}
            renderOption={(props, option) => (
              <div
                {...props}
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}>
                <div>
                  <Typography variant="subtitle1" align="left">
                    {option.data.label}
                  </Typography>
                </div>
                {option.data.responseMessage &&
                  option.data.responseMessage.length > 0 &&
                  option.data.responseMessage.map((message, index) => (
                    <Typography
                      key={index}
                      variant="body2"
                      align="left"
                      style={{ color: "darkgray" }}>
                      {message}
                    </Typography>
                  ))}
              </div>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Search" variant="outlined" />
            )}
          />
        </Collapse>
      </div>
    </Box>
  );
}

export default React.memo(Bar);
