import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import {
  validateAgentName,
  validateDisplayNameCharacters,
  validateEmpty,
} from "../../shared/helper/validations";
import Transition from "../../shared/helper/transitionDialog";

const EditAgentModal = ({ open, rowData, onClose, onSave }) => {
  const { t } = useTranslation("agents");
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [knowledge, setKnowledge] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [typeModal, setTypeModal] = useState("");
  const filteredAgentNames = agentNames.filter(
    (agentName) => agentName !== name,
  );

  useEffect(() => {
    setName(rowData?.name ? rowData.name : undefined);
    setDisplayName(rowData?.displayName ? rowData.displayName : undefined);
    if (rowData?.knowledge) {
      let arrayKnowledge = [];
      for (let i of rowData.knowledge) {
        arrayKnowledge.push(i);
      }
      setKnowledge(arrayKnowledge);
    } else {
      setKnowledge([]);
    }
    setAgentNames(rowData?.agents.map((agent) => agent.name));
  }, [rowData, open]);

  useEffect(() => {
    setTypeModal(rowData?.name ? "edit" : "new");
  }, [open]);

  const validateUniqueName = (name) => {
    if (
      rowData?.agents?.some(
        (agent) => agent.name === name && agent._id !== rowData.id,
      )
    ) {
      return t("validationAgentNameUnique");
    }
    return false;
  };

  const validateUniqueDisplayName = (displayName) => {
    if (
      rowData?.agents?.some(
        (agent) =>
          agent.display_name === displayName && agent._id !== rowData.id,
      )
    ) {
      return t("validationAgentDisplayNameUnique");
    }
    return false;
  };

  const validate = useCallback(() => {
    const temp = {};
    temp.name =
      name !== undefined
        ? validateEmpty(name)
          ? validateAgentName(name)
            ? validateUniqueName(name)
            : t("validationAgentName")
          : t("validationEmpty")
        : "";
    temp.displayName =
      displayName !== undefined
        ? validateEmpty(displayName)
          ? validateDisplayNameCharacters(displayName)
            ? validateUniqueDisplayName(displayName)
            : t("validationAgentName")
          : t("validationEmpty")
        : "";
    setErrors(temp);
  }, [name, displayName, knowledge]);

  useEffect(() => {
    validate();
  }, [name, displayName, knowledge]);

  const renderInputAgents = useMemo(
    () =>
      function showKnowledge(params) {
        return (
          <TextField
            variant="standard"
            {...params}
            label={t("knowledge")}
            error={errors.agents}
            helperText={errors.agents}
          />
        );
      },
    [t, errors.agents],
  );

  const handleChangeKnowledge = (newValue, knowledge) => {
    const value = newValue;
    if (knowledge) {
      knowledge.splice(0, knowledge.length, value);
      setKnowledge(value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ padding: "1rem", display: "flex", justifyContent: "center" }}>
        {typeModal === "new"
          ? t("modalTitleNew")
          : t("modalTitleEdit") + ` ${name}`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="modal-agent-name"
          variant="standard"
          label={t("name") + " *"}
          disabled={typeModal === "edit" ? true : false}
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={name}
          error={errors.name}
          helperText={errors.name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          id="modal-agent-displayName"
          variant="standard"
          label={t("displayName") + " *"}
          sx={{
            width: "75%",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          value={displayName}
          error={errors.displayName}
          helperText={errors.displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
        />
        <Autocomplete
          id="modal-agent-knowledge"
          multiple
          variant="standard"
          options={filteredAgentNames ? filteredAgentNames : {}}
          value={knowledge}
          sx={{
            width: "75%",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          renderInput={renderInputAgents}
          onChange={(e, value) => handleChangeKnowledge(value, knowledge)}
        />
      </Box>
      <DialogActions>
        <Button
          id="modal-agent-cancel"
          onClick={() => {
            onClose();
          }}>
          {t("modalCancel")}
        </Button>
        <Button
          id="modal-agent-save"
          variant="contained"
          color="primary"
          disabled={Object.keys(errors).some((x) =>
            errors[x] === "" ? true : errors[x],
          )}
          onClick={() => {
            onSave(
              {
                id: rowData.id,
                name,
                displayName,
                knowledge,
              },
              typeModal,
            );
          }}>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAgentModal;
