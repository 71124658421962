import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

import { MarkerType } from "reactflow";

import "../Flowgraph.css";

import EdgeIntents from "./EdgeComponents/edge.intents.component";
import EdgeConditions from "./EdgeComponents/edge.conditions.component";
import EdgeEntity from "./EdgeComponents/edge.entity.component";
import EdgeDateConditions from "./EdgeComponents/edge.date.conditions.component";
import EdgeCleanParams from "./EdgeComponents/edge.clean.params.component";
import EdgePresets from "./EdgeComponents/edge.presets.component";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";
import EdgeMedia from "./EdgeComponents/edge.media.component";

export default function EdgeModal({ ...props }) {
  const [type, setType] = React.useState("intent");
  const [message, setMessage] = React.useState("");
  const [intents, setIntents] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [entity, setEntity] = React.useState({});
  const [media, setMedia] = React.useState({});
  const [presets, setPresets] = React.useState([]);
  const [cleanParams, setCleanParams] = React.useState([]);
  const [valid, setValid] = React.useState(false);
  const [editEdge, setEdit] = React.useState({});
  const [error, setError] = React.useState("");
  const { t } = useTranslation();
  const [userPermissions, setUserPermissions] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setUserPermissions(props.userPermissions);
    setEdit(JSON.parse(JSON.stringify(props.editEdge)));
    setType(props.editEdge.data.type);
    setMessage(props.editEdge.data.message);
    setIntents(props.editEdge.data.intents);
    setConditions(props.editEdge.data.conditions);
    setEntity(props.editEdge.data.entity);
    setMedia(props.editEdge.data.media);
    setPresets(props.editEdge.data.presets);
    setCleanParams(props.editEdge.data.cleanParams);
    setError("");
  }, [props.open]);

  function handleChangeType(e) {
    let value = e.target.value;
    if (value === "intent") {
      setIntents([props.intents[0].name]);
    } else if (value === "condition") {
      setConditions([
        {
          op: "eq",
        },
      ]);
    } else if (value === "date") {
      setConditions([
        {
          op: "time",
          value: {
            startTime: dayjs().startOf("day").format("HH:mm:ss"),
            endTime: dayjs().endOf("day").format("HH:mm:ss"),
          },
        },
      ]);
    } else if (value === "direct") {
      setValid(true);
    }
    setType(value);
    let edit = editEdge;
    edit.data.type = value;
    setEdit(edit);
    if (value === "direct") {
      let valid = validateSameTransition();
      if (!valid) {
        setError(t("flowgraph:errorSameTransition"));
      } else {
        setError("");
      }
      setValid(valid);
    }
  }

  function updateMessage(e) {
    setMessage(e.target.value);
    setValid(true);
  }

  function updateIntents(update) {
    let validIntents;
    setIntents(update);
    if (update.length > 0) {
      setValid(true);
      validIntents = validateSameTransition(update);
      if (!validIntents) {
        setError(t("flowgraph:errorSameTransition"));
        setValid(false);
      } else {
        setError("");
      }
    } else {
      setValid(false);
      setError("");
    }
  }

  function updateConditions(update, valid) {
    setConditions(update);
    if (validateSameTransition(update)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  }

  function updateEntity(update, valid) {
    setEntity(update);
    if (validateSameTransition(entity)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  }

  function updateMedia(update, valid) {
    setMedia(update);
    if (validateSameTransition(media)) {
      setError("");
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  }

  function updateDateConditions(update, valid) {
    updateConditions(update);
    if (validateSameTransition(update)) {
      setValid(valid);
    } else {
      setError(t("flowgraph:errorSameTransition"));
      setValid(false);
    }
  }

  function updatePresets(update) {
    setPresets(update);
  }

  function updateCleanParams(cleanParams) {
    setCleanParams(cleanParams);
  }

  function conditionOperations(operation) {
    let name = "";
    switch (operation) {
      case "eq":
        name = " =";
        break;
      case "neq":
        name = " !=";
        break;
      case "lt":
        name = " <";
        break;
      case "elt":
        name = " <=";
        break;
      case "gt":
        name = " >";
        break;
      case "egt":
        name = " >=";
        break;
      case "cgt":
        name = ".length >=";
        break;
      case "clt":
        name = ".length <=";
        break;
    }
    return name;
  }

  function conditionDates(date) {
    let name = "";
    let operator;
    switch (date.op) {
      case "eq":
        name = "=";
        break;
      case "neq":
        name = "!=";
        break;
      case "time":
        name =
          date.value.startTime.slice(0, 5) +
          " hs - " +
          date.value.endTime.slice(0, 5) +
          " hs";
        break;
      case "date":
        name =
          dayjs(date.value.startDate).format("MM/DD/YYYY") +
          " < 🗓️ " +
          " < " +
          dayjs(date.value.endDate).format("MM/DD/YYYY");
        break;
      case "day":
        name = date.value.join(" | ");
        break;
      case "holiday":
        operator = date.reverse ? "!" : "=";
        name = "Holiday 🗓️ " + operator + "= " + date.value.join(" | ");
        break;
      case "schedule":
        name = date.reverse ? "Out of Schedule" : "Schedule";
        break;
    }
    return name;
  }

  //Validates if the same transition is being created with the same destination node
  function edgeExists() {
    let edit = editEdge;
    let exists = false;

    let edges = props.edges.filter(
      (e) =>
        e.source === edit.source &&
        e.target === edit.target &&
        e.data.type === type &&
        e.id !== edit.id,
    );

    if (edges.length > 0) {
      edges.forEach((edge) => {
        switch (type) {
          case "intent":
            if (edge.data.intents.some((e) => intents.includes(e))) {
              exists = true;
              return;
            }
            break;
          //this case "condition" lines eslinst markes it as an error but it accepted by documentation
          case "condition":
          case "date":
            if (
              JSON.stringify(edge.data.conditions) ===
              JSON.stringify(conditions)
            ) {
              exists = true;
              return;
            }
            break;
          case "entity":
            if (JSON.stringify(edge.data.entity) === JSON.stringify(entity)) {
              exists = true;
              return;
            }
            break;
          case "media":
            if (JSON.stringify(edge.data.media) === JSON.stringify(media)) {
              exists = true;
              return;
            }
            break;
          case "direct":
            exists = true;
            break;
        }
      });
    }
    return exists;
  }

  //Validates if there is an equal transition but with a different destination node
  const validateSameTransition = (change) => {
    let valid = true;
    let transitions = props.transitions;
    let conflictTransitions = [];
    for (let i in transitions) {
      if (
        transitions[i].data.type === editEdge?.data?.type &&
        transitions[i].id !== editEdge?.id
      ) {
        conflictTransitions.push(transitions[i]);
      }
    }
    if (conflictTransitions.length > 0) {
      for (let j in conflictTransitions) {
        if (conflictTransitions[j].source === editEdge?.source) {
          switch (editEdge.data.type) {
            case "intent": {
              let existIntent = conflictTransitions[j].data.intents?.some((e) =>
                change.includes(e),
              );
              if (existIntent) {
                return false;
              }
              break;
            }
            case "condition": {
              let existCondition = conflictTransitions[
                j
              ].data.conditions?.filter((e) =>
                change.some(
                  (n) =>
                    e.op === n.op && e.prop === n.prop && e.value === n.value,
                ),
              );
              if (existCondition?.length === change.length) {
                return false;
              }
              break;
            }
            case "entity": {
              if (
                conflictTransitions[j].data.entity.value === change?.value ||
                conflictTransitions[j].data.entity.name === change?.name
              ) {
                return false;
              }
              break;
            }
            case "media": {
              if (conflictTransitions[j].data.media.name === change?.name) {
                return false;
              }
              break;
            }
            case "date": {
              let conditions = conflictTransitions[j].data.conditions;
              if (valid) {
                valid = validateSameDate(change, conditions);
              }
              break;
            }
            case "direct": {
              return false;
            }
            default:
              valid = true;
              setError("");
              break;
          }
        }
      }
    }
    return valid;
  };

  // Transforms the holidays array into an array of objects with a single holiday
  function transformHolidays(array) {
    let tempArray = [];

    array?.forEach((item) => {
      if (item.op === "holiday" && item.value.length > 1) {
        item.value.forEach((holiday) => {
          tempArray.push({
            op: item.op,
            reverse: item.reverse,
            value: [holiday],
          });
        });
      } else {
        tempArray.push(item);
      }
    });

    return tempArray;
  }

  //Validates if there is a date match
  const validateSameDate = (changes, conditions) => {
    let invalidConditions = [];
    let isHoliday = false;
    let tempChanges = transformHolidays(changes);
    let tempConditions = transformHolidays(conditions);

    for (const change of tempChanges) {
      const changeValue = change.value;
      for (const condition of tempConditions) {
        if (change.op === condition.op) {
          switch (change.op) {
            case "time": {
              const conditionValue = condition.value;
              const changeStart = moment(changeValue.startTime, "HH:mm:ss");
              const changeEnd = moment(changeValue.endTime, "HH:mm:ss");
              const conditionStart = moment(
                conditionValue.startTime,
                "HH:mm:ss",
              );
              const conditionEnd = moment(conditionValue.endTime, "HH:mm:ss");
              if (
                changeStart.isSame(conditionStart) &&
                changeEnd.isSame(conditionEnd)
              ) {
                invalidConditions.push(condition);
              }
              if (
                changeStart.isBetween(conditionStart, conditionEnd) ||
                changeEnd.isBetween(conditionStart, conditionEnd) ||
                conditionStart.isBetween(changeStart, changeEnd) ||
                conditionEnd.isBetween(changeStart, changeEnd)
              ) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "date": {
              const conditionValue = condition.value;
              const changeStartDate = moment(
                changeValue.startDate.toISOString(),
              ).startOf("day");
              const changeEndDate = moment(
                changeValue.endDate.toISOString(),
              ).endOf("day");
              const conditionStartDate = moment(
                conditionValue.startDate,
              ).startOf("day");
              const conditionEndDate = moment(conditionValue.endDate).endOf(
                "day",
              );

              if (
                (changeStartDate.isSameOrBefore(conditionStartDate, "day") &&
                  changeEndDate.isSameOrAfter(conditionStartDate, "day")) ||
                (conditionStartDate.isSameOrBefore(changeStartDate, "day") &&
                  conditionEndDate.isSameOrAfter(changeStartDate, "day"))
              ) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "day": {
              let valid = true;
              const conditionValue = condition.value;
              for (let i = 0; i < 7; i++) {
                if (changeValue[i] !== "" || conditionValue[i] !== "") {
                  if (changeValue[i] === conditionValue[i]) {
                    valid = false;
                    break;
                  } else {
                    valid = true;
                  }
                }
              }
              if (!valid) {
                invalidConditions.push(condition);
              }
              break;
            }
            case "holiday": {
              const conditionValue = condition.value;
              const holidays = changeValue.some((holiday) =>
                conditionValue.includes(holiday),
              );
              if (holidays && change.reverse === condition.reverse) {
                invalidConditions.push(condition);
                isHoliday = true;
              }
              break;
            }
            case "schedule": {
              if (change.reverse === condition.reverse) {
                invalidConditions.push(condition);
              }
              break;
            }
          }
        }
      }
    }
    if (
      invalidConditions.length === tempConditions.length &&
      (invalidConditions.length === tempChanges.length || isHoliday)
    ) {
      return false;
    }
    return true;
  };

  function handleSave() {
    if (!edgeExists()) {
      let edit = editEdge;

      edit.data.type = type;
      edit.data.message = message;
      edit.data.intents = intents;
      edit.data.conditions = conditions;
      edit.data.entity = entity;
      edit.data.cleanParams = cleanParams;
      edit.data.presets = presets;
      edit.data.media = media;
      edit.selected = false;
      edit.animated = false;

      switch (edit.data.type) {
        case "intent":
          edit.data.label = edit.data.intents.join(", ");
          edit.style = { stroke: "#DFBD14", strokeWidth: "2px" };
          edit.markerEnd = {
            type: MarkerType.ArrowClosed,
            width: "22px",
            height: "22px",
            color: "#DFBD14",
          };
          break;

        case "condition":
          if (edit.data.conditions.length > 0) {
            edit.animated = true;
            edit.style = { stroke: "#A100FF", strokeWidth: "2px" };
            edit.markerEnd = {
              type: MarkerType.ArrowClosed,
              width: "22px",
              height: "22px",
              color: "#A100FF",
            };

            edit.data.label = edit.data.conditions
              .map((condition) => {
                return (
                  condition.prop +
                  conditionOperations(condition.op) +
                  " " +
                  condition.value
                );
              })
              .join(", ");
          }
          break;

        case "entity":
          edit.data.label = "entity: " + edit.data.entity.name;
          edit.style = { stroke: "#24a819", strokeWidth: "2px" };
          edit.markerEnd = {
            type: MarkerType.ArrowClosed,
            width: "22px",
            height: "22px",
            color: "#24a819",
          };
          break;

        case "media":
          edit.data.label = "media: " + edit.data.media.name;
          edit.style = { stroke: "#228ee6", strokeWidth: "2px" };
          edit.markerEnd = {
            type: MarkerType.ArrowClosed,
            width: "22px",
            height: "22px",
            color: "#228ee6",
          };
          break;

        case "date":
          if (edit.data.conditions.length > 0) {
            edit.animated = true;
            edit.style = { stroke: "#c876f7", strokeWidth: "2px" };
            edit.markerEnd = {
              type: MarkerType.ArrowClosed,
              width: "22px",
              height: "22px",
              color: "#c876f7",
            };

            edit.data.label = edit.data.conditions
              .map((condition) => {
                return conditionDates(condition);
              })
              .join(", ");
          }
          break;

        case "direct":
          edit.animated = true;
          edit.data.intents = [];
          edit.style = {
            stroke: "#bf0abd",
            strokeWidth: "2px",
          };
          edit.data.label = "Direct Transition";
          edit.markerEnd = {
            type: MarkerType.ArrowClosed,
            width: "22px",
            height: "22px",
            color: "#bf0abd",
          };
          break;

        default:
          edit.style = { stroke: "#DFBD14", strokeWidth: "2px" };
          edit.markerEnd = {
            type: MarkerType.ArrowClosed,
            width: "22px",
            height: "22px",
            color: "#DFBD14",
          };
          break;
      }
      edit.is_modified = true;
      setType("");
      props.onSave(edit);
    } else {
      console.error("Error: duplicate edge");
      enqueueSnackbar("Duplicate edge!", {
        variant: "error",
      });
    }
  }

  function handleClose() {
    setMessage("");
    setIntents([]);
    setConditions([]);
    props.onClose();
  }

  function handleDelete() {
    props.onDelete(editEdge.id);
    setType("");
  }

  return (
    <Fragment>
      <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography style={{ display: "inline-flex" }}>
            {t("flowgraph:transitionEdit")}
          </Typography>
          <Typography style={{ display: "inline-flex", float: "right" }}>
            {editEdge.sourceName} <ChevronRightIcon /> {editEdge.targetName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-label="entityType"
            name="entityType"
            value={editEdge?.data?.type ?? "intent"}
            onChange={handleChangeType}>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="intent"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:intent")}
              />
            </Tooltip>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="condition"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:condition")}
              />
            </Tooltip>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="entity"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:entity")}
              />
            </Tooltip>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="date"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:date")}
              />
            </Tooltip>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="direct"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:direct")}
              />
            </Tooltip>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <FormControlLabel
                value="media"
                disabled={!userPermissions}
                control={<Radio />}
                label={t("flowgraph:media")}
              />
            </Tooltip>
          </RadioGroup>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
            placement="left">
            <TextField
              id="transition-message"
              label={t("flowgraph:message")}
              variant="standard"
              className="transition-message"
              multiline
              disabled={!userPermissions}
              fullWidth
              defaultValue={message}
              onInput={updateMessage}
            />
          </Tooltip>
          {type === "intent" ? (
            <EdgeIntents
              userPermissions={userPermissions}
              list={props.intents}
              intents={intents}
              intentsUpdate={updateIntents}
            />
          ) : null}
          {type === "condition" ? (
            <EdgeConditions
              userPermissions={userPermissions}
              conditions={conditions}
              conditionsUpdate={updateConditions}
            />
          ) : null}
          {type === "entity" ? (
            <EdgeEntity
              userPermissions={userPermissions}
              entity={entity}
              entityUpdate={updateEntity}
              pages={props.pages}
            />
          ) : null}
          {type === "date" ? (
            <EdgeDateConditions
              userPermissions={userPermissions}
              dateConditions={conditions}
              dateConditionsUpdate={updateDateConditions}
              edges={props.edges}
              source={editEdge.source}
              id={editEdge.id}
            />
          ) : null}
          {type === "media" ? (
            <EdgeMedia
              userPermissions={userPermissions}
              media={media}
              mediaUpdate={updateMedia}
            />
          ) : null}
          {error !== "" ? (
            <Typography
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "3%",
              }}>
              {error}
            </Typography>
          ) : null}
          <EdgeCleanParams
            userPermissions={userPermissions}
            cleanParams={props.editEdge.data.cleanParams}
            cleanParamsUpdate={updateCleanParams}
          />
          <EdgePresets
            userPermissions={userPermissions}
            presets={props.editEdge.data.presets}
            presetsUpdate={updatePresets}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                color="error"
                variant="contained"
                disabled={!userPermissions}
                onClick={handleDelete}>
                {t("flowgraph:delete")}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}>
            <span>
              <Button
                color="success"
                variant="contained"
                disabled={!valid || !userPermissions}
                onClick={handleSave}>
                {t("flowgraph:save")}
              </Button>
            </span>
          </Tooltip>
          <Button variant="contained" onClick={handleClose}>
            {t("flowgraph:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
