import axios from "axios";
import { store } from "../redux/configureStore";
import { Buffer } from "buffer";
import { setLoading } from "../loader/actions/loaderActions";
import { logout as logoutAction } from "../redux/authSlice";

const dispatch = store.dispatch;

const URL_BACKEND = process.env.REACT_APP_API_GW || "http://localhost:3001";

const http = axios.create({
  baseURL: URL_BACKEND,
  headers: {
    "Content-type": "application/json",
  },
});

const validResponses = [200, 201];

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      dispatch(logoutAction());
    }
    return error;
  },
);

export const getImage = async (endpoint) => {
  try {
    const response = await axios.get(URL_BACKEND + endpoint, {
      responseType: "arraybuffer",
    });
    return Buffer.from(response.data, "binary").toString("base64");
  } catch (error) {
    console.log(error);
  }
};

export const get = (endpoint, params, notAlert) => {
  let clientRedux = store.getState().client;
  let paramsAux = params;
  if (clientRedux && clientRedux.name !== "") {
    paramsAux = { ...params, clientName: clientRedux.name };
  }
  return new Promise((resolve, reject) => {
    dispatch(setLoading(true));
    http
      .get(endpoint, { params: paramsAux })
      .then((response) => {
        changeStatusSpinner();
        if (response && response.status === 200) {
          return resolve(response.data);
        } else {
          if (response.response && response.response.status === 403) {
            if (!notAlert) {
              alert("No tiene permisos para acceder a este recurso");
            }
            return reject(response.response.data);
          }
          const error = response;
          reject(error);
        }
      })
      .catch(() => {
        let errorMessage = "Intern Error";
        changeStatusSpinner();
        return reject(errorMessage);
      });
  });
};

export const post = (endpoint, data, headers) => {
  let clientRedux = store.getState().client;
  let authRedux = store.getState().auth;
  let clientNameHeaders = {};
  if (clientRedux) {
    clientNameHeaders["darwinclientname"] = clientRedux.name;
    http.defaults.headers.common = {
      ...http.defaults.headers.common,
      ...clientNameHeaders,
    };
  }

  if (headers) {
    http.defaults.headers = {
      ...http.defaults.headers,
      ...headers,
    };
  } else {
    http.defaults.headers = {
      ...http.defaults.headers,
      "Content-type": "application/json",
    };
  }

  let userNameHeader = {};
  let userIdHeader = {};
  userNameHeader["username"] = authRedux.name;
  userIdHeader["userid"] = authRedux._id;
  http.defaults.headers.common = {
    ...http.defaults.headers.common,
    ...userNameHeader,
    ...userIdHeader,
  };

  return new Promise((resolve, reject) => {
    dispatch(setLoading(true));

    http
      .post(endpoint, data)
      .then((response) => {
        changeStatusSpinner();
        if (response) {
          if (response.status && validResponses.includes(response.status)) {
            return resolve(response.data);
          } else if (response.response && response.response.status === 403) {
            if (endpoint !== "/login") {
              alert("No tiene permisos para acceder a este recurso");
            }
            return reject(response.response.data);
          } else {
            if (response.response) {
              const error = {
                status: response.response.status,
                message: response.response.data,
              };
              reject(error);
            }
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        let errorMessage = "Intern Error";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        changeStatusSpinner();
        return reject(errorMessage);
      });
  });
};

export const put = (endpoint, data) => {
  let clientRedux = store.getState().client;
  let authRedux = store.getState().auth;
  let clientNameHeaders = {};
  if (clientRedux) {
    clientNameHeaders["darwinclientname"] = clientRedux.name;
    http.defaults.headers.common = {
      ...http.defaults.headers.common,
      ...clientNameHeaders,
    };
  }

  let userNameHeader = {};
  let userIdHeader = {};
  userNameHeader["username"] = authRedux.name;
  userIdHeader["userid"] = authRedux._id;
  http.defaults.headers.common = {
    ...http.defaults.headers.common,
    ...userNameHeader,
    ...userIdHeader,
  };

  return new Promise((resolve, reject) => {
    dispatch(setLoading(true));
    http
      .put(endpoint, data)
      .then((response) => {
        changeStatusSpinner();
        if (response && validResponses.includes(response.status)) {
          return resolve(response.data);
        } else if (response.response && response.response.status === 403) {
          alert("No tiene permisos para acceder a este recurso");
          return reject(response.response.data);
        } else {
          const error = response;
          reject(error);
        }
      })
      .catch(() => {
        let errorMessage = "Intern Error";
        changeStatusSpinner();
        return reject(errorMessage);
      });
  });
};

export const remove = (endpoint, data) => {
  let clientRedux = store.getState().client;
  let authRedux = store.getState().auth;
  let clientNameHeaders = {};
  if (clientRedux) {
    clientNameHeaders["darwinclientname"] = clientRedux.name;
    http.defaults.headers.common = {
      ...http.defaults.headers.common,
      ...clientNameHeaders,
    };
  }

  let userNameHeader = {};
  let userIdHeader = {};
  userNameHeader["username"] = authRedux.name;
  userIdHeader["userid"] = authRedux._id;
  http.defaults.headers.common = {
    ...http.defaults.headers.common,
    ...userNameHeader,
    ...userIdHeader,
  };
  return new Promise((resolve, reject) => {
    dispatch(setLoading(true));
    http
      .delete(endpoint, { data: data })
      .then((response) => {
        changeStatusSpinner();
        if (response && validResponses.includes(response.status)) {
          return resolve(response.data);
        } else if (response.response && response.response.status === 403) {
          alert("No tiene permisos para acceder a este recurso");
          return reject(response.response.data);
        } else {
          reject(response);
        }
      })
      .catch(() => {
        let errorMessage = "Intern Error";
        changeStatusSpinner();
        return reject(errorMessage);
      });
  });
};

const changeStatusSpinner = () => {
  setTimeout(() => {
    dispatch(setLoading(false));
  }, 1500);
};

export const setToken = (token) => {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const removeToken = () => {
  http.defaults.headers.common["Authorization"] = null;
};
