import React from "react";
import { useTheme } from "@mui/material/styles";

const VideoMessage = (props) => {
  const theme = useTheme();

  return (
    <div
      className="sc-message--text-box"
      style={{
        background: theme.palette.chatTextBox.background,
        minWidth: "290px",
      }}>
      <span
        className="sc-message--text"
        style={{ color: theme.palette.chatMessage.color }}>
        <video
          src={props.message.data.url}
          controls
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </span>
      <span className="sc-message--date">{props.timestamp}</span>
    </div>
  );
};

export default VideoMessage;
