import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Transition from "../shared/helper/transitionDialog";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { Button } from "@mui/material";

const EditModal = ({ open, onClose, onSave, models }) => {
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [selectedModel, setSelectedModel] = useState("");
  const { t } = useTranslation("aiTraining");

  const isSaveDisabled = !name || !selectedModel || !file;

  useEffect(() => {
    if (open) {
      setName("");
      setFile(null);
      setSelectedModel("");
    }
  }, [open]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleIconClick = () => {
    document.getElementById("file-input").click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {t("aiTraining:newTraining")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          id="name"
          variant="standard"
          label={t("nameModal")}
          sx={{ width: "75%", marginBottom: "0.5rem", marginTop: "0.5rem" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl variant="standard" sx={{ width: "75%", marginBottom: "0.5rem" }}>
          <InputLabel>{t("aiTraining:selectModel")}</InputLabel>
          <Select
            id="model"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            label={t("aiTraining:selectModel")}>
            {models.map((model) => (
              <MenuItem key={model.id} value={model.id}>
                {model.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            border: "2px dashed #ccc",
            borderRadius: "4px",
            padding: "1rem",
            textAlign: "center",
            width: "75%",
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}>
          <Tooltip title={t("aiTraining:uploadFile")}>
            <IconButton 
              id="file-input-icon"
              onClick={handleIconClick}
            >
              <InsertDriveFileIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          {file ? (
            <Typography>{file.name}</Typography>
          ) : (
            <Typography>{t("aiTraining:dragDropFile")}</Typography>
          )}
        </Box>
        <input
          type="file"
          id="file-input"
          accept=".jsonl"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </Box>
      <DialogActions>
        <Button 
          id="cancel-button"
          onClick={onClose}>
          {t("aiTraining:modalCancel")}
        </Button>
        <Button
          id="save-button"
          variant="contained"
          color="primary"
          onClick={() => onSave({ name, model: selectedModel, file })}
          disabled={isSaveDisabled}>
          {t("aiTraining:modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
