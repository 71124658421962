import React, { useEffect } from "react";
import Message from "./Messages/Message.js";
import { Box } from "@mui/material";

const ChatViewer = (props) => {
  useEffect(() => {
    console.log();
  }, [props.conversation]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          noWrap: true,
          width: "90%",
          background: "black",
          flexDirection: "column",
          overflow: "auto",
          borderRadius: "10px",
          paddingTop: "10px",
        }}>
        {props.conversation.map((message, i) => {
          return <Message message={message} key={i} />;
        })}
      </Box>
    </div>
  );
};

export default ChatViewer;
