import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { get } from "../shared/http/httpService";

async function obtainLanguages() {
  const json = await get("/languages");
  return json;
}

async function obtainTranslations() {
  const json = await get("/translations/formated");
  const translations = {};

  for (let key in json) {
    const translationObj = json[key];
    const component = {};

    for (let translationKey in translationObj) {
      const translationValue = translationObj[translationKey];
      component[translationKey] = translationValue;
    }

    for (let language in translationObj) {
      if (!translations[key]) {
        translations[key] = {};
      }

      translations[key][language] = component[language];
    }
  }
  return translations;
}

async function initI18n() {
  const languages = await obtainLanguages();
  const translations = await obtainTranslations();

  const namespaces = Object.keys(translations);

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      react: {
        useSuspense: false,
      },
      interpolation: { escapeValue: false },
      lng: localStorage.getItem("language"),
      fallbackLng: "ES",
      resources: {},
    });

  for (let key in languages) {
    const lng = languages[key].language;
    const languageCode = languages[key].language.toLowerCase();

    for (const ns of namespaces) {
      i18next.addResources(lng, ns, translations[ns][languageCode]);
    }
  }
}

initI18n();
export default i18next;
