import React, { Fragment, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import {
  validateCharacters,
  validateEmpty,
} from "../../../shared/helper/validations";
import { Tooltip } from "@mui/material";

export default function EdgeMedia({ ...props }) {
  const [media, setMedia] = React.useState([]);
  const [nameDirty, setNameDirty] = React.useState(false);
  const { t } = useTranslation("edge");

  useEffect(() => {
    if (props.media) {
      setMedia([props.media]);
      setNameDirty(true);
    } else {
      setMedia([{}]);
    }
  }, []);

  useEffect(() => {
    validate();
  }, [media]);

  async function updateName(e, index) {
    let current = [...media];
    current[index].name = e.target.value;
    setMedia(current);
    setNameDirty(true);
    validate();
  }

  function validate() {
    let valid = true;
    if (media && media.length > 0) {
      for (let i = 0, len = media.length; i < len; i++) {
        if (!validateCharacters(media[i].name)) {
          valid = false;
        }

        if (!validateEmpty(media[i].name)) {
          valid = false;
        }
      }
    } else valid = false;
    props.mediaUpdate(media[0], valid);
  }

  return (
    <Fragment>
      <div>
        <h3 className="media-title">{t("mediaTitle")}</h3>

        {media.map((item, index) => (
          <div key={index} className="media">
            <Tooltip
              title={props.userPermissions ? "" : t("noPermissionTooltip")}
              placement="left">
              <TextField
                className="media-name"
                label={t("mediaName")}
                variant="standard"
                multiline
                required
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.name}
                onChange={(e) => updateName(e, index)}
                error={
                  item.name !== ""
                    ? item.name
                      ? nameDirty && !validateCharacters(item.name)
                      : false
                    : true
                }
                helperText={
                  item.name !== ""
                    ? item.name
                      ? nameDirty && !validateCharacters(item.name)
                        ? t("validateNameInvalid")
                        : ""
                      : ""
                    : t("validateNameEmpty")
                }
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
