import React, { useState, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import { get, post, put, remove } from "../shared/http/httpService";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import EditModal from "./EditModal.component";
import { Button } from "@mui/material";
import { DeleteRowModal } from "../shared/helper/tableHelper";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { Chip } from "@mui/material";

import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";

const Routes = () => {
  const { t, i18n } = useTranslation("routes");
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editRow, seteditRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [typeModal, setTypeModal] = useState(null);
  const theme = useSelector((state) => state.theme);
  let auth = useSelector((state) => state.auth);
  let [userPermissions, setUserPermissions] = React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "routes" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const columns = useMemo(
    () => [
      {
        header: "Host",
        accessorFn: (original) => original.host,
        id: "host",
        size: 150,
      },
      {
        header: t("routes"),
        accessorFn: (original) => original.route,
        id: "route",
        size: 150,
      },
      {
        header: "Auth",
        accessorFn: (original) => original.auth,
        id: "auth",
        size: 200,
        Cell: ({ cell }) => {
          let arrayAuth = cell.getValue();
          return (
            <div>
              {Array.isArray(arrayAuth) &&
                arrayAuth.map((method, index) => (
                  <Chip key={index} label={method} variant="outlined" />
                ))}
            </div>
          );
        },
      },
    ],
    [t],
  );

  const [data, setData] = useState([{}]);

  useEffect(() => {
    getRoutes();
    getUserPermisions();
  }, []);

  const getRoutes = () => {
    get("/routes")
      .then(async (data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("routes:errorGettingRoutes"), {
          variant: "error",
        });
      });
  };

  const newRoute = (row) => {
    const body = {
      intent: row.intent,
      host: row.host,
      route: row.route,
      auth: row.auth,
    };
    post("/routes", body)
      .then(() => {
        enqueueSnackbar(t("routes:newRouteAdded"), {
          variant: "success",
        });
        getRoutes();
      })
      .catch(() => {
        enqueueSnackbar(t("routes:errorCreatingRoute"), {
          variant: "error",
        });
      });
  };

  const updateRoute = (row, values) => {
    const body = {
      _id: row.original._id,
      host: values.host,
      route: values.route,
      auth: values.auth,
    };
    put("/routes", body)
      .then(async () => {
        enqueueSnackbar(t("routes:routeUpdated"), {
          variant: "success",
        });
        getRoutes();
      })
      .catch(() => {
        enqueueSnackbar(t("routes:errorUpdatingRoute"), {
          variant: "error",
        });
      });
  };

  const deleteRoute = (row) => {
    remove("/routes", { _id: row.original._id })
      .then(async () => {
        enqueueSnackbar(t("routes:routeDeleted"), {
          variant: "success",
        });
        getRoutes();
        setTypeModal(null);
      })
      .catch(() => {
        enqueueSnackbar(t("routes:errorDeletingRoute"), {
          variant: "error",
        });
      });
  };
  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("routes")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "left",
                },
                size: 50,
              },
            }}
            positionActionsColumn="last"
            columns={columns}
            data={data}
            enableColumnOrdering
            enableGlobalFilter={false}
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateRoute}
            enableDelete
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions ? t("editButton") : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="edit-icon"
                      onClick={() => {
                        setEditModalOpen(true);
                        seteditRow(row);
                        setTypeModal("edit");
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("deleteButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="delete-icon"
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(true);
                        seteditRow(row);
                      }}
                      disabled={!userPermissions}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Tooltip
                arrow
                placement="right"
                title={userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="mrt-create-new-account-button"
                    onClick={() => {
                      setEditModalOpen(true);
                      setTypeModal("new");
                    }}
                    disabled={!userPermissions}
                    variant="contained">
                    {t("addButton")}
                  </Button>
                </span>
              </Tooltip>
            )}
          />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteRoute(editRow);
          setDeleteModalOpen(false);
        }}
        title={t("tittleDeleteModal")}
      />
      <EditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setTypeModal(null);
        }}
        rowdata={{
          _id: editRow?.original?._id,
          host: editRow?.original?.host,
          auth: editRow?.original?.auth,
          route: editRow?.original?.route,
        }}
        onSave={(values) => {
          if (typeModal === "new") {
            newRoute(values);
            setEditModalOpen(false);
          } else {
            updateRoute(editRow, values);
            setEditModalOpen(false);
          }
        }}
        typeModal={typeModal}
      />
    </React.Fragment>
  );
};

export default Routes;
