import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Card, CardContent, Chip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { get } from "../shared/http/httpService";

const Monitoring = () => {
  const { t } = useTranslation("monitoring");
  const { enqueueSnackbar } = useSnackbar();
  const [darwinServices, setDarwinServices] = useState({});
  const [openAiServices, setOpenAiServices] = useState({});
  const [metaServices, setMetaServices] = useState({});

  useEffect(() => {
    getDarwinServices();
    getOpenAiServices();
    getMetaServices();
  }, []);

  useEffect(() => {
    const fetchAllServices = () => {
      getDarwinServices();
      getOpenAiServices();
      getMetaServices();
    };
  
    fetchAllServices();
  
    const intervalId = setInterval(fetchAllServices, 60000);
  
    return () => clearInterval(intervalId);
  }, []);

  const getDarwinServices = () => {
    get("/monitoring/darwinServices")
      .then((res) => {
        setDarwinServices(res);
      })
      .catch(() => {
        enqueueSnackbar("Error fetching Darwin services", { variant: "error" });
      });
  };

  const getOpenAiServices = () => {
    get("/monitoring/openaiServices")
      .then((res) => {
        setOpenAiServices(res);
      })
      .catch(() => {
        enqueueSnackbar("Error fetching OpenAi services", { variant: "error" });
      });
  };

  const getMetaServices = () => {
    get("/monitoring/metaServices")
      .then((res) => {
        setMetaServices(res);
      })
      .catch(() => {
        enqueueSnackbar("Error fetching Meta services", { variant: "error" });
      });
  };

  const getServiceStatus = (services) => {
    return Object.values(services).some(service => service.statusCode === 500)
      ? "warning"
      : "success";
  };

  return (
    <React.Fragment>
      <Container maxWidth={false} style={{ maxWidth: "100%" }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: "10px",
            padding: "20px",
          }}>
          <Typography
            variant="h4"
            sx={{ mb: 4 }}>
            {t("monitoring")}
          </Typography>
          <Accordion 
            sx={{ 
              width: "100%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              "&:before": {
                display: "none",
              },
              mb: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="darwin-services-content"
              id="darwin-services-header"
              sx={{
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {getServiceStatus(darwinServices) === "warning" ? (
                  <ErrorOutlineIcon sx={{ mr: 1, color: "orange" }} />
                ) : (
                  <CheckCircleIcon sx={{ mr: 1, color: "green" }} />
                )}
                <Typography variant="h6">{t("darwinServices")}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {Object.entries(darwinServices).map(([serviceName, serviceData]) => (
                  <Grid item xs={12} sm={6} md={4} key={serviceName}>
                    <Card 
                      sx={{ 
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "box-shadow 0.3s ease-in-out",
                        "&:hover": {
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          {serviceName.startsWith("host_")
                            ? serviceName.replace("host_", "").charAt(0).toUpperCase() + serviceName.replace("host_", "").slice(1)
                            : serviceName}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                            {t("statusCode")}:
                          </Typography>
                          <Chip
                            label={serviceData.statusCode}
                            color={serviceData.statusCode === 200 ? "success" : "error"}
                            size="small"
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {t("statusMessage")}: {serviceData.statusMessage || t("noMessage")}
                        </Typography>
                      </CardContent>
                      <Box sx={{ p: 2, pt: 0 }}>
                        {serviceData.statusCode === 200 ? (
                          <Chip
                            icon={<CheckCircleIcon />}
                            label={t("healthy")}
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            icon={<ErrorIcon />}
                            label={t("unhealthy")}
                            color="error"
                            variant="outlined"
                          />
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion 
            sx={{ 
              width: "100%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              "&:before": {
                display: "none",
              },
              mb: 2,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="openai-services-content"
              id="openai-services-header"
              sx={{
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {getServiceStatus(openAiServices) === "warning" ? (
                  <ErrorOutlineIcon sx={{ mr: 1, color: "orange" }} />
                ) : (
                  <CheckCircleIcon sx={{ mr: 1, color: "green" }} />
                )}
                <Typography variant="h6">{t("openAiServices")}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {Object.entries(openAiServices).map(([serviceName, serviceData]) => (
                  <Grid item xs={12} sm={6} md={4} key={serviceName}>
                    <Card 
                      sx={{ 
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "box-shadow 0.3s ease-in-out",
                        "&:hover": {
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          {serviceName}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                            {t("statusCode")}:
                          </Typography>
                          <Chip
                            label={serviceData.statusCode}
                            color={serviceData.statusCode === 200 ? "success" : "error"}
                            size="small"
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {t("statusMessage")}: {serviceData.statusMessage || t("noMessage")}
                        </Typography>
                      </CardContent>
                      <Box sx={{ p: 2, pt: 0 }}>
                        {serviceData.statusCode === 200 ? (
                          <Chip
                            icon={<CheckCircleIcon />}
                            label={t("healthy")}
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            icon={<ErrorIcon />}
                            label={t("unhealthy")}
                            color="error"
                            variant="outlined"
                          />
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion 
            sx={{ 
              width: "100%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="meta-services-content"
              id="meta-services-header"
              sx={{
                backgroundColor: "primary.main",
                color: "primary.contrastText",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {getServiceStatus(metaServices) === "warning" ? (
                  <ErrorOutlineIcon sx={{ mr: 1, color: "orange" }} />
                ) : (
                  <CheckCircleIcon sx={{ mr: 1, color: "green" }} />
                )}
                <Typography variant="h6">{t("metaServices")}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 3 }}>
              <Grid container spacing={3}>
                {Object.entries(metaServices).map(([serviceName, serviceData]) => (
                  <Grid item xs={12} sm={6} md={4} key={serviceName}>
                    <Card 
                      sx={{ 
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        transition: "box-shadow 0.3s ease-in-out",
                        "&:hover": {
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          {serviceName}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                            {t("statusCode")}:
                          </Typography>
                          <Chip
                            label={serviceData.statusCode}
                            color={serviceData.statusCode === 200 ? "success" : "error"}
                            size="small"
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {t("statusMessage")}: {serviceData.statusMessage || t("noMessage")}
                        </Typography>
                      </CardContent>
                      <Box sx={{ p: 2, pt: 0 }}>
                        {serviceData.statusCode === 200 ? (
                          <Chip
                            icon={<CheckCircleIcon />}
                            label={t("healthy")}
                            color="success"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            icon={<ErrorIcon />}
                            label={t("unhealthy")}
                            color="error"
                            variant="outlined"
                          />
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Monitoring;