export const changeNameChannels = (channels) => {
  let channelsName = [];
  channels.forEach((channel) => {
    if (channel === "MESSENGER") {
      channelsName.push("Facebook");
    }
    if (channel === "WHATSAPP") {
      channelsName.push("WhatsApp");
    }
    if (channel === "WEB") {
      channelsName.push("Web");
    }
    if (channel === "INSTAGRAMDIRECT") {
      channelsName.push("Instagram");
    }
    if (channel === "WEBCHAT") {
      channelsName.push("Webchat");
    }
    if (channel === "msteams") {
      channelsName.push("Teams");
    }
  });
  return channelsName;
};

export const channelsList = [
  { value: "MESSENGER", label: "Facebook" },
  { value: "WHATSAPP", label: "WhatsApp" },
  { value: "WEB", label: "Web" },
  { value: "INSTAGRAMDIRECT", label: "Instagram" },
  { value: "WEBCHAT", label: "Webchat" },
  { value: "msteams", label: "Teams" },
];
