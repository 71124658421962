import React, { useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import chatImage from "../assets/chat.png";
import PropTypes from "prop-types";

const ChatTab = ({
  userPermissionsProps,
  saveChatConfig,
  chatSettingsProps,
}) => {
  const { t } = useTranslation("personalization");
  const [userPermissions] = React.useState(userPermissionsProps);
  const [chatSettings, setChatSettings] = React.useState({});

  useEffect(() => {
    setChatSettings(chatSettingsProps);
  }, [chatSettingsProps?.chatTitle]);

  const handleSaveChat = () => {
    let tempChatSettings = chatSettings;
    tempChatSettings.chatTitle = chatSettings.chatTitle;
    saveChatConfig(tempChatSettings);
  };

  return (
    <React.Fragment>
      <Container>
        <Paper style={{ marginBottom: "10px" }}>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <h1 style={{ marginTop: "20px" }}>{t("chatCustomization")}</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}>
              <Box>
                <h3>Chat</h3>
                <img src={chatImage} alt="ChatImage" />
              </Box>
              <Box sx={{ width: "50%" }}>
                <h3>{t("options")}</h3>
                <FormControl style={{ marginBottom: "1rem", width: "100%" }}>
                  <InputLabel id="chat-title-input">
                    {t("chatTitle")}
                  </InputLabel>
                  <Input
                    fullWidth
                    value={chatSettings.chatTitle}
                    labelId="chat-title-input"
                    style={{ minWidth: "80%" }}
                    disabled={!userPermissions}
                    onChange={(e) =>
                      setChatSettings((prevSettings) => ({
                        ...prevSettings,
                        chatTitle: e.target.value,
                      }))
                    }
                  />
                </FormControl>
              </Box>
            </div>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "1rem",
                marginBottom: "1rem",
              }}>
              <Tooltip title={userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "1rem" }}
                    onClick={() => handleSaveChat()}
                    disabled={!userPermissions}>
                    {t("save")}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

ChatTab.propTypes = {
  userPermissionsProps: PropTypes.bool.isRequired,
  saveChatConfig: PropTypes.func.isRequired,
  chatSettingsProps: PropTypes.object.isRequired,
};

export default ChatTab;
