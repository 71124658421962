import React from "react";
import { Chip } from "@mui/material";
import { Box } from "@mui/material";

const onClick = (value, props, setSelected, index, setIndexSelected) => {
  props.onClick(value);
  setSelected((s) => !s);
  setIndexSelected(index);
};

const QuickMessage = (props) => {
  const [selected, setSelected] = React.useState(false);
  const [indexSelected, setIndexSelected] = React.useState(-1);
  return props.message.data.quickReplies.map((item, index) => {
    return (
      <Box key={index}>
        <Chip
          key={index}
          color="primary"
          variant={indexSelected === index ? "default" : "outlined"}
          label={item.label}
          onClick={() =>
            onClick(item.value, props, setSelected, index, setIndexSelected)
          }
          disabled={selected ? true : false}
          sx={{
            cursor: "pointer",
            width: "280px",
            "&:hover": {
              backgroundColor: "primary.main",
              color: "#0A60FF",
            },
            fontSize: "14px",
            fontAlign: "center",
            padding: "0.2rem",
            margin: "0.3rem",
          }}
        />
        &nbsp;
      </Box>
    );
  });
};

export default QuickMessage;
