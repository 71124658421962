import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  validateUrlHost,
  validateUrlRoute,
} from "../shared/helper/validations";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

const EditModal = ({ typeModal, rowdata, open, onClose, onSave }) => {
  const { t } = useTranslation("routes");

  const [methods] = useState([
    {
      name: "GET",
      value: "GET",
    },
    {
      name: "POST",
      value: "POST",
    },
    {
      name: "PUT",
      value: "PUT",
    },
    {
      name: "DELETE",
      value: "DELETE",
    },
  ]);

  const [auth, setAuth] = useState([]);
  const [host, setHost] = useState("");
  const [route, setRoute] = useState("");

  const [errors, setErrors] = useState({});

  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if (typeModal !== "new") {
      setHost(rowdata.host);
      setAuth(rowdata.auth);
      setRoute(rowdata.route);
      setDirty({
        host: true,
        route: true,
      });
    } else {
      setHost("");
      setAuth([]);
      setRoute("");
      setDirty({});
    }
  }, [rowdata, typeModal]);

  const validateEmpty = (field) => {
    return field === undefined || field === "" ? false : true;
  };

  const validateHostLocal = useCallback((host) => {
    if (!validateEmpty(host)) return t("HostCantBeEmpty");
    if (!validateUrlHost(host)) return t("validationHost");
    return true;
  });

  const validateRouteLocal = useCallback((route) => {
    if (!validateEmpty(route)) return t("routeCantBeEmpty");
    if (!validateUrlRoute(route)) return t("validationRoute");
    return true;
  });

  const validate = useCallback(() => {
    const temp = {};
    temp.host = validateHostLocal(host) === true ? "" : validateHostLocal(host);
    temp.route =
      validateRouteLocal(route) === true ? "" : validateRouteLocal(route);

    if (!dirty.host && host !== "") {
      setDirty({ ...dirty, host: true });
    }

    if (!dirty.route && route !== "") {
      setDirty({ ...dirty, route: true });
    }

    setErrors({ ...temp });
  }, [route, host, t]);

  useEffect(() => {
    validate();
  }, [host, route]);

  const getOptionMethodsLabel = useMemo(() => (option) => option.name, []);

  const valueMethods = useMemo(
    () => methods.filter((x) => auth?.includes(x.name)),
    [methods, auth],
  );

  const handleMethodsChange = useCallback((event, newValue) => {
    if (newValue !== null) {
      let newAuth = [];
      newValue.forEach((value) => {
        newAuth.push(value.name);
      });
      setAuth(newAuth);
    } else {
      setAuth([]);
    }
  }, []);

  const renderInputMethods = useMemo(
    () =>
      function renderInputMethodsTextfield(params) {
        return <TextField variant="standard" {...params} label={t("auth")} />;
      },
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {typeModal === "new" ? t("modalTitleNew") : t("modalTitleEdit")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          label={"Host"}
          id={"routes-editModal-host"}
          required
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={host}
          error={errors.host && dirty.host}
          helperText={errors.host && dirty.host ? errors.host : ""}
          onChange={(e) => {
            setHost(e.target.value);
          }}
        />
        <TextField
          variant="standard"
          label={t("route")}
          id={"routes-editModal-route"}
          required
          sx={{ width: "75%", marginBottom: "2rem", marginTop: "2rem" }}
          value={route}
          error={errors.route && dirty.route}
          helperText={errors.route && dirty.route ? errors.route : ""}
          onChange={(e) => {
            setRoute(e.target.value);
          }}
        />
        <Autocomplete
          multiple
          variant="standard"
          options={methods}
          id={"routes-editModal-auth"}
          value={valueMethods}
          getOptionLabel={getOptionMethodsLabel}
          sx={{ width: "75%", marginTop: "2rem", marginBottom: "2rem" }}
          onChange={handleMethodsChange}
          renderInput={renderInputMethods}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          id="modal-save-button"
          disabled={Object.keys(errors).some((x) => errors[x])}
          onClick={() =>
            onSave({
              host,
              route,
              auth,
            })
          }>
          {t("modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
