import React, { useEffect } from "react";
import { Button, TextField, Checkbox} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../Login.module.css";

const LoginForm = ({errors, username, password, rememberIp, handleLogin, setErrors, setRememberIp, setUsername, setPassword, setDialogForgotPassword, setDialogContactSupport}) => {
  
  useEffect(() => {
  }, [errors.username, errors.password, errors.credentials]);
  
  return (
    <div className={styles.content1}>
      <div className={styles.content2}>
        <div className={styles.ttuloDescripcion}>
          <div className={styles.emoji}>👋</div>
          <div className={styles.tituloDescripcion}>
            <div className={styles.titulo1}>
              <p className={styles.titulo1}>Te damos la</p>
              <p className={styles.titulo2}>bienvenida</p>
            </div>
            <div className={styles.elUniversoDe}>
              El universo de Darwin está listo para que explores todas sus
              funcionalidades y potencies la experiencia de tus clientes.
            </div>
          </div>
        </div>
        <div className={styles.formRecordarme}>
          <div className={styles.form}>
            <TextField
              label="Usuario"
              fullWidth
              variant="filled"
              value={username}
              onFocus={() => {
                let errorsTemp = {...errors};
                delete errorsTemp.username;
                setErrors(errorsTemp);
              }}
              id={"login_input_username"}
              className={styles.textField}
              error={(errors.credentials || errors.username)? true:false}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              InputProps={{
                endAdornment: (errors.credentials || errors.username) ? 
                  <ErrorOutlineIcon style={{ color: "#9F1919" }} />  : 
                  (username || username!="") ? 
                    <CloseIcon onClick={() => {setUsername("");}} style={{ cursor: "pointer", color: "2C2F88" }} />
                    : null,
              }}
            />
            {
              (errors.credentials || errors.username) ?
                <div id="username-error-msg" className={styles.helper2FaError}>{errors.username ?? errors.credentials}</div> : null
            }
            <TextField
              label="Contraseña"
              fullWidth
              onFocus={() => {
                let errorsTemp = {...errors};
                delete errorsTemp.password;
                setErrors(errorsTemp);
              }}
              type="password"
              variant="filled"
              value={password}
              id={"login_input_password"}
              className={styles.textField}
              error={(errors.credentials || errors.password)? true:false}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
              InputProps={{
                endAdornment: (errors.credentials || errors.password) ? 
                  <ErrorOutlineIcon style={{ color: "#9F1919" }} /> : 
                  (password || password!="") ? 
                    <CloseIcon onClick={() => {setPassword("");}} style={{ cursor: "pointer", color: "2C2F88" }} /> 
                    : null,
              }}
            />
          </div>
          {
            (errors.credentials || errors.password) ?
              <div id="password-error-msg" className={styles.helper2FaError}>{errors.password?? errors.credentials}</div> : null
          }
          <div className={styles.recordarmeForgot}>
            <div className={styles.recordarme}>
              <Checkbox
                size="small"
                id="remember_me_check"
                onChange={(e) => setRememberIp(e.target.checked)}
                value={rememberIp}
                sx={{
                  color: "#2C2F88",
                  "&.Mui-checked": { color: "#2C2F88" },
                }}
              />
              <label htmlFor="remember_me_check" className={styles.recordarme}>
                Recuérdame
              </label>
            </div>
            <div
              onClick={() => setDialogForgotPassword(1)}
              id="forgot-password"
              className={styles.recordarme}>
                Olvidé mi contraseña
            </div>
          </div>
        </div>
        <div className={styles.botForm}>
          <Button
            variant="contained"
            id={"login_button"}
            onClick={handleLogin}
            sx={{
              fontSize: "20px",
              textAlign: "center",
              minWidth: "248px",
              width: "100%",
            }}>
            Iniciar sesión
          </Button>
          <div className={styles.necesitasAyudaParaIniciarSParent}>
            <div className={styles.teDamosLaContainer}>
              <span>¿Necesitas ayuda para iniciar sesión?</span>
            </div>
            <a
              id="contact-support"
              onClick={() => setDialogContactSupport(true)}
              className={styles.buttonTypeLink}>
              Contáctate con soporte.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;