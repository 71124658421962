import React from "react";
import { Handle, Position } from "reactflow";
import { useSelector } from "react-redux";

import "./customNode.css";

export default function CustomNode({ data }) {
  const theme = useSelector((state) => state.theme);
  let bgColor = theme.darkTheme === true ? "#FFFFFF" : "#20212E";
  let textColor = theme.darkTheme === true ? "#20212E" : "#FFFFFF";
  let shape = nodeShape();

  function nodeShape() {
    if (data.page_type === "input") return "start";
    if (data.label === "End Session") return "start";
    if (data.endpoint) return "cloud";
    if (data.transferToAgent?.agent) return "agent";
    if (data.core_transfer_page) return "transfer";
    if (
      data.quickReplies?.length > 0 ||
      (data.quickRepliesDynamic?.length > 0 &&
        data.quickRepliesDynamic[0]?.arrayName !== undefined)
    )
      return "quickReplies";
    return "normal";
  }

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        padding: "10px",
        fontSize: "12px",
        textAlign: "center",
        cursor: "grab",
        boxSizing: "border-box",
        pointerEvents: "all",
        "-webkit-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
      }}
      className={`${shape} ${"customNode"}`}>
      {data.page_type !== "input" ? (
        <Handle
          type="target"
          position={Position.Top}
          id="b"
          style={{ marginTop: shape === "cloud" ? "-24px" : "" }}
        />
      ) : (
        ""
      )}
      <div>
        <label htmlFor="text">{data.label}</label>
      </div>
      {shape !== "agent" && data.label !== "End Session" ? (
        <Handle type="source" position={Position.Bottom} id="a" />
      ) : (
        ""
      )}
    </div>
  );
}
