import React, { useState } from "react";
import { Box, Fab, Tooltip, Zoom } from "@mui/material";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import QuestionIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";

const FabButtons = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("home");
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 24,
        right: 124,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 2,
      }}>
      <Zoom in={open} timeout={200}>
        <Tooltip title={t("makeTour")} placement="left">
          <Fab
            color="default"
            aria-label="document"
            sx={{ borderRadius: "12px" }}
            size="small">
            <BoltOutlinedIcon />
          </Fab>
        </Tooltip>
      </Zoom>
      <Tooltip title={t("documentation")} placement="left">
        <a
          href="https://tu-enlace-de-documentacion.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ margin: 0, display: "inline-flex" }}>
          <Zoom in={open} timeout={200}>
            <Fab
              color="default"
              aria-label="question"
              size="small"
              sx={{ borderRadius: "12px" }}>
              <ArticleOutlinedIcon />
            </Fab>
          </Zoom>
        </a>
      </Tooltip>
      <Fab
        variant="extended"
        color="primary"
        sx={{ height: "56px", borderRadius: "12px" }}
        onClick={() => setOpen(!open)}>
        <QuestionIcon />
      </Fab>
    </Box>
  );
};

export default FabButtons;
