import React, { Fragment, useEffect } from "react";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";

export default function EdgePresets({ ...props }) {
  const [presets, setPresets] = React.useState([]);
  const { t } = useTranslation("edge");

  useEffect(() => {
    if (Array.isArray(props.presets)) {
      setPresets(props.presets);
    }
  }, [props.presets]);

  useEffect(() => {
    props.presetsUpdate(presets);
  }, [presets]);

  function handleAddPreset() {
    let current = [...presets];
    current.push({});
    setPresets(current);
  }

  function handleRemovePreset() {
    if (presets.length > 0) {
      let current = [...presets];
      current.pop({});
      setPresets(current);
    }
  }

  function updateName(e, index) {
    if (presets.length) {
      let current = [...presets];
      current[index].prop = e.target.value;
      setPresets(current);
    }
  }

  function updateValue(e, index) {
    if (presets.length) {
      let current = [...presets];
      current[index].value = e.target.value;
      setPresets(current);
    }
  }

  return (
    <Fragment>
      <div className="preset">
        <h3 className="preset-title">
          {t("presets")}
        </h3>
        <div className="preset-buttons">
          <Tooltip
            title={
              !props.userPermissions
                ? t("noPermissionTooltip")
                : t("tooltipPresetsAdd")
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                onClick={handleAddPreset}
                style={{ cursor: "pointer" }}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !props.userPermissions
                ? t("noPermissionTooltip")
                : t("tooltipPresetsDelete")
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                onClick={handleRemovePreset}
                style={{ cursor: "pointer" }}>
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        {presets?.map((item, index) => (
          <div key={index} className="preset-header">
            <h3 className="preset-title">
              {t("preset")} {index + 1}
            </h3>
            <Tooltip
              title={!props.userPermissions ? "noPermissionTooltip" : ""}>
              <TextField
                className="preset-name"
                label={t("presetName")}
                variant="standard"
                multiline
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.prop ? item.prop : ""}
                onChange={(e) => updateName(e, index)}
              />
            </Tooltip>
            <Tooltip
              title={!props.userPermissions ? "noPermissionTooltip" : ""}>
              <TextField
                className="preset-value"
                label={t("presetValue")}
                variant="standard"
                multiline
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item.value ? item.value : ""}
                onChange={(e) => updateValue(e, index)}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
