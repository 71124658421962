import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const TimeRange = (props) => {
  const { t } = useTranslation();

  const [selectedTime, setSelectedTime] = useState({});
  const [validTimes, setValidTimes] = useState({});
  const [validationComplete, setValidationComplete] = useState(false);

  useEffect(() => {
    if (!props.disabled || props.disabled === false) {
      updateSelectedTime("00:00:00", "23:00:00");
      setValidationComplete(true);
    }
  }, [props.disabled]);

  useEffect(() => {
    updateSelectedTime(props.startTime, props.endTime);
    setValidationComplete(true);
  }, [props.startTime, props.endTime]);

  const updateSelectedTime = (startTime, endTime) => {
    const tempStartTime = dayjs(startTime, "HH:mm:ss");
    const tempEndTime = dayjs(endTime, "HH:mm:ss");

    const tempSelectedTime = {
      startTime: tempStartTime,
      endTime: tempEndTime,
      tz: tempStartTime?.utcOffset(),
      valid: validateTimes(tempStartTime, tempEndTime),
    };

    const formattedStartTime = tempSelectedTime.startTime.format("HH:mm:ss");
    const formattedEndTime = tempSelectedTime.endTime.format("HH:mm:ss");

    const propSelectedTime = {
      ...tempSelectedTime,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    };
    setSelectedTime(tempSelectedTime);
    props.onTimeSelected(propSelectedTime);
  };

  function validStringTime(time) {
    return dayjs(time, "HH:mm:ss").isValid();
  }

  function validateTimes(startTime, endTime) {
    let tempvalidTimes = {
      startTime: true,
      endTime: true,
      range: true,
    };
    if (!startTime || startTime.toString() === "Invalid Date") {
      tempvalidTimes.startTime = false;
    }

    if (!endTime || endTime?.toString() === "Invalid Date") {
      tempvalidTimes.endTime = false;
    }

    if (startTime?.format("HH:mm") > endTime?.format("HH:mm")) {
      tempvalidTimes.range = false;
    }
    setValidTimes(tempvalidTimes);
    return !Object.values(tempvalidTimes).some((valid) => valid === false);
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: "flex",
            flexDirection: props.orientation ? "column" : "row",
            paddingRight: "5px",
            paddingLeft: "5px",
          }}>
          <div
            style={{
              margin: "5px",
            }}>
            <MobileTimePicker
              label={t("timeRange:startTime")}
              value={selectedTime.startTime}
              onChange={(e) => updateSelectedTime(e, selectedTime.endTime)}
              disabled={props.disabled}
              maxTime={selectedTime.endTime ? dayjs(selectedTime.endTime) : null}
              ampm={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder
                      ? props.placeholder
                      : undefined,
                    readOnly: true,
                  }}
                  error={
                    (!validationComplete ||
                      !validTimes.startTime ||
                      !validTimes.range) &&
                    !props.disabled
                  }
                  helperText={
                    (!validationComplete &&
                      (params.inputProps.value
                        ? validStringTime(params.inputProps.value)
                          ? null
                          : t("timeRange:validationCompleteTime")
                        : t("timeRange:validationStartTime"))) ||
                    (!props.disabled &&
                      validTimes.startTime &&
                      !validTimes.range &&
                      t("timeRange:errorSelectionTime"))
                  }
                />
              )}
            />
          </div>
          <div style={{ margin: "5px", display: "inline-block" }}>
            <MobileTimePicker
              label={t("timeRange:endTime")}
              value={selectedTime.endTime}
              onChange={(e) => updateSelectedTime(selectedTime.startTime, e)}
              minTime={selectedTime.startTime ? dayjs(selectedTime.startTime) : null}
              disabled={props.disabled}
              ampm={false}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder
                      ? props.placeholder
                      : undefined,
                    readOnly: true,
                  }}
                  error={
                    (!validationComplete ||
                      !validTimes.endTime ||
                      !validTimes.range) &&
                    !props.disabled
                  }
                  helperText={
                    !props.disabled &&
                    !validationComplete &&
                    (params.inputProps.value
                      ? validStringTime(params.inputProps.value)
                        ? null
                        : t("timeRange:validationCompleteTime")
                      : t("timeRange:validationEndTime"))
                  }
                />
              )}
            />
          </div>
        </div>
        <span
          style={{
            color: "red",
            fontSize: 12,
            display: "block",
            margin: "0px 5px 0px 5px",
          }}>
          {!validationComplete || !validTimes.range
            ? t("timeRange:errorSelectionTime")
            : ""}{" "}
        </span>
      </LocalizationProvider>
    </React.Fragment>
  );
};
export default TimeRange;
