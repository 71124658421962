import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { validateEmpty, validateUrlRoute } from "../../../shared/helper/validations";

const AuthItem = ({ auth, _id, arrayAuth, modalDeleteAuth, saveAuth, userPermissions }) => {
  const { t } = useTranslation("apiauths");
  //Only for show password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [editing, setEditing] = useState();
  const [edit, setEdit] = useState();
  const [errors, setErrors] = useState();
  const [save, setSave] = useState();
  const [isNew, setIsNew] = useState({});

  useEffect(() => {
    setEditing(auth.editing);
    setErrors({});
    let tempEdit;
    if (auth.editing) {
      if(auth.add) {
        tempEdit = { 
          _id: auth._id,
          type: auth.type,
          payload: {
            username: {
              key: auth.payload.username.key,
              value: auth.payload.username.value,
            },
            password: {
              key: auth.payload.password.key,
              value: auth.payload.password.value,
            },
            loginRoute: auth.payload.loginRoute,
            tokenPrefix: auth.payload.tokenPrefix,
          },
          new: auth.new,
          editing: auth.editing,
        };
        delete auth.add;
        delete arrayAuth[_id].add;
      } else {
        tempEdit = { 
          _id: auth._id,
          type: edit?.type ?? auth.type,
          payload: {
            username: {
              key:edit?.payload?.username?.key ??  auth.payload.username.key,
              value:edit?.payload?.username?.value ??  auth.payload.username.value,
            },
            password: {
              key:edit?.payload?.password?.key ??  auth.payload.password.key,
              value:edit?.payload?.password?.value ??  auth.payload.password.value,
            },
            loginRoute:edit?.payload?.loginRoute ??  auth.payload.loginRoute,
            tokenPrefix:edit?.payload?.tokenPrefix ??  auth.payload.tokenPrefix,
          },
          new: edit?.new ??  auth.new,
          editing: auth.editing,
        };
      }
      setEdit(tempEdit);
    }
    if (auth.new) {
      const tempIsNew = {};
      if (!tempEdit.payload?.loginRoute || tempEdit.payload?.loginRoute === "") {
        tempIsNew.loginRoute = true;
      }
      if (!tempEdit.payload?.username?.value || tempEdit.payload?.username?.value === "") {
        tempIsNew.usernameValue = true;
      }
      if (!tempEdit.payload?.password?.value || tempEdit.payload?.password?.value === "") {
        tempIsNew.passwordValue = true;
      }
      if (!tempEdit.payload?.tokenPrefix || tempEdit.payload?.tokenPrefix === "") {
        tempIsNew.tokenPrefix = true;
      }
      setIsNew(tempIsNew);
    } else {
      const tempIsNew = {};
      tempIsNew.loginRoute = false;
      tempIsNew.usernameValue = false;
      tempIsNew.passwordValue = false;
      tempIsNew.tokenPrefix = false;
      setIsNew(tempIsNew);
    }

  }, [auth]);

  useEffect(() => {
    if (editing) {
      validate();
    } else {
      setErrors({});
    }
  }, [edit]);

  const validate = () => {
    const tempError = {};
    tempError.loginRoute = validateLoginAuth();
    if (tempError.loginRoute === "") {
      delete tempError.loginRoute;
    }
    if (validateEmpty(edit.payload.username.key)) {
      delete tempError.usernameKey;
    } else {
      tempError.usernameKey = t("errorEmpty");
    }
    if (validateEmpty(edit.payload.username.value)) {
      delete tempError.usernameValue;
    } else {
      if (!isNew.usernameValue) {
        tempError.usernameValue = t("errorEmpty");
      }
    }
    if (validateEmpty(edit.payload.password.key)) {
      delete tempError.passwordKey;
    } else {
      tempError.passwordKey = t("errorEmpty");
    }
    if (validateEmpty(edit.payload.password.value)) {
      delete tempError.passwordValue;
    } else {
      if (!isNew.passwordValue) {
        tempError.passwordValue = t("errorEmpty");
      }
    }
    if (Object.keys(tempError).length>0) {
      setSave(false);
    } else if (isNew.loginRoute || isNew.usernameValue || isNew.passwordValue){
      setSave(false);
    } else {
      setSave(true);
    }
    setErrors(tempError);
  };

  const validateLoginAuth = () => {
    if (!isNew.loginRoute) {
      if ((!edit?.payload.loginRoute || edit.payload.loginRoute === "")) {
        return t("errorEmptyRoute");
      } else if (!validateUrlRoute(edit.payload.loginRoute)) {
        return t("errorRoute");
      }
    }
    return "";
  };

  const handleEditButton = () => {
    if (!editing) {
      setEdit({ 
        _id: auth._id,
        type: auth.type,
        payload: {
          username: {
            key: auth.payload.username.key,
            value: auth.payload.username.value,
          },
          password: {
            key: auth.payload.password.key,
            value: auth.payload.password.value,
          },
          loginRoute: auth.payload.loginRoute,
          tokenPrefix: auth.payload.tokenPrefix,
        },
        new: auth.new,
        editing: auth.editing,
      });
    }
    setEditing(true);
  };

  const handleEditInput = (value, fieldName) => {
    let editAuth = { ...edit };
    const tempIsNew = {...isNew};
    if (fieldName === "type") {
      editAuth[fieldName] = value;
    } else if (fieldName === "loginRoute"){
      if (isNew.loginRoute) {
        tempIsNew.loginRoute = false;
      }
      editAuth.payload.loginRoute = value;
    } else if (fieldName.includes("username")) {
      if (fieldName.includes("key") || fieldName.includes("Key")){
        editAuth.payload.username.key = value;
      } else if (fieldName.includes("value") || fieldName.includes("Value")) {
        if (isNew.usernameValue) {
          tempIsNew.usernameValue = false;
        }
        editAuth.payload.username.value = value;
      }
    } else if (fieldName.includes("password")) {
      if (fieldName.includes("key") || fieldName.includes("Key")){
        editAuth.payload.password.key = value;
      } else if (fieldName.includes("value") || fieldName.includes("Value")) {
        if (isNew.passwordValue) {
          tempIsNew.passwordValue = false;
        }
        editAuth.payload.password.value = value;
      } 
    }
    if (fieldName === "tokenPrefix") {
      if (isNew.tokenPrefix) {
        tempIsNew.tokenPrefix = false;
      }
      editAuth.payload.tokenPrefix = value;
    }
    setIsNew(tempIsNew);
    setEdit(editAuth);
  };

  const handleSaveEdit = () => {
    saveAuth(edit);
    setEdit({});
    setEditing(false);
  };

  const handleCancelEdit = () => {
    const payload = auth.payload;
    setEditing(false);
    if (auth.new) {
      setEdit({});
      modalDeleteAuth(auth);
    } else {
      const originalauth = {
        type: auth.type,
        payload: {      
          username: {
            key: payload.username.key,
            value: payload.username.value,
          },
          password: {
            key: payload.password.key,
            value: payload.password.value,
          },
          loginRoute: payload.loginRoute,
          tokenPrefix: payload.tokenPrefix,
        },
      };
      setEdit(originalauth);
    }
  };

  const renderPayload = (auth) => {
    const payload = editing? edit.payload : auth.payload;
    return (
      <Box>
        <TextField value={auth.type} onChange={(e) => handleEditInput(e.target.value, "type")} label={t("nameType")} disabled={true} variant="standard" sx={{marginTop: "8px"}}/>
        <TextField value={payload.loginRoute} onChange={(e) => handleEditInput(e.target.value, "loginRoute")} label={t("loginauth")}  error={errors?.loginRoute? true: false} helperText={errors?.loginRoute} disabled={!editing} variant="standard" fullWidth sx={{marginTop: "8px"}} />
        <TextField value={payload.username.key} onChange={(e) => handleEditInput(e.target.value, "usernameKey")} label={t("usernameKey")} error={errors?.usernameKey? true: false} helperText={errors?.usernameKey} disabled={!editing} variant="standard" fullWidth sx={{marginTop: "8px"}}/>
        <TextField value={payload.username.value} onChange={(e) => handleEditInput(e.target.value, "usernameValue")} label={t("usernameValue")} error={errors?.usernameValue? true: false} helperText={errors?.usernameValue} disabled={!editing} variant="standard" fullWidth sx={{marginTop: "8px"}}/>
        <TextField value={payload.password.key} onChange={(e) => handleEditInput(e.target.value, "passwordKey")} label={t("passwordKey")} error={errors?.passwordKey? true: false} helperText={errors?.passwordKey} disabled={!editing} variant="standard" fullWidth sx={{marginTop: "8px"}}/>
        <TextField
          value={payload.password.value}
          onChange={(e) => handleEditInput(e.target.value, "passwordValue")}
          label={t("passwordValue")}
          type={showPassword ? "text" : "password"}
          error={Boolean(errors?.passwordValue)}
          helperText={errors?.passwordValue}
          fullWidth
          disabled={!editing}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={userPermissions ? t("togglePasswordVisibility") : t("noPermissionMessage")}
                  sx={{
                    display: "flex",
                    "&:hover": { color: "#4e8cff" },
                  }}>
                  <span>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disabled={!userPermissions}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{marginTop: "8px"}}
        />
        <TextField value={payload.tokenPrefix} onChange={(e) => handleEditInput(e.target.value, "tokenPrefix")} label={t("tokenPrefix")} error={errors?.tokenPrefix? true: false} helperText={errors?.tokenPrefix} disabled={!editing} variant="standard" fullWidth sx={{marginTop: "8px"}}/>
      </Box>
    );
  };

  const renderButtonsEdit = (index) => {
    return (
      <Box sx={{marginTop:"5px", display:"flex", flexDirection:"row", alignItems:"flex-end", justifyContent:"flex-end"}}>
        <Button
          id={`saveEditButton_${index}`}
          variant="contained"
          disabled={!save}
          onClick={() => handleSaveEdit(index)}          
          sx={{margin: "8px"}}>
          {t("saveEditButon")}
        </Button>
        <Button
          id={`cancelEditButton_${index}`}
          variant="contained"
          onClick={() => handleCancelEdit(index)}
          sx={{margin: "8px"}}>
          {t("cancelEditButon")}
        </Button>
      </Box>
    );
  };

  return (
    <Grid container columns={7} sx={{ display: "flex", flexDirection: "row", justifyContent:"space-between"}} >
      <Grid item md={5}>
        {renderPayload(auth)}
      </Grid>
      <Grid item xs={1} rows={12} sx={{ display: "flex", flexDirection: "column", justifyContent:"space-between"}}>
        <Grid item xs={2} columns={24} sx={{ display: "flex", flexDirection: "row"}}>
          <Grid item xs={8} sx={{ display: "flex", flexDirection: "row", marginRight:"20%"}}>
            <Tooltip
              title={userPermissions ? t("editTooltip") : t("noPermissionMessage")}
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton 
                  disabled={!userPermissions} 
                  onClick={() => handleEditButton()} 
                  sx={{height:"50%"}}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={8} sx={{ display: "flex", flexDirection: "row", marginLeft:"20%"}}>
            <Tooltip
              title={userPermissions ? t("deleteTooltip") : t("noPermissionMessage")}
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton 
                  disabled={!userPermissions}
                  onClick={() => modalDeleteAuth(auth)} 
                  sx={{height:"50%"}}
                >
                  <Delete sx={{ color: userPermissions ? "deleteIcon.color" : "grey" }} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {editing ? renderButtonsEdit(_id) : null}
    </Grid>
  );
};

export default AuthItem;
