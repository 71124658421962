import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export const request = async (url) => {
  try {
    const response = await fetch(url, { mode: "no-cors" });
    if (response) {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export default function ApiTest({ ...props }) {
  const [status, setStatus] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tested, setTested] = React.useState(false);
  const { t } = useTranslation("apiTest");

  async function testApi() {
    setLoading(true);
    setTested(false);

    const response = await request(props.host + props.route);
    if (response) {
      setStatus(true);
      setLoading(false);
      setTested(true);
    } else {
      setStatus(false);
      setLoading(false);
      setTested(true);
    }
  }

  const validateInternal = () => {
    if (props.host.includes("botdarwin")) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setTested(false);
    setStatus(false);
    setLoading(false);
  }, [props.type, props.host, props.route]);

  return (
    <Fragment>
      <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={testApi}
        disabled={
          !props.userPermissions ||
          !props.type ||
          !props.host ||
          !props.route ||
          !validateInternal()
        }
        style={{
          borderColor: tested && (status ? "#36ff43" : "#f11"),
          color: tested && (status ? "#36ff43" : "#f11"),
          float: props.align,
        }}>
        {status && <DoneIcon />}
        {!status && tested && <CloseIcon />}
        {t("test")}
      </LoadingButton>
    </Fragment>
  );
}
