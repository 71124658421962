import React from "react";
import { Box } from "@mui/material";

const AnimatedMessage = (props) => {
  return (
    <Box
      sx={{
        width: 150,
        height: 150,
      }}
      component="img"
      src={`${props.message.data.img}`}
      alt=""
      className="sc-message--animated"
    />
  );
};

export default AnimatedMessage;
