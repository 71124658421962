import { createTheme } from "@mui/material/styles";
const headingStyles = {
  fontFamily: ["'Helvetica Neue'", "Helvetica", "sans-serif"].toString(),
  fontWeight: 700,
};
export const tableLightTheme = createTheme({
  palette: {
    mode: "light",
    type: "light",
    primary: {
      main: "#4e8cff",
    },
    info: {
      main: "#4e8cff",
    },
    secondary: {
      main: "rgba(0,0,0,0)",
    },
    default: {
      main: "#ffffff",
    },
    background: {
      default: "#fafafa",
    },
    deleteIcon: {
      color: "#D62828",
    },
    typography: {
      fontFamily: ["'Fira Mono'", "Menlo", "monospace"].toString(),
      h1: headingStyles,
      h2: headingStyles,
      h3: headingStyles,
      h4: headingStyles,
      h5: headingStyles,
      h6: {
        fontWeight: 400,
      },
      overline: {
        fontSize: "0.875rem",
        fontWeight: 500,
        letterSpacing: 2,
      },
      button: {
        fontWeight: 500,
      },
    },
    permissionsGrid: {
      backgroundColor: "#ffffff",
    },
    disabledFab: {
      color: "#858585",
    },
    chipEdit: {
      normal: "#b2ebf2",
      hover: "#7cdde8",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
        },
      },
    },
  },
});
export const tableDarkTheme = createTheme({
  palette: {
    mode: "dark",
    type: "dark",
    primary: {
      main: "#4e8cff",
    },
    info: {
      main: "#4e8cff",
    },
    secondary: {
      main: "#303030",
    },
    default: {
      main: "#d9d9d9",
    },
    background: {
      default: "#1f1f1f",
    },
    deleteIcon: {
      color: "#D62828",
    },
    typography: {
      fontFamily: ["'Fira Mono'", "Menlo", "monospace"].toString(),
      h1: headingStyles,
      h2: headingStyles,
      h3: headingStyles,
      h4: headingStyles,
      h5: headingStyles,
      h6: {
        fontWeight: 400,
      },
      overline: {
        fontSize: "0.875rem",
        fontWeight: 500,
        letterSpacing: 2,
      },
      button: {
        fontWeight: 500,
      },
    },
    permissionsGrid: {
      backgroundColor: "#1F1F1F",
    },
    disabledFab: {
      color: "#858585",
    },
    chipEdit: {
      normal: "#7cdde8",
      hover: "#b2ebf2",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
        },
      },
    },
  },
});
