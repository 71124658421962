import React, { useState, useEffect, useMemo } from "react";
import { get } from "../../shared/http/httpService";
import InputLabel from "@mui/material/InputLabel";
import Moment from "moment";
import Button from "@mui/material/Button";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Box, Grid, ListItem, Typography } from "@mui/material";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import {
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTooltip,
} from "victory";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";

import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    display: "flex",
  },
  button: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const Feedback = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [conversation, setConversation] = useState([]);
  const [data, setData] = useState([]);

  const [legendData, setLegendData] = useState([]);
  const [graphicData, setGraphicData] = useState([]);
  const [success, setSuccess] = useState(0);
  const [open, setOpen] = useState(false);
  const [valid, setValid] = useState(true);
  let theme = useTheme();

  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const getParsedDate = (date) => {
    date = String(date).split(" ");
    let days = String(date[2]);
    let month = String(date[1]);
    let year = String(date[3]);
    return days + " " + month + " " + year;
  };

  const [fileName, setFileName] = useState(
    t("analytics:SatFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );

  const columns = useMemo(() => [
    {
      header: t("feedback:resolved"),
      accessorKey: "resolved",
    },
    { header: t("feedback:comment"), accessorKey: "comment" },
    { header: t("feedback:user"), accessorKey: "room" },
    {
      header: t("feedback:idChat"),
      accessorKey: "idChat",
    },
    {
      header: t("feedback:date"),
      accessorKey: "date",
      Cell: ({ cell }) => {
        return (
          <InputLabel>
            {new Moment(cell.getValue())
              .subtract(0, "month")
              .format("DD-MM-YYYY")}
          </InputLabel>
        );
      },
    },
    {
      header: t("feedback:time"),
      accessorKey: "time",
    },
    {
      header: t("feedback:conversation"),
      accessorKey: "user",
      Cell: ({ row }) => {
        return (
          <ListItem>
            <div>
              <LibraryBooksIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleOpen(row.original)}
              />
            </div>
          </ListItem>
        );
      },
    },
    {
      header: t("feedback:agent"),
      accessorKey: "agent",
    },
  ]);

  useEffect(() => {}, [i18n.language]);

  const handleOpen = (nps) => {
    get(
      "report/conversationByIdChat?room=" +
        nps.room +
        "&idChat=" +
        nps.idChat +
        "&question=" +
        nps.comment,
    )
      .then(async (data) => {
        data.conversations.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        setOpen(true);
        setConversation(data.conversations);
      })
      .catch(() => {
        enqueueSnackbar(t("feedback:errorGettingConversation"), {
          variant: "error",
        });
      });
  };

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((row) => {
      const resolvedValue = row.original.resolved.toString().includes("SI")
        ? "SI"
        : "NO";
      const dateValue = dayjs(row.original.date);
      const dateFormatted = dateValue.format("DD/MM/YYYY");
      const timeFormatted = dateValue.format("HH:mm:ss");
      return {
        [t("feedback:resolved")]: resolvedValue,
        [t("feedback:comment")]: row.original.comment,
        [t("feedback:user")]: row.original.room,
        [t("feedback:date")]: dateFormatted,
        [t("feedback:time")]: timeFormatted,
        [t("feedback:agent")]: row.original.agent,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNPS = () => {
    setGraphicData([]);
    setLegendData([]);

    get("/report/feedback", {
      startDate: dayjs(startDate).toDate(),
      endDate: dayjs(endDate).toDate(),
    })
      .then(async (data) => {
        setFileName(
          t("analytics:SatFile") +
            t("analytics:from") +
            getParsedDate(startDate) +
            t("analytics:to") +
            getParsedDate(endDate),
        );
        let total = 0;
        let ok = 0;
        if (data.length !== 0) {
          data.forEach(function (nps) {
            nps.time = new Moment(nps.date)
              .subtract(0, "month")
              .format("HH:mm:ss");
            total++;
            if (nps.resolved === "true") {
              ok++;
              nps.resolved = "👍 SI";
            } else {
              nps.resolved = "👎 NO";
            }
          });
        }
        setData(data);
        setSuccess((ok !== 0) & (total !== 0) ? ok / total : 0);
        if (total > 0) {
          let graphicData = [
            { y: total - ok, label: "fail" },
            { y: ok, label: "success" },
          ];
          let legendData = [{ name: "fail" }, { name: "success" }];
          setGraphicData(graphicData);
          setLegendData(legendData);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("feedback:errorGettingIntents"), {
          variant: "error",
        });
      });
  };

  const handleDate = (dates) => {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setStartDate(dates.startDate);
      setValid(dates.valid);

      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:SatFile") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    className: classes.zIndex0,
    initialState: { columnVisibility: { idChat: false } },
  });

  return (
    <div style={{ textAlign: "center" }}>
      <Paper
        style={{
          marginBottom: "15px",
          borderRadius: "15px",
        }}>
        <Box
          sx={{
            p: 3,
            borderRadius: "15px",
            backgroundColor: "boxAnalytics.background",
          }}>
          <Grid container>
            <Grid item xs={12} md={3} display="flex">
              <Typography variant="h4">
                {"SAT " + Number((success * 100).toFixed(1)) + "%"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              display="flex"
              flexWrap="wrap"
              justifyContent="right"
              alignItems="center">
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onDateSelected={handleDate}
              />
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginLeft: "10px",
                }}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="default"
                  size="medium"
                  onClick={getNPS}
                  disabled={!valid}
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("feedback:fetch")}
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="default"
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => {
                    prepareDataForExport(table.getPrePaginationRowModel().rows);
                  }}
                  size="medium"
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("feedback:download")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div style={{ maxWidth: "100%" }}>
        <Typography>
          <br />
          <h2>{t("conversationalInteractions:usersAndInteractions")}</h2>
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>

      {graphicData.length > 0 ? (
        <Paper style={{ maxWidth: "50%", margin: "auto" }}>
          <h2 style={{ marginTop: "50px" }}>SAT Chart</h2>
          <VictoryPie
            labelComponent={<VictoryTooltip />}
            style={{
              labels: {
                fill: "#000",
                fontSize: 5,
                fontWeight: "bold",
              },
            }}
            height={250}
            colorScale={["#ED4F2D", "#67E548"]}
            data={graphicData}
          />
          <VictoryLegend
            height={100}
            width={400}
            orientation="horizontal"
            itemsPerRow={2}
            title={t("feedback:references") + ":"}
            gutter={20}
            style={{
              labels: {
                fill: theme.palette.textColor,
                fontSize: 5,
                fontWeight: "bold",
              },
              title: { fill: theme.palette.textColor },
            }}
            titleComponent={
              <VictoryLabel style={[{ fontSize: 12 }, { fontSize: 12 }]} />
            }
            colorScale={["#ED4F2D", "#67E548"]}
            data={legendData}
          />
        </Paper>
      ) : null}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>{t("feedback:conversation")}</DialogTitle>
        <DialogContent>
          {conversation?.map((conv) => (
            <p key={conv}>{conv.user + ":  " + conv.message} </p>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("feedback:close")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Feedback;
