export const orderAlphabetically = (unsortedArray) => {
  let sortedArray = unsortedArray.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );
  return sortedArray;
};

export function orderAlphabeticallyWithAttribute(unsortedArray, attribute) {
  let sortedArray = unsortedArray.sort((a, b) =>
    a[attribute].toLowerCase() > b[attribute].toLowerCase() ? 1 : -1,
  );
  return sortedArray;
}
