import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Transition from "../shared/helper/transitionDialog";
import Input from "@mui/material/Input";
import { useRef, useState } from "react";
export const ImportJsonModal = ({ open, onClose, onImport, title }) => {
  const { t } = useTranslation("deleteRowModal");
  const fileTranslationsInputRef = useRef(null);
  const fileLanguagesInputRef = useRef(null);
  const [selectedLanguagesFileName, setSelectedLanguagesFileName] =
    useState("");
  const [selectedTranslationsFileName, setSelectedTranslationsFileName] =
    useState("");
  const [replace, setReplace] = useState(false);
  const [importTranslationsData, setImportTranslationsData] = useState([]);
  const [importLanguagesData, setImportLanguagesData] = useState([]);

  const handleButtonTranslationsClick = () => {
    fileTranslationsInputRef.current.click();
  };
  const handleButtonLanguagesClick = () => {
    fileLanguagesInputRef.current.click();
  };

  const handleCheckboxChange = (event) => {
    setReplace(event.target.checked);
  };
  
  const handleFileChange = (collection, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const fileContent = e.target.result;
          const jsonData = JSON.parse(fileContent);
          if (collection === "translations") {
            setSelectedTranslationsFileName(file.name);
            setImportTranslationsData([jsonData]);
          } else {
            setSelectedLanguagesFileName(file.name);
            setImportLanguagesData([jsonData]);
          }
        } catch (error) {
          setSelectedTranslationsFileName("");
          setImportTranslationsData([]);
          setSelectedLanguagesFileName("");
          setImportLanguagesData([]);
        }
      };
      reader.readAsText(file);
    } else {
      setSelectedTranslationsFileName("");
      setImportTranslationsData([]);
      setSelectedLanguagesFileName("");
      setImportLanguagesData([]);
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "0.5rem",
        },
        justifyContent: "center",
      }}>
      <DialogTitle textAlign="center">{title ?? "Import Json"}</DialogTitle>
      <Box
        m={"1rem"}
        sx={{
          textAlign: selectedTranslationsFileName ? "left" : "center",
        }}>
        <Button
          onClick={handleButtonTranslationsClick}
          variant="contained"
          sx={{
            mr: selectedTranslationsFileName ? "1rem" : "0rem",
          }}>
          {t("chooseTranslations")}
          <label htmlFor="inputTranslationsJson">
            <Input
              id="inputTranslationsJson"
              type="file"
              sx={{ display: "none" }}
              ref={fileTranslationsInputRef}
              onChange={(e) => {
                handleFileChange("translations", e);
              }}
            />
          </label>
        </Button>
        {selectedTranslationsFileName}
      </Box>
      <Box
        m={"1rem"}
        sx={{
          textAlign: selectedLanguagesFileName ? "left" : "center",
        }}>
        <Button
          onClick={handleButtonLanguagesClick}
          variant="contained"
          sx={{
            mr: selectedLanguagesFileName ? "1rem" : "0rem",
          }}>
          {t("chooseLanguages")}
          <label htmlFor="inputLanguagesJson">
            <Input
              id="inputLanguagesJson"
              type="file"
              sx={{ display: "none" }}
              ref={fileLanguagesInputRef}
              onChange={(e) => {
                handleFileChange("languages", e);
              }}
            />
          </label>
        </Button>
        {selectedLanguagesFileName}
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <FormControlLabel
          control={
            <Checkbox checked={replace} onChange={handleCheckboxChange} />
          }
          label={t("replace")}
        />
      </Box>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button
          color="primary"
          onClick={() => {
            onImport(replace, importTranslationsData, importLanguagesData);
          }}
          variant="contained">
          {t("import")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
