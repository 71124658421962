import React from "react";
//import FileIcon from "../icons/FileIcon";
import { useTheme } from "@mui/material/styles";

const FileMessage = (props) => {
  const theme = useTheme();
  return (
    <a
      style={{
        background: theme.palette.chatTextBox.background,
      }}
      className="sc-message--file"
      href={props.data.url}
      download={props.data.fileName}
      target="_blank"
      rel="noopener noreferrer">
      {/*  <FileIcon /> */}
      <p
        style={{
          color: theme.palette.chatMessage.color,
        }}>
        {props.data.fileName}
      </p>
    </a>
  );
};

export default FileMessage;
