import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Transition from "../shared/helper/transitionDialog";

import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import ChipInput from "../shared/components/ChipInput";

import { Button } from "@mui/material";

const EditModal = ({ data, open, onClose, onSave }) => {
  const [_id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const { t } = useTranslation("files");
  
  useEffect(() => {
    setId(data._id);
    setName(data.name);
    setDescription(data.description);
    setTags(data.tags);

  }, [data]);

  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="h6" gutterBottom sx={{ padding: "1rem" }}>
        {t("files:editFile")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}>
        <TextField
          variant="standard"
          label={t("nameModal")}
          sx={{ width: "75%", marginBottom: "0.5rem", marginTop: "0.5rem" }}
          disabled
          value={name}
        />
        <TextField
          variant="standard"
          label={t("files:description")}
          sx={{ width: "75%", marginBottom: "0.5rem", marginTop: "0.5rem" }}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <ChipInput
          name="Tags"
          value={tags}
          source="tags"
          onChange={(e) => {
            setTags(e);
          }}
          updateProps={(e) => setTags(e)}
          label={t("tagsModal")}
          sx={{ width: "75%" }}
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose}>{t("files:modalCancel")}</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave({ name, description, tags, _id})}>
          {t("files:modalSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
