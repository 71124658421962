import React from "react";
import linkifyHtml from "linkify-html";
import { useTheme } from "@mui/material/styles";

const TextMessage = (props) => {
  const theme = useTheme();

  // Agregar negrita o cursiva, si viene una cadena que contiene ** o _

  let style_css = { color: theme.palette.chatMessage.color };
  let text = props.message;
  let chain_string = "";

  const options = {
    attributes: { target: "_blank" },
    className: "sc-message-link",
  };

  //bold
  text = text.replace(/^(\*)(.*?)(\*)(\s+)/g, "<b>$2</b>$4");
  text = text.replace(/([_\s]+)(\*)(.*?)(\*)([\s_,.]+)/g, "$1<b>$3</b>$5");
  text = text.replace(/(\s+)(\*)(.*?)(\*)(\s*)$/g, "$1<b>$3</b>$5");
  text = text.replace(/^(\*)(.*?)(\*)$/g, "<b>$2</b>");

  //italic
  text = text.replace(/^(_)(.*?)(_)(\s+)/g, "<i>$2</i>$4");
  text = text.replace(/([\s>]+)(_)(.*?)(_)([\s,.<]+)/g, "$1<i>$3</i>$5");
  text = text.replace(/(\s+)(_)(.*?)(_)(\s*)$/g, "$1<i>$3</i>$5");
  text = text.replace(/^(_)(.*?)(_)$/g, "<i>$2</i>");

  if (chain_string.length === 0) {
    chain_string = text;
  }

  let new_chain = (
    <div
      dangerouslySetInnerHTML={{ __html: linkifyHtml(chain_string, options) }}
    />
  );

  return (
    <div
      className="sc-message--text-box"
      style={{
        background: theme.palette.chatTextBox.background,
      }}>
      {
        <span className="sc-message--text" style={style_css}>
          {new_chain}
        </span>
      }
      <span className="sc-message--date">
        {new Date(props.date).toLocaleTimeString("en-EN", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })}
      </span>
    </div>
  );
};

export default TextMessage;
