const embeddingsModelNames = [
  {
    name: "OpenAI",
    id: "openai",
  },
  {
    name: "HuggingFace",
    id: "huggingface",
  },
];

module.exports = {
  embeddingsModelNames,
};
