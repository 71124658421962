import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Typography, Grid, Checkbox, Button } from "@mui/material";
import { put } from "../../shared/http/httpService";
import { useSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
const Permissions = ({ row }) => {
  const { t } = useTranslation("permissions");
  const [permissions, setPermissions] = useState(row.permissions);
  const { enqueueSnackbar } = useSnackbar();
  const [userPermissions, setUserPermissions] = useState(false);
  let auth = useSelector((state) => state.auth);

  const hiddenPermissions = ["holidays", "permissions", "noMatchIgnore"];

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "permissions" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  useEffect(() => {
    getUserPermisions();
  }, []);
  const handleSave = () => {
    put("/permissions", { permissions: permissions, _id: row._id })
      .then(() => {
        enqueueSnackbar(t("enqueueSnackbarSuccess"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("enqueueSnackbarError"), {
          variant: "error",
        });
      });
  };

  function handlePermissionChange(permissionToChange, newAccessLevel) {
    setPermissions((prevPermissions) => {
      const newPermissions = [...prevPermissions];
      const index = newPermissions.findIndex(permission => permission.name === permissionToChange.name);
      newPermissions[index].fullAccess = newAccessLevel;

      if (newPermissions[index].name === "workingDays") {
        newPermissions.forEach((permission) => {
          if (permission.name === "holidays") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "users") {
        newPermissions.forEach((permission) => {
          if (permission.name === "permissions") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "noMatch") {
        newPermissions.forEach((permission) => {
          if (permission.name === "noMatchIgnore") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }

      if (newPermissions[index].name === "config") {
        newPermissions.forEach((permission) => {
          if (permission.name === "clients2") {
            permission.fullAccess = newAccessLevel;
          }
        });
      }
      
      return newPermissions;
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "background.default",
      }}>
      <Typography variant="h5">{t("title")}</Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              marginLeft: "5%",
            }}>
            {t("name")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{t("fullAccess")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{t("readOnly")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">{t("noAccess")}</Typography>
        </Grid>
      </Grid>
      {permissions
        .filter((permission) => !hiddenPermissions.includes(permission.name))
        .map((permission, index) => (
          <Grid key={index}
            container
            sx={{
              backgroundColor:
                index % 2 === 0
                  ? "permissionsGrid.backgroundColor"
                  : "background.default",
            }}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: "5%",
                  marginTop: "1%",
                  marginBottom: "1%",
                }}>
                {t(`${permission.name}`)}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ marginTop: "0.5%" }}>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <Checkbox
                    disabled={!userPermissions}
                    checked={permission.fullAccess}
                    onChange={() => {
                      if (permission.fullAccess !== true) {
                        handlePermissionChange(permission, true);
                      }
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={2} sx={{ marginTop: "0.5%" }}>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <Checkbox
                    disabled={!userPermissions}
                    checked={permission.fullAccess === false}
                    onChange={() => {
                      if (permission.fullAccess !== false) {
                        handlePermissionChange(permission, false);
                      }
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={2} sx={{ marginTop: "0.5%" }}>
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <Checkbox
                    disabled={!userPermissions}
                    checked={permission.fullAccess === null}
                    onChange={() => {
                      if (permission.fullAccess !== null) {
                        handlePermissionChange(permission, null);
                      }
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        ))}
      <Box
        sx={{
          width: "100%",
          marginTop: "0.5%",
          marginBottom: "0.5%",
        }}>
        <Grid container>
          <Grid item xs={11} />
          <Grid item xs={1}>
            <Tooltip title={userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{
                    marginTop: "2%",
                    marginBottom: "2%",
                  }}
                  disabled={!userPermissions}>
                  {t("save")}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Permissions;
