import { combineReducers } from "redux";
import loading from "../loader/reducers/loaderReducer";
import auth from "./authSlice";
import theme from "./themeSlice";
import client from "./clientSlice";

export default combineReducers({
  loading,
  theme,
  auth,
  client,
});
