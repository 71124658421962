import {
  Box,
  Button,
  Container,
  FormControl,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PasswordModal from "./Components/PasswordModal.component";
import { TabGeneral } from "./Components/Tabs/General.component";
import { TabLanguage } from "./Components/Tabs/Language.component";

const TabSecurity = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const { index, value, auth } = props;
  const { t } = useTranslation();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div
      role="tabSecurity"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ height: "50vh", width: "50%" }}>
      <Box sx={{ p: 3 }}>
        <Typography id="security-title" marginLeft="20px" align="left" variant="h5">
          {t("preferences:security")}
        </Typography>

        <PasswordModal
          open={openModal}
          onClose={handleCloseModal}
          auth={auth}
        />
        <div
          style={{
            margin: "45px",
            textAlign: "left",
          }}>
          <FormControl>
            <Button
              id="change-password-button"
              align="left"
              variant="contained"
              color="primary"
              size="medium"
              sx={{ marginBottom: "10px", marginTop: "10px" }}
              onClick={() => {
                handleOpenModal();
              }}>
              {t("preferences:changePassword")}
            </Button>
          </FormControl>
        </div>
      </Box>
    </div>
  );
};

function a11yProps(index) {
  return {
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Preferences = () => {
  let auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [tab, setTab] = React.useState();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderTab = () => {
    switch (tab) {
      case "security":
        return <TabSecurity auth={auth} />;
      case "language":
        return <TabLanguage />;
      case "general":
        return <TabGeneral />;
      default:
        return <TabSecurity auth={auth} />;
    }
  };

  return (
    <React.Fragment>
      <Container maxwidth={false} style={{ maxWidth: "100%" }}>
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: "10px",
          }}>
          <Typography
            id="preferences-title"
            align="left"
            variant="h4"
            sx={{ padding: "50px 0px 30px 50px" }}>
            {t("preferences:preferences")}
          </Typography>
          <Box
            sx={{ display: "flex", flexGrow: 1, bgcolor: "background.paper" }}>
            <Tabs
              textColor="primary"
              visibleScrollbar
              selectionFollowsFocus="true"
              orientation="vertical"
              variant="scrollable"
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider", margin: "20px" }}>
              <Tab 
                id="general-tab"
                label={"General"} 
                {...a11yProps(0)} 
                value={"general"} />
              <Tab
                id="language-tab"
                label={t("preferences:language")}
                {...a11yProps(1)}
                value={"language"}
              />
              <Tab
                id="security-tab"
                label={t("preferences:security")}
                {...a11yProps(2)}
                value={"security"}
              />
            </Tabs>
            {renderTab()}
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Preferences;
