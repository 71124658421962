import React from "react";
import styles from "../Login.module.css";

//svgs
import { G274Icon, G278Icon, G282Icon, G286Icon, Path290Icon, G292Icon, DotIcon } from "../../assets/svgs/darwin-logo";

const Logo = () => {
  return (
    <div className={styles.isotipoDarwin}>
      <G274Icon className={styles.g274Icon} alt="" />
      <G278Icon className={styles.g278Icon} alt="" />
      <G282Icon className={styles.g282Icon} alt="" />
      <G286Icon className={styles.g286Icon} alt="" />
      <Path290Icon className={styles.path290Icon} alt="" />
      <G292Icon className={styles.g292Icon} alt="" />
      <DotIcon className={styles.dotIcon} alt="" />
    </div>
  );
};

export default Logo;