import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, Grid, Checkbox } from "@mui/material";

const StepTwo = ({ onChange, setPermissions, permissions }) => {
  const { t } = useTranslation("permissions");
  const hiddenPermissions = ["holidays", "permissions", "noMatchIgnore"];

  useEffect(() => {
    onChange(permissions);
  }, [permissions, onChange]);

  function handlePermissionChange(index, newAccessLevel) {
    setPermissions((prevPermissions) => {
      const newPermissions = prevPermissions.map((permission, i) => {
        if (i === index) {
          return { ...permission, fullAccess: newAccessLevel };
        }
        return permission;
      });

      const relatedPermissionsMap = {
        workingDays: ["holidays"],
        users: ["permissions"],
        noMatch: ["noMatchIgnore"],
        config: ["clients2"],
      };

      const updatedPermission = newPermissions[index];
      const relatedPermissions =
        relatedPermissionsMap[updatedPermission.name] || [];

      relatedPermissions.forEach((relatedPermissionName) => {
        newPermissions.forEach((permission, i) => {
          if (permission.name === relatedPermissionName) {
            newPermissions[i] = { ...permission, fullAccess: newAccessLevel };
          }
        });
      });

      return newPermissions;
    });
  }

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            sx={{
              paddingLeft: "5%",
            }}>
            {t("name")}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("fullAccess")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("readOnly")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">{t("noAccess")}</Typography>
        </Grid>
      </Grid>
      {permissions.map((permission, index) => {
        if (hiddenPermissions.includes(permission.name)) {
          return null;
        }
        return (
          <Grid
            key={index}
            container
            sx={{
              backgroundColor:
                index % 2 === 0
                  ? "permissionsGrid.backgroundColor"
                  : "background.default",
            }}>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: "5%",
                }}>
                {t(`${permission.name}`)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess === true}
                onChange={() => {
                  if (permission.fullAccess !== true) {
                    handlePermissionChange(index, true);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess === false}
                onChange={() => {
                  if (permission.fullAccess !== false) {
                    handlePermissionChange(index, false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={permission.fullAccess === null}
                onChange={() => {
                  if (permission.fullAccess !== null) {
                    handlePermissionChange(index, null);
                  }
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default StepTwo;
