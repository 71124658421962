import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Transition from "../../shared/helper/transitionDialog";
import { Dialog, IconButton, Box, Tooltip, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NewStepOne from "./NewStepOne.component";
import NewStepTwo from "./NewStepTwo.component";
import NewStepThree from "./NewStepThree.component";

const NewIntent = ({
  open,
  onClose,
  validateNoDuplicateName,
  validateNoDuplicatePhrases,
  allTags,
  saveIntent,
  intents,
  agentPages,
  userPermissions,
  agentSelected,
}) => {
  const [newIntent, setNewIntent] = useState({});
  const [step, setStep] = useState(0);
  const [dirtyExcludeAlternatives, setDirtyExcludeAlternatives] =
    useState(undefined);

  const { t } = useTranslation();

  const close = useCallback(() => {
    setNewIntent({});
    setStep(0);
    onClose();
  }, [onClose, setNewIntent, setStep]);

  const backStep = useCallback(() => {
    setStep(step - 1);
    if (dirtyExcludeAlternatives === undefined) {
      setDirtyExcludeAlternatives(
        newIntent.response.text.length === 0 ? true : false,
      );
    }
  }, [step]);

  const onSave = useCallback(() => {
    saveIntent(newIntent);
    close();
  }, [newIntent]);

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="md"
      TransitionComponent={Transition}>
      <div>
        {step === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}>
            <Typography variant="h6" component="div">
              {t("intents:StepOneTitle")}
            </Typography>
          </Box>
        )}
        {step > 0 && (
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <Tooltip title={t("intents:back")}>
              <IconButton
                onClick={() => backStep()}
                sx={{
                  marginRight: "30%",
                }}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" component="div">
              {t("intents:StepTwoTitle")}
            </Typography>
          </Box>
        )}
        {step === 0 && (
          <NewStepOne
            intent={newIntent}
            validateNoDuplicateName={validateNoDuplicateName}
            validateNoDuplicatePhrases={validateNoDuplicatePhrases}
            onClose={close}
            setStep={setStep}
            setNewIntent={setNewIntent}
            agentSelected={agentSelected}
          />
        )}
        {step === 1 && (
          <NewStepTwo
            intent={newIntent}
            onClose={close}
            allTags={allTags}
            setNewIntent={setNewIntent}
            agentPages={agentPages}
            setStep={(step) => {
              setStep(step);
              if (dirtyExcludeAlternatives === undefined) {
                setDirtyExcludeAlternatives(
                  newIntent.response.text.length === 0 ? true : false,
                );
              }
            }}
            dirtyExcludeAlternatives={dirtyExcludeAlternatives}
          />
        )}
        {step === 2 && (
          <NewStepThree
            intent={newIntent}
            onClose={close}
            setNewIntent={setNewIntent}
            setStep={setStep}
            onSave={onSave}
            userPermissions={userPermissions}
            intents={intents}
          />
        )}
      </div>
    </Dialog>
  );
};

export default NewIntent;
