import React, { Fragment, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import RemoveIcon from "@mui/icons-material/Remove";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { validateCharacters } from "../../../shared/helper/validations";

export default function EdgeConditions({ ...props }) {
  const [conditions, setConditions] = React.useState([]);
  const [dirty, setDirty] = React.useState([]);
  const { t } = useTranslation("edge");
  const userPermissions = useMemo(
    () => props.userPermissions,
    [props.userPermissions],
  );

  const operations = [
    {
      name: "=",
      value: "eq",
    },
    {
      name: "!=",
      value: "neq",
    },
    {
      name: ">",
      value: "gt",
    },
    {
      name: "<",
      value: "lt",
    },
    {
      name: ">=",
      value: "egt",
    },
    {
      name: "<=",
      value: "elt",
    },
    {
      name: ".length >=",
      value: "cgt",
    },
    {
      name: ".length <=",
      value: "clt",
    },
  ];

  useEffect(() => {
    setConditions(props.conditions);
    let rows = [];
    for (let i = 0; i < props.conditions.length; i++) {
      rows.push({ prop: false, value: false });
    }
    setDirty(rows);
  }, []);

  useEffect(() => {
    validate();
  }, [conditions]);

  function updateProp(e, index) {
    let current = [...conditions];
    current[index].prop = e.target.value;
    setConditions(current);
    validate();

    let currentDirty = [...dirty];
    currentDirty[index].prop = true;
    setDirty(currentDirty);
  }

  async function updateOp(e, index) {
    let current = [...conditions];
    current[index].op = e.target.value;
    setConditions(current);
    validate();
  }

  function updateValue(e, index) {
    let current = [...conditions];
    current[index].value = e.target.value;
    setConditions(current);
    validate();

    let currentDirty = [...dirty];
    currentDirty[index].value = true;
    setDirty(currentDirty);
  }

  function handleAddCondition() {
    let current = [...conditions];
    current.push({ op: "eq" });
    setConditions(current);
    validate();

    let currentDirty = [...dirty];
    currentDirty.push({ prop: false, value: false });
    setDirty(currentDirty);
  }

  function handleRemoveCondition() {
    if (conditions.length > 1) {
      let current = [...conditions];
      current.pop({});
      setConditions(current);
      validate();

      let currentDirty = [...dirty];
      currentDirty.pop({});
      setDirty(currentDirty);
    }
  }

  function validate() {
    let valid = true;
    for (let i = 0; i < conditions.length; i++) {
      if (
        !conditions[i].op ||
        !validateCharacters(conditions[i].prop) ||
        !validateCharacters(conditions[i].value, "/_-")
      ) {
        valid = false;
        break;
      }
    }
    if (conditions.length === 0) valid = false;
    props.conditionsUpdate(conditions, valid);
  }

  return (
    <Fragment>
      <div>
        {conditions.map((item, index) => (
          <div key={index} className="condition">
            <div className="condition-header">
              <h3 className="condition-title">
                {t("condition")} {index + 1}
              </h3>
              {index === 0 && (
                <div className="condition-buttons">
                  <Tooltip
                    title={
                      !userPermissions
                        ? t("noPermissionTooltip")
                        : t("tooltipConditionAdd")
                    }>
                    <span>
                      <IconButton
                        onClick={handleAddCondition}
                        disabled={!userPermissions}
                        style={{ cursor: "pointer" }}>
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !userPermissions
                        ? t("noPermissionTooltip")
                        : t("tooltipConditionDelete")
                    }>
                    <span>
                      <IconButton
                        disabled={!userPermissions}
                        onClick={handleRemoveCondition}
                        style={{ cursor: "pointer" }}>
                        <RemoveIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                className="condition-property"
                label={t("conditinProperty")}
                variant="standard"
                disabled={!userPermissions}
                multiline
                fullWidth
                defaultValue={item.prop ? item.prop : ""}
                onChange={(e) => updateProp(e, index)}
                error={
                  dirty[index].prop === true && !validateCharacters(item.prop)
                }
                helperText={
                  dirty[index].prop === true && !validateCharacters(item.prop)
                    ? t("validateValue")
                    : ""
                }
              />
            </Tooltip>
            <br></br>
            <InputLabel shrink>{t("conditinOperator")}</InputLabel>
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <Select
                className="condition-operator"
                fullWidth
                disabled={!userPermissions}
                defaultValue={item.op ? item.op : operations[0].value}
                onChange={(e) => updateOp(e, index)}>
                {operations.map((item, index) => (
                  <MenuItem name={item.name} value={item.value} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                className="condition-value"
                label={t("conditinValue")}
                variant="standard"
                multiline
                disabled={!userPermissions}
                fullWidth
                defaultValue={item.value ? item.value : ""}
                onChange={(e) => updateValue(e, index)}
                error={
                  dirty[index].value === true &&
                  !validateCharacters(item.value, "/_-")
                }
                helperText={
                  dirty[index].value === true &&
                  !validateCharacters(item.value, "/_-")
                    ? t("validateValue")
                    : ""
                }
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
