import { createSlice } from "@reduxjs/toolkit";
import { setToken, removeToken } from "../http/httpService";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    root: JSON.parse(localStorage.getItem("root")) === true,
    name: localStorage.getItem("name"),
    clients: localStorage.getItem("clients")
      ? localStorage.getItem("clients").split(",")
      : [],
    language: localStorage.getItem("language"),
    _id: localStorage.getItem("_id"),
    theme: localStorage.getItem("theme"),
  },
  reducers: {
    login: (state, response) => {
      let login = response.payload;
      let root = false;
      if (login && login.root) {
        localStorage.setItem("root", true);
        root = true;
      }
      localStorage.setItem("name", login.name);
      localStorage.setItem("token", login.idToken);
      localStorage.setItem("clients", login.clients);
      localStorage.setItem("language", login.language);
      localStorage.setItem("_id", login._id);
      localStorage.setItem("theme", login.theme);
      return {
        ...state,
        _id: login._id,
        token: login.idToken,
        name: login.name,
        root: root,
        clients: login.clients,
        language: login.language,
        theme: login.theme,
      };
    },
    setAuth: (state, action) => {
      let token = action.payload;
      if (token) {
        setToken(token);
      }
    },
    logout: (state) => {
      removeToken();
      localStorage.removeItem("_id");
      localStorage.removeItem("token");
      localStorage.removeItem("root");
      localStorage.removeItem("name");
      localStorage.removeItem("clients");
      localStorage.removeItem("language");
      localStorage.removeItem("theme");
      state = {};
      return {
        ...state,
      };
    },
  },
});

export const { login, setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
